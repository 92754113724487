import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { twMerge as cx } from 'tailwind-merge';

import { BBBCard } from '@/components/ui/BBBCard';
import { useLoginSetting } from '@/hooks/bitLogin/login/login-settings';
import useSettings from '@/hooks/bitLogin/useSettings';
import useSocialAppearance from '@/hooks/bitLogin/useSocialAppearance';

export default function PreviewAdditionalForm() {
  const { status: statusEnabled, data } = useSettings();
  const isDisabled = statusEnabled !== 'success' || !data?.verified;

  return (
    <div
      className={cx(
        'border-l flex-[1_1_0%] flex justify-center items-center overflow-auto',
        isDisabled && 'opacity-50 pointer-events-none'
      )}
    >
      <_PreviewAdditionalForm />
    </div>
  );
}

function _PreviewAdditionalForm() {
  const { data: socialAppearance } = useSocialAppearance();
  const { data: loginSetting = [] } = useLoginSetting();

  const settingsMap = loginSetting.reduce((acc, setting) => {
    acc[setting.type] = setting;
    return acc;
  }, {} as Record<string, FormState>);

  const {
    phoneNumber = { active: false, mandatory: false },
    gender = { active: false, mandatory: false },
    dateOfBirth = { active: false, mandatory: false },
    address = { active: false, mandatory: false },
  } = settingsMap;

  const allSectionsInactive =
    !phoneNumber?.active &&
    !gender?.active &&
    !dateOfBirth?.active &&
    !address?.active;

  const [currentCard, setCurrentCard] = useState(0);

  const activeSections = [phoneNumber, gender, dateOfBirth, address].filter(
    (section) => section?.active
  ).length;

  const totalCards =
    (phoneNumber?.active || gender?.active || dateOfBirth?.active ? 1 : 0) +
    (address?.active ? 1 : 0);

  useEffect(() => {
    if (address?.active && activeSections === 1) {
      setCurrentCard(1);
    } else if (!address?.active && currentCard !== 0) {
      setCurrentCard(0);
    }
  }, [address?.active, activeSections, currentCard]);

  const getDescription = () => {
    if (!socialAppearance?.captureAdditionalData) {
      return 'You must turn on capture more data feature';
    }
    if (allSectionsInactive) {
      return 'You must turn on one section to display the live preview';
    }
    return '';
  };

  const handleBulletClick = (index: number) => {
    setCurrentCard(index);
  };

  const handleNextClick = () => {
    if (currentCard < totalCards - 1) {
      setCurrentCard((prev) => prev + 1);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <BBBCard
        className={cx(
          'w-96 !p-10 flex flex-col gap-4',
          (!socialAppearance?.captureAdditionalData || allSectionsInactive) &&
            'text-center !py-20'
        )}
        desc={getDescription()}
      >
        {socialAppearance?.captureAdditionalData && (
          <>
            {currentCard === 0 && (
              <Card0
                form={settingsMap}
                allSectionsInactive={allSectionsInactive}
                onNextClick={handleNextClick}
              />
            )}
            {currentCard === 1 && (
              <Card1
                form={settingsMap}
                totalCards={totalCards}
                onChangeCard={setCurrentCard}
              />
            )}
          </>
        )}
      </BBBCard>

      <div className="w-full text-center">
        <ul className="list-none flex justify-center gap-2">
          {[...Array(totalCards)].map((_, index) => (
            <li
              key={index}
              className={cx(
                'w-3 h-3 rounded-full cursor-pointer',
                currentCard === index ? 'bg-[#262627]' : 'bg-[#9A9A9A]'
              )}
              onClick={() => handleBulletClick(index)}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  );
}

type FormState = {
  active?: boolean;
  mandatory?: boolean;
};

function Card0({
  form: { phoneNumber, gender, dateOfBirth, address },
  allSectionsInactive,
  onNextClick,
}: {
  form: Record<string, FormState>;
  allSectionsInactive: boolean;
  onNextClick: () => void;
}) {
  return (
    <>
      {phoneNumber?.active && (
        <div className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30">
          <p className="text-primary opacity-50">
            Phone Number
            {phoneNumber.mandatory && <small className="text-red-500">*</small>}
          </p>
        </div>
      )}

      {gender?.active && (
        <div className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30">
          <p className="text-primary opacity-50">
            Gender
            {gender.mandatory && <small className="text-red-500">*</small>}
          </p>
        </div>
      )}

      {dateOfBirth?.active && (
        <div className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30">
          <p className="text-primary opacity-50">
            Date of Birth
            {dateOfBirth.mandatory && <small className="text-red-500">*</small>}
          </p>
        </div>
      )}
      {!allSectionsInactive && (
        <div
          className="w-full px-4 py-3 rounded-lg bg-[#262627] border-[1px] border-[#262627] cursor-pointer"
          onClick={onNextClick}
        >
          <p className="text-neutral-20 text-center font-semibold">
            {address?.active ? 'Next' : 'Submit'}
          </p>
        </div>
      )}
    </>
  );
}

const addressFields = [
  'Address 1',
  'Address 2 (Optional)',
  'City',
  'Country / region',
  'Province',
  'Postal / ZIP code',
];

function Card1({
  form: { address },
  totalCards,
  onChangeCard,
}: {
  form: Record<string, FormState>;
  totalCards: number;
  onChangeCard: (val: number) => void;
}) {
  if (!address?.active) return null;

  return (
    <>
      {totalCards > 1 && (
        <a
          className="flex items-center text-primary opacity-50 cursor-pointer hover:underline"
          onClick={() => onChangeCard(0)}
        >
          <ArrowLeft size={20} />
          <span className="ml-2">Back</span>
        </a>
      )}
      {addressFields.map((label, index) => (
        <div
          key={index}
          className="w-full px-4 py-3 rounded-lg bg-neutral-20 border-[1px] border-neutral-30"
        >
          <p className="text-primary opacity-50">
            {label}
            {address.mandatory && <small className="text-red-500">*</small>}
          </p>
        </div>
      ))}
      <div className="w-full px-4 py-3 rounded-lg bg-[#262627] border-[1px] border-[#262627] cursor-pointer">
        <p className="text-neutral-20 text-center font-semibold">Submit</p>
      </div>
    </>
  );
}
