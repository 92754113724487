import { Edge, Node } from 'reactflow';
import { createId } from '@paralleldrive/cuid2';

export const dataFieldOptions = [
  { label: 'Shopify abandoned cart', value: 'shopify_abandoned_cart' },
  { label: 'Shopify order created', value: 'shopify_order_created' },
  { label: 'Shopify order fulfilled', value: 'shopify_order_fulfilled' },
  { label: 'Shopify order paid', value: 'shopify_order_paid' },
  { label: 'Stamped', value: 'stamped' },
  { label: 'Yotpo', value: 'yotpo' },
  { label: 'WhatsApp API', value: 'whatsapp_meta' },
  { label: 'WhatsApp Business', value: 'whatsapp_business' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Web activity visit page', value: 'web-activity-visit-page' },
  {
    label: 'Web activity visit collections',
    value: 'web-activity-visit-collections',
  },
  {
    label: 'Web activity visit product',
    value: 'web-activity-visit-product',
  },
  {
    label: 'Web activity add cart item',
    value: 'web-activity-add-cart-item',
  },
  {
    label: 'bitLogin user login',
    value: 'bitlogin-user-login',
  },
  {
    label: 'bitLogin user signup',
    value: 'bitlogin-user-signup',
  },
  {
    label: 'Customer information',
    value: 'customer-information',
  },
  {
    label: 'Stamped loyalty membership information',
    value: 'loyalty-membership-info',
  },
  {
    label: 'Stamped loyalty earn rewards',
    value: 'loyalty-rewards-earn',
  },
  {
    label: 'Stamped loyalty rewards used',
    value: 'loyalty-rewards-used',
  },
] as const;

export type DataFieldOption = typeof dataFieldOptions[number];
export type DataFieldValue = typeof dataFieldOptions[number]['value'];

export type RunIfValue =
  | 'product_purchased'
  | 'product_not_purchased'
  | 'product_paid'
  | 'product_not_paid'
  | 'total_price_more'
  | 'total_price_less'
  | 'product_variant_more'
  | 'product_variant_less'
  | 'quantity_more'
  | 'quantity_less'
  | 'review_submitted'
  | 'review_submitted_stars_more'
  | 'review_submitted_stars_less'
  | 'review_not_submitted'
  | 'message_replied'
  | 'form_submitted'
  | 'cta_clicked'
  | 'visited_page_x_times'
  | 'visited_collection_x_times'
  | 'visited_product_x_times'
  | 'add_x_cart_item'
  | 'login_x_period'
  | 'signup_channel'
  | 'customer_have_tags'
  | 'loyalty_level_equals'
  | 'loyalty_level_not_equals'
  | 'loyalty_x_points'
  | 'loyalty_rewards_earn_more_than'
  | 'loyalty_rewards_earn_less_than'
  | 'loyalty_rewards_used_more_than'
  | 'loyalty_rewards_used_less_than';

export const runIfOptionsAll: Record<
  DataFieldValue,
  { label: string; value: RunIfValue }[]
> = {
  shopify_abandoned_cart: [
    { label: 'Product purchased', value: 'product_purchased' },
    { label: 'Product not purchased', value: 'product_not_purchased' },
  ],
  shopify_order_created: [
    { label: 'Product paid', value: 'product_paid' },
    { label: 'Product not paid', value: 'product_not_paid' },
  ],
  shopify_order_fulfilled: [
    { label: 'Total price more than', value: 'total_price_more' },
    { label: 'Total price less than', value: 'total_price_less' },
    { label: 'Product variant more than', value: 'product_variant_more' },
    { label: 'Product variant less than', value: 'product_variant_less' },
    { label: 'Quantity more than', value: 'quantity_more' },
    { label: 'Quantity less than', value: 'quantity_less' },
  ],
  shopify_order_paid: [
    { label: 'Total price more than', value: 'total_price_more' },
    { label: 'Total price less than', value: 'total_price_less' },
    { label: 'Product variant more than', value: 'product_variant_more' },
    { label: 'Product variant less than', value: 'product_variant_less' },
    { label: 'Quantity more than', value: 'quantity_more' },
    { label: 'Quantity less than', value: 'quantity_less' },
  ],
  stamped: [
    { label: 'Review submitted', value: 'review_submitted' },
    {
      label: 'Submitted review more than (stars)',
      value: 'review_submitted_stars_more',
    },
    {
      label: 'Submitted review less than (stars)',
      value: 'review_submitted_stars_less',
    },
    { label: 'Review not submitted', value: 'review_not_submitted' },
  ],
  yotpo: [
    { label: 'Review submitted', value: 'review_submitted' },
    {
      label: 'Submitted review more than (stars)',
      value: 'review_submitted_stars_more',
    },
    {
      label: 'Submitted review less than (stars)',
      value: 'review_submitted_stars_less',
    },
    { label: 'Review not submitted', value: 'review_not_submitted' },
  ],
  whatsapp_meta: [
    { label: 'Message replied', value: 'message_replied' },
    { label: 'Form submitted', value: 'form_submitted' },
    { label: 'CTA clicked', value: 'cta_clicked' },
  ],
  whatsapp_business: [{ label: 'Message replied', value: 'message_replied' }],
  instagram: [{ label: 'Message replied', value: 'message_replied' }],
  'web-activity-visit-page': [
    { label: 'Visited page X times', value: 'visited_page_x_times' },
  ],
  'web-activity-visit-collections': [
    {
      label: 'Visited collections X times',
      value: 'visited_collection_x_times',
    },
  ],
  'web-activity-visit-product': [
    {
      label: 'Visited product X times',
      value: 'visited_product_x_times',
    },
  ],
  'web-activity-add-cart-item': [
    {
      label: 'Add X item to cart',
      value: 'add_x_cart_item',
    },
    {
      label: 'Total price more than',
      value: 'total_price_more',
    },
  ],
  'bitlogin-user-login': [
    {
      label: 'Login X times for X day',
      value: 'login_x_period',
    },
  ],
  'bitlogin-user-signup': [
    {
      label: 'Sign up with (Channel)',
      value: 'signup_channel',
    },
  ],
  'customer-information': [
    {
      label: 'Have certain tag',
      value: 'customer_have_tags',
    },
  ],
  'loyalty-membership-info': [
    {
      label: 'If loyalty level is equal to',
      value: 'loyalty_level_equals',
    },
    {
      label: 'If loyalty level is not equal to',
      value: 'loyalty_level_not_equals',
    },
    {
      label: 'If customer have X amount of points',
      value: 'loyalty_x_points',
    },
  ],
  'loyalty-rewards-earn': [
    {
      label: 'Earn rewards more than',
      value: 'loyalty_rewards_earn_more_than',
    },
    {
      label: 'Earn rewards less than',
      value: 'loyalty_rewards_earn_less_than',
    },
  ],
  'loyalty-rewards-used': [
    {
      label: 'Points used more than',
      value: 'loyalty_rewards_used_more_than',
    },
    {
      label: 'Points used less than',
      value: 'loyalty_rewards_used_less_than',
    },
  ],
};

const mapDataField: Record<DataFieldValue, object> = {
  shopify_order_fulfilled: {
    type: 'trigger',
    data: {
      source: 'shopify',
      triggerValue: 'orders/fulfilled',
    },
  },
  shopify_abandoned_cart: {
    type: 'trigger',
    data: {
      source: 'shopify',
      triggerValue: 'carts/create',
    },
  },
  shopify_order_paid: {
    type: 'trigger',
    data: {
      source: 'shopify',
      triggerValue: 'orders/paid',
    },
  },
  shopify_order_created: {
    type: 'trigger',
    data: {
      source: 'shopify',
      triggerValue: 'orders/create',
    },
  },
  whatsapp_business: {
    type: 'action',
    data: {
      source: 'WHATSAPP',
    },
  },
  whatsapp_meta: {
    type: 'action',
    data: {
      source: 'WHATSAPP_META',
    },
  },
  stamped: {
    type: 'action',
    data: {
      source: 'STAMPED',
    },
  },
  yotpo: {
    type: 'action',
    data: {
      source: 'YOTPO',
    },
  },
  instagram: {
    type: 'action',
    data: {
      source: 'INSTAGRAM',
    },
  },
  'web-activity-visit-page': {
    type: 'trigger',
    data: {
      source: 'web_activity',
      triggerValue: 'visit-page',
    },
  },
  'web-activity-visit-collections': {
    type: 'trigger',
    data: {
      source: 'web_activity',
      triggerValue: 'visit-collections',
    },
  },
  'web-activity-visit-product': {
    type: 'trigger',
    data: {
      source: 'web_activity',
      triggerValue: 'visit-product',
    },
  },
  'web-activity-add-cart-item': {
    type: 'trigger',
    data: {
      source: 'web_activity',
      triggerValue: 'add-to-cart',
    },
  },
  'bitlogin-user-login': {
    type: 'trigger',
    data: {
      source: 'bitlogin',
      triggerValue: 'login',
    },
  },
  'bitlogin-user-signup': {
    type: 'trigger',
    data: {
      source: 'bitlogin',
      triggerValue: 'signup',
    },
  },
  'customer-information': {
    type: 'action',
    data: {
      source: 'customer',
      action: 'get_customer_information',
    },
  },
  'loyalty-membership-info': {
    type: 'action',
    data: {
      source: 'stamped_loyalty',
      action: 'get_loyalty_membership_info',
    },
  },
  'loyalty-rewards-earn': {
    type: 'trigger',
    data: {
      source: 'stamped_loyalty',
      triggerValue: 'loyalty-rewards-earn',
    },
  },
  'loyalty-rewards-used': {
    type: 'trigger',
    data: {
      source: 'stamped_loyalty',
      triggerValue: 'loyalty-rewards-used',
    },
  },
};

export const dataFieldEntries = Object.entries(mapDataField);

export const automationVariables = [
  {
    group: 'all',
    label: 'Order Code / Name',
    value: '{{order.name}}',
    placeholder: 'OB12700',
  },
  {
    group: 'all',
    label: 'Total Price',
    value: '{{order.totalPrice}}',
    placeholder: '4000$',
  },
  {
    group: 'all',
    label: 'Order Note',
    value: '{{order.note}}',
    placeholder: 'Please read this order notes',
  },
  {
    group: 'all',
    label: 'Item Lines',
    value: '{{order.itemLines}}',
    placeholder:
      '1. Extra Shipping Cost - Cost: 85k - Rp. 85.000,00 x 1\u003Cbr\u003E2. Black T-Shirt - 10$ x 1',
  },
  {
    group: 'all',
    label: 'Order Link',
    value: '{{order.link}}',
    placeholder: 'http://rebrand.ly/qeozz4x',
  },
  {
    group: 'all',
    label: 'Order Properties Label',
    value: '{{order.itemLines.properties.name[index]}}',
    placeholder: 'Recipients : ',
  },
  {
    group: 'all',
    label: 'Order Properties Value',
    value: 'order.itemLines.properties.value[index]',
    placeholder: 'Mr. John Doe',
  },
  {
    group: 'customer',
    label: 'Customer full name',
    value: '{{order.customer.name}}',
    placeholder: 'John Doe',
  },
  {
    group: 'customer',
    label: 'Customer first name',
    value: '{{order.customer.firstName}}',
    placeholder: 'John',
  },
  {
    group: 'customer',
    label: 'Customer last name',
    value: '{{order.customer.lastName}}',
    placeholder: 'Doe',
  },
  {
    group: 'customer',
    label: 'Customer email',
    value: '{{order.customer.email}}',
    placeholder: 'john@gmail.com',
  },
  {
    group: 'customer',
    label: 'Customer phone',
    value: '{{order.customer.phone}}',
    placeholder: '628xxxxxxxxxx',
  },
  {
    group: 'customer',
    label: 'Customer address',
    value: '{{order.customer.address}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping full name',
    value: '{{order.shippingAddress.name}}',
    placeholder: 'John Doe',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping first name',
    value: '{{order.shippingAddress.firstName}}',
    placeholder: 'John',
  },
  {
    group: 'shippingAddress',
    label: 'Customer shipping last name',
    value: '{{order.shippingAddress.lastName}}',
    placeholder: 'Doe',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping address 1',
    value: '{{order.shippingAddress.address1}}',
    placeholder: 'Hasanudin Street number 499',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping address 2',
    value: '{{order.shippingAddress.address2}}',
    placeholder: 'Pattimura Street number 12',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping country',
    value: '{{order.shippingAddress.country}}',
    placeholder: 'Indonesia',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping province',
    value: '{{order.shippingAddress.province}}',
    placeholder: 'East Java',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping city',
    value: '{{order.shippingAddress.city}}',
    placeholder: 'Jakarta',
  },
  {
    group: 'shippingAddress',
    label: 'Shipping zip / postal code',
    value: '{{order.shippingAddress.zip}}',
    placeholder: '12110',
  },
  {
    group: 'payment',
    label: 'Payment Status',
    value: '{{order.financialStatus}}',
    placeholder: 'paid',
  },
  {
    group: 'payment',
    label: 'Order Gateway',
    value: '{{order.gateway}}',
    placeholder: 'Bca_Xendit',
  },
  {
    group: 'payment',
    label: 'Payment Method',
    value: '{{order.paymentGatewayNames}}',
    placeholder: 'Bca_Xendit',
  },
  {
    group: 'fulfillment',
    label: 'Tracking company',
    value: '{{order.fulfillments.name}}',
    placeholder: 'JNE',
  },
  {
    group: 'fulfillment',
    label: 'Tracking number',
    value: '{{order.fulfillments.trackingNumber}}',
    placeholder: 'XXXXXXXX',
  },
  {
    group: 'fulfillment',
    label: 'Tracking url',
    value: '{{order.fulfillments.trackingUrl}}',
    placeholder: 'https://www.ups.com/mobile/track?trackingNumber={XXXXX}',
  },
  {
    group: 'fulfillment',
    label: 'Delivery status',
    value: '{{order.fulfillments.status}}',
    placeholder: 'send',
  },
  {
    group: 'abandoned_cart',
    label: 'Abandoned Cart Url',
    value: '{{order.abandonedCartUrl}}',
    placeholder: 'http://rebrand.ly/qeozz4x',
  },
  {
    group: 'discount',
    label: 'Voucher Code',
    value: '{{voucher.code}}',
    placeholder: 'FAVO1234',
  },
  {
    group: 'review',
    label: 'Review Url',
    value: '{{reviewUrl}}',
    placeholder: 'reviewurl.example.com/token',
  },
];

const abandonedCartNodes: Node[] = [
  {
    id: 'z5qqzlqu9jbpwzn0nhxsoikz',
    type: 'trigger',
    position: { x: 0, y: 0 },
    data: {
      source: 'shopify',
      triggerName: 'Abandoned Cart',
      triggerValue: 'carts/create',
      jubelioChannelLabel: null,
      jubelioChannelValue: null,
    },
  },
  {
    id: 'eqv92pgbdq0bgtlr1t3xvgod',
    type: 'action',
    position: { x: 0, y: 0 },
    data: {
      action: 'send_message',
      params: null,
      source: 'WHATSAPP_META',
      message: null,
      delayUnit: null,
      delayCount: null,
      templateId: null,
      templateName: null,
      templateMessage: null,
    },
  },
  {
    id: 'kdldt2czu02koklddmv6ewsz',
    type: 'conditions',
    position: { x: 0, y: 0 },
    data: null,
  },
  {
    id: 'vjg9ltuu2edla7oq8afk5e5f',
    type: 'action',
    position: { x: 0, y: 0 },
    data: {
      action: 'send_message',
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'WHATSAPP_META',
      message: null,
      delayCount: null,
      delayUnit: null,
    },
  },
];

const abandonedCartEdges: Edge[] = [
  {
    id: 'mjqd77ql98bdn4hivgsx9muu',
    source: 'z5qqzlqu9jbpwzn0nhxsoikz',
    target: 'eqv92pgbdq0bgtlr1t3xvgod',
    type: 'custom',
    data: null,
  },
  {
    id: 'tj9sdjzy8aul2xbqk3lp1dco',
    source: 'eqv92pgbdq0bgtlr1t3xvgod',
    target: 'kdldt2czu02koklddmv6ewsz',
    type: 'custom',
  },
  {
    id: 'qri9orrlup7p0opiqfztmygz',
    source: 'kdldt2czu02koklddmv6ewsz',
    target: 'vjg9ltuu2edla7oq8afk5e5f',
    type: 'customCondition',
    data: {
      dataField: 'shopify_abandoned_cart',
      runIf: 'product_not_purchased',
      currency: null,
      totalPrice: null,
      totalProductVariant: null,
      totalQuantity: null,
      delay: 24,
      starsTreshold: null,
    },
  },
];

const defaultNodeId1 = createId();
const defaultNodeId2 = createId();

const defaultNodes: Node[] = [
  {
    id: defaultNodeId1,
    position: { x: 0, y: 0 },
    data: null,
    type: 'trigger',
  },
  {
    id: defaultNodeId2,
    position: { x: 0, y: 0 },
    data: null,
    type: 'action',
  },
];

const defaultEdges: Edge[] = [
  {
    id: createId(),
    source: defaultNodeId1,
    target: defaultNodeId2,
    type: 'custom',
  },
];

const defaultOrderConfirmationNodes: Node[] = [
  {
    id: 'vo7j97bey2vv4645ytfo471y',
    position: {
      x: 0,
      y: 75,
    },
    data: {
      source: 'shopify',
      triggerName: 'Order Created',
      triggerValue: 'orders/create',
      jubelioChannelLabel: null,
      jubelioChannelValue: null,
    },
    type: 'trigger',
  },
  {
    id: 'ba0dqu0yw5b740x0x6kvpk2q',
    position: {
      x: 500,
      y: 75,
    },
    data: {
      action: 'send_message',
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'WHATSAPP_META',
      message: null,
      delayCount: null,
      delayUnit: null,
    },
    type: 'action',
  },
];

const defaultOrderConfirmationEdges: Edge[] = [
  {
    id: 'qijc7eg5rtp5otuhiqzhzm0a',
    source: 'vo7j97bey2vv4645ytfo471y',
    target: 'ba0dqu0yw5b740x0x6kvpk2q',
    type: 'custom',
  },
];

const defaultOrderFulfilledNodes: Node[] = [
  {
    id: 'ulcdlhddscp39wftou09ip7g',
    position: {
      x: 0,
      y: 75,
    },
    data: {
      source: 'shopify',
      triggerName: 'Order Fulfilled',
      triggerValue: 'orders/fulfilled',
      jubelioChannelLabel: null,
      jubelioChannelValue: null,
    },
    type: 'trigger',
    width: 336,
    height: 88,
  },
  {
    id: 'iw6pknl7yqdcw3n5jb7822ip',
    position: {
      x: 500,
      y: 75,
    },
    data: {
      action: 'send_message',
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'WHATSAPP_META',
      message: null,
      delayCount: null,
      delayUnit: null,
    },
    type: 'action',
    width: 336,
    height: 88,
  },
  {
    id: 'lw3eqq5y1a3w5aletdqvrz2o',
    type: 'action',
    data: {
      action: null,
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'time_delay',
      message: null,
      delayCount: 7,
      delayUnit: 'days',
    },
    position: {
      x: 1000,
      y: 75,
    },
    width: 336,
    height: 88,
  },
  {
    id: 'ijpuiz7nurkszxe3xw5gerb7',
    type: 'action',
    data: {
      action: 'ask_review',
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'STAMPED',
      message: null,
      delayCount: null,
      delayUnit: null,
    },
    position: {
      x: 1500,
      y: 75,
    },
  },
  {
    id: 'thibc7mcn425m2w4oczlrwgi',
    type: 'action',
    data: {
      action: 'send_message',
      params: null,
      templateName: null,
      templateId: null,
      templateMessage: null,
      source: 'WHATSAPP_META',
      message: null,
      delayCount: null,
      delayUnit: null,
    },
    position: {
      x: 2000,
      y: 75,
    },
  },
];

const defaultOrderFullfilledEdges: Edge[] = [
  {
    id: 'l2abzwmapvrznt0vpbynj3cj',
    source: 'ulcdlhddscp39wftou09ip7g',
    target: 'iw6pknl7yqdcw3n5jb7822ip',
    type: 'custom',
  },
  {
    id: 'tk6evckdvdsj53ek9hocxc9e',
    type: 'custom',
    source: 'iw6pknl7yqdcw3n5jb7822ip',
    target: 'lw3eqq5y1a3w5aletdqvrz2o',
  },
  {
    id: 'djl5uvkbslo7n5i23gpdz02y',
    type: 'custom',
    source: 'lw3eqq5y1a3w5aletdqvrz2o',
    target: 'ijpuiz7nurkszxe3xw5gerb7',
  },
  {
    id: 'llo17rhhdnyk3o3rb3o94o1o',
    type: 'custom',
    source: 'ijpuiz7nurkszxe3xw5gerb7',
    target: 'thibc7mcn425m2w4oczlrwgi',
  },
];

type PrebuiltAutomationType =
  | 'default'
  | 'abandonedCart'
  | 'orderConfirmation'
  | 'orderFulfilled';

export const prebuiltAutomation: Record<
  PrebuiltAutomationType,
  {
    title: string;
    nodes: Node[];
    edges: Edge[];
    description?: string;
    key?: string;
  }
> = {
  default: {
    title: 'Abandoned cart recovery',
    nodes: defaultNodes,
    edges: defaultEdges,
  },
  abandonedCart: {
    title: 'Abandoned cart',
    description:
      'Recover lost sales by sending automated WhatsApp reminders to customers who abandon their carts on your Shopify store.',
    nodes: abandonedCartNodes,
    edges: abandonedCartEdges,
    key: 'abandoned_cart',
  },
  orderConfirmation: {
    title: 'Order confirmation',
    description:
      'Instantly confirm orders to customers via WhatsApp, providing order details and expected delivery time.',
    nodes: defaultOrderConfirmationNodes,
    edges: defaultOrderConfirmationEdges,
    key: 'order_confirmation',
  },
  orderFulfilled: {
    title: 'Order fulfilled',
    description:
      'Notify customers when their order has shipped, including tracking information and delivery updates.',
    nodes: defaultOrderFulfilledNodes,
    edges: defaultOrderFullfilledEdges,
    key: 'order_fulfilled',
  },
};

export const prebuiltAutomationPopular =
  Object.values(prebuiltAutomation).slice(1);

export const mapPrebuiltKeyToType: Record<string, PrebuiltAutomationType> = {
  abandoned_cart: 'abandonedCart',
  order_confirmation: 'orderConfirmation',
  order_fulfilled: 'orderFulfilled',
};

export const companyWithJubelioIntegration = [
  335, 379, 521, 619, 1322, 1569, 6065,
];
