import { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { DetailCardProps } from '../CustomerDetail/DetailCard/DetailCard';

import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import {
  BBBCheckbox,
  BBBFileUpload,
  BBBModal,
  BBBSelect,
  BBBTelInput,
  BBBTelInputValue,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';
import { env } from '@/config/env';
import { acceptedXcelFileType } from '@/constants/crm';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import useResponsive from '@/hooks/common/useResponsive';
import useCreateCustomer from '@/hooks/customers/customer/useCreateCustomer';
import useCreateTag from '@/hooks/customers/customer/useCreateTag';
import useTags from '@/hooks/customers/customer/useTags';
import { CustomerAddresses, CustomerTags } from '@/types/customers';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { phoneValidator } from '@/utils/common/phone';
import { formatPhonePayload } from '@/utils/common/phone';

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  data?: DetailCardProps | null;
};

export type FormSchema = {
  source: {
    label: string;
    value: string;
  } | null;
  csv?: Nullable<FileType>;
  overwrite?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  instagramId: string;
  phone: BBBTelInputValue | undefined | null;
  id?: string;
  addresses?: Partial<
    Pick<CustomerAddresses, 'city' | 'company' | 'id' | 'address1' | 'address2'>
  >[];
  tags?: string[];
};

const schema = yup.object().shape({
  id: yup.string(),
  source: yup.mixed().required().label('Source'),
  csv: yup
    .mixed<Nullable<FileType[]>>()
    .label('Customer data')
    .when('source', {
      is: (opt: FormSchema['source']) => opt?.value === 'manual',
      then: (rule) => rule.required(),
    }),
  overwrite: yup.bool().when('source', {
    is: (opt: FormSchema['source']) => opt?.value === 'manual',
    then: (rule) => rule.required(),
  }),
  firstName: yup
    .string()
    .label('First name')
    .when('source', {
      is: (opt: FormSchema['source']) => opt?.value === 'individual',
      then: (rule) => rule.required(),
    }),
  lastName: yup.string().label('Last name'),
  email: yup.string().email().label('Email'),
  instagramId: yup.string().label('Instagram ID'),
  phone: phoneValidator(true),
  addresses: yup
    .mixed<Pick<CustomerAddresses, 'city' | 'company' | 'id'>[]>()
    .label('Addresses'),
  tags: yup.mixed<CustomerTags[]>().label('Customer Tags'),
});

const optionsAdd = [
  {
    label: 'Bulk Import from File',
    value: 'manual',
  },
  {
    label: 'Add individually',
    value: 'individual',
  },
];

function AddCustomerModal({ show, data, onClose, onSuccess }: Props) {
  const isMobile = useResponsive('sm');
  const phoneDefaultValue = useDefaultCountryCode(data?.phoneNumber);
  const { mutate: addCustomer, isLoading: loadingCreateCustomer } =
    useCreateCustomer();

  const {
    data: tagsData,
    hasNextPage: hasMore,
    fetchNextPage: fetchNextTags,
  } = useTags();
  const { mutate: createTag } = useCreateTag();

  const flattenedTags = tagsData?.pages.flatMap((page) => page.data);

  const { handleSubmit, formState, control, reset, watch } =
    useCustomForm<FormSchema>({
      resolver: yupResolver(schema),
      defaultValues: {
        csv: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: undefined,
        addresses: [],
      },
    });

  const dataFromApi = useMemo<FormSchema>(
    () => ({
      source: data?.id
        ? optionsAdd.find((opt) => opt.value === 'individual')!
        : null,
      phone: phoneDefaultValue || null,
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
      id: data?.id || undefined,
      addresses: [
        {
          city: data?.addresses?.[0]?.city || '',
          company: data?.addresses?.[0]?.company || '',
          id: data?.addresses?.[0]?.id,
          address1: data?.addresses?.[0]?.address1 || '',
          address2: data?.addresses?.[0]?.address2 || '',
        },
      ],
      instagramId: data?.instagramId ?? '',
      overwrite: false,
      tags: data?.customerTags?.map((tag) => tag.tagName),
    }),
    [
      data?.addresses,
      data?.customerTags,
      data?.email,
      data?.firstName,
      data?.id,
      data?.instagramId,
      data?.lastName,
      phoneDefaultValue,
    ]
  );

  const sourceOptions = optionsAdd.filter((opt) =>
    isMobile ? opt.value === 'individual' : true
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const isFormEqual = isEqual(dataFromApi, watch());

  const onSubmit = ({ csv, overwrite, ...formData }: FormSchema) => {
    const { value } = formData.source!;
    if (value === 'individual') {
      addCustomer(
        {
          type: 'individual',
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          source: 'bitbybit_add_individual',
          phone: formatPhonePayload(formData.phone),
          id: formData.id,
          addresses: formData.addresses,
          instagramId: formData.instagramId,
          tags: formData.tags,
        },
        {
          onSuccess: () => {
            onClose();
            onSuccess?.();
          },
        }
      );
    } else if (value === 'manual') {
      addCustomer(
        {
          type: 'bulk_file',
          url: csv?.remoteUrl,
          source: 'bitbybit_bulk_import',
          id: formData.id,
          overwrite,
        },
        {
          onSuccess: () => {
            onClose();
            onSuccess?.();
          },
        }
      );
    }
  };

  return (
    <BBBModal
      show={show}
      onHide={onClose}
      title={`${data ? 'Edit' : 'Add customer'}`}
      subtitle={`${
        data
          ? `You can edit customer details here.`
          : 'You can manually import your customer data to bitbybit'
      }`}
      submitText={`${
        data
          ? `Save ${!isMobile ? 'changes' : ''}`
          : watch('source')?.value === 'manual'
          ? 'Import'
          : `Save ${!isMobile ? 'changes' : ''}`
      }`}
      cancelText={`${data ? 'Cancel' : 'Discard'}`}
      footer
      handleSave={handleSubmit(onSubmit)}
      loadingSave={loadingCreateCustomer}
      disableVerticallyCentered
      disableSave={isFormEqual}
    >
      {!data?.id && (
        <Controller
          name="source"
          control={control}
          render={({ field: { onChange, value } }) => (
            <BBBSelect
              options={sourceOptions}
              placeholder="Select customer source"
              onValueChange={onChange}
              isDisabled={!!data}
              value={value}
              label="Source"
              error={formState.errors.source?.message}
              optionLabel="label"
              optionValue="value"
            />
          )}
        />
      )}

      {watch('source')?.value === 'manual' && (
        <div className="my-3 flex flex-col gap-1">
          <div className="flex items-center justify-center gap-2">
            <div className="flex flex-col gap-2 w-full">
              <Controller
                name="csv"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <BBBFileUpload
                      isSingle
                      files={value}
                      onChangeFile={onChange}
                      accept={acceptedXcelFileType}
                    />
                    <div className="my-4.5">
                      {!value ? (
                        <p className="text-neutral-60 text-xs">
                          Download bulk customer template{' '}
                          <span
                            className="underline text-blue-400 cursor-pointer"
                            onClick={() =>
                              window.open(
                                env.REACT_APP_CDN_URL +
                                  '/bitbybit/static/bitCRM/template-customers',
                                '_blank'
                              )
                            }
                          >
                            here.
                          </span>
                        </p>
                      ) : (
                        <div className="flex items-center gap-2">
                          <Controller
                            control={control}
                            name="overwrite"
                            render={({ field }) => (
                              <BBBCheckbox
                                checked={field.value}
                                onValueChange={field.onChange}
                              />
                            )}
                          />
                          <div className="text-primary-main">
                            Overwrite existing customers that have the same
                            phone or email
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          {formState.errors.csv && (
            <div className="text-danger-main">
              {formState.errors.csv?.message}
            </div>
          )}
        </div>
      )}
      {watch('source')?.value === 'individual' && (
        <div className="mt-3">
          <div className="grid grid-cols-2 gap-4 mb-3">
            <BBBTextInput
              label="First name"
              placeholder="Enter customer first name"
              isHookForm
              control={control}
              controlName="firstName"
              error={formState.errors.firstName?.message}
              containerClassname="mb-0"
            />
            <BBBTextInput
              label="Last name"
              placeholder="Enter customer last name"
              isHookForm
              control={control}
              controlName="lastName"
              error={formState.errors.lastName?.message}
              containerClassname="mb-0"
            />
          </div>
          <BBBTelInput
            isHookForm
            label="Phone number"
            placeholder="234 5678"
            containerClassname={cx(
              'mb-3',
              data && 'opacity-50 pointer-events-none'
            )}
            control={control}
            controlName="phone"
            error={formState.errors.phone?.message}
          />
          <BBBTextInput
            label="Instagram account"
            containerClassname={cx(
              'mb-3',
              data && 'opacity-50 pointer-events-none'
            )}
            placeholder="Enter instagram account"
            isHookForm
            control={control}
            controlName="instagramId"
            error={formState.errors.instagramId?.message}
          />
          <BBBTextInput
            label="Email"
            placeholder="Enter customer email"
            isHookForm
            control={control}
            controlName="email"
            error={formState.errors.email?.message}
          />
          <Controller
            control={control}
            name="addresses"
            render={({ field }) => (
              <>
                <BBBTextInput
                  label="Company"
                  placeholder="Enter company name"
                  value={field.value?.[0]?.company ?? ''}
                  onChange={({ target: { value } }) =>
                    field.onChange([
                      {
                        ...field.value?.[0],
                        company: value,
                      },
                    ])
                  }
                  error={formState.errors.addresses?.[0]?.company?.message}
                />
                <BBBTextAreaInput
                  label="Address"
                  placeholder="Enter customer address"
                  value={field.value?.[0]?.address1 ?? ''}
                  onChange={({ target: { value } }) =>
                    field.onChange([
                      {
                        ...field.value?.[0],
                        address1: value,
                      },
                    ])
                  }
                  rows={5}
                  error={formState.errors.addresses?.[0]?.address1?.message}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <>
                <BBBSelect
                  isMulti
                  value={
                    flattenedTags?.filter((tag) =>
                      field.value?.includes(tag.name)
                    ) ?? []
                  }
                  onValueChange={(opt, selected) => {
                    field.onChange([...(field.value ?? []), selected!.name]);
                  }}
                  isCreatable
                  onCreateOption={(val) => {
                    createTag(
                      { name: val },
                      {
                        onSuccess: (data) => {
                          field.onChange([...(field.value ?? []), data.name]);
                        },
                      }
                    );
                  }}
                  fallbackToPlaceholder
                  isPaginated
                  hasMore={!!hasMore}
                  fetchNext={fetchNextTags}
                  optionLabel="name"
                  optionValue="id"
                  options={flattenedTags}
                  label="Tags"
                  placeholder="Enter tag name"
                  containerClassName="mb-3"
                />
                {!!field.value?.length && (
                  <TruncatedTags
                    items={field.value}
                    onDelete={(tag) =>
                      field.onChange(
                        field.value?.filter((_tag) => _tag !== tag)
                      )
                    }
                  />
                )}
              </>
            )}
          />
        </div>
      )}
    </BBBModal>
  );
}

export default AddCustomerModal;
