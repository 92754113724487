import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge as cx } from 'tailwind-merge';

import { BBBOverviewBox, BBBRenderHtml } from '@/components/ui';
import { integrationsMeta } from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import useResponsive from '@/hooks/common/useResponsive';
import { AutomationLog } from '@/types/bitCRM/automation';
import { formatCurrency } from '@/utils/common/currency';

type Props = {
  data: AutomationLog | undefined;
  isLoading: boolean;
};

export default function AutomationLogAnalytics({ data, isLoading }: Props) {
  const isMobile = useResponsive('sm');

  const [showMore, setShowMore] = useState(false);

  const isOrderFulfilledStamped =
    data?.trigger === 'orders/fulfilled' && data?.source === 'Stamped';
  const isOrderUnpaidShopify =
    data?.trigger === 'orders/unpaid' && data?.source === 'Shopify';
  const isAbandonedCartShopify =
    data?.trigger === 'carts/create' && data?.source === 'Shopify';

  return (
    <div className={`grid grid-cols-12 gap-6 ${isMobile && 'px-4'}`}>
      {isLoading ? (
        <AutomationLogAnalyticsSkeleton
          isMobile={isMobile}
          isWithAdditional={
            isAbandonedCartShopify ||
            isOrderUnpaidShopify ||
            isOrderFulfilledStamped
          }
        />
      ) : (
        <>
          <div className="col-span-12 flex flex-col gap-2 px-6 py-6 bg-white border-[1.5px] border-gray-300 rounded-xl">
            <p className="text-base text-primary-main font-semibold">
              Message:
            </p>
            <div className="inline">
              <BBBRenderHtml content={data?.message || '-'} />
              {data?.message && data?.message?.length > 210 && (
                <span
                  className="underline cursor-pointer"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? ' Show less' : ' Show more'}
                </span>
              )}
            </div>
          </div>
          <BBBOverviewBox
            title="Source"
            className={isMobile ? 'col-span-12' : 'col-span-4'}
            value={data?.source || '-'}
          />
          <BBBOverviewBox
            title="Trigger"
            className={isMobile ? 'col-span-12' : 'col-span-4'}
            value={data?.triggerName || '-'}
          />
          <BBBOverviewBox
            title="Channel"
            className={isMobile ? 'col-span-12' : 'col-span-4'}
            value={
              data?.channel
                ? integrationsMeta[mapSourceToKnownIntegrations[data.channel]]
                    .title
                : '-'
            }
          />
          <BBBOverviewBox
            title="Sent"
            className={isMobile ? 'col-span-4' : 'col-span-3'}
            value={data?.sent || '0'}
          />
          <BBBOverviewBox
            title="Delivered"
            className={isMobile ? 'col-span-4' : 'col-span-3'}
            value={data?.delivered || '0'}
          />
          <BBBOverviewBox
            title="Opened"
            className={isMobile ? 'col-span-4' : 'col-span-3'}
            value={data?.read || '0'}
          />
          <BBBOverviewBox
            title="Replied"
            className={isMobile ? 'col-span-4' : 'col-span-3'}
            value={data?.replied || '0'}
          />
          {isAbandonedCartShopify || isOrderUnpaidShopify ? (
            <>
              <BBBOverviewBox
                title="Click rate"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={`${data?.clickRate}%` || '0%'}
              />
              <BBBOverviewBox
                title="Conversion rate"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={`${data?.conversionRate}%` || '0%'}
              />
              <BBBOverviewBox
                title="Revenue"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={formatCurrency(data?.revenue, data?.currency) || '-'}
              />
            </>
          ) : isOrderFulfilledStamped ? (
            <>
              <BBBOverviewBox
                title="Click rate"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={`${data?.clickRate}%` || '0%'}
              />
              <BBBOverviewBox
                title="Review submitted"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={data?.reviewSubmitted || '0'}
              />
              <BBBOverviewBox
                title="Avg. Review"
                className={isMobile ? 'col-span-12' : 'col-span-4'}
                value={`${data?.avgReview?.toFixed(1) || '0'}/5`}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

function AutomationLogAnalyticsSkeleton({
  isMobile,
  isWithAdditional,
}: {
  isMobile: boolean;
  isWithAdditional?: boolean;
}) {
  return (
    <>
      <div className="col-span-12">
        <Skeleton height={150} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-4' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-4' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-4' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
      <div className={cx(`${isMobile ? 'col-span-4' : 'col-span-3'}`)}>
        <Skeleton height={110} />
      </div>
      {isWithAdditional && (
        <>
          <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
            <Skeleton height={110} />
          </div>
          <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
            <Skeleton height={110} />
          </div>
          <div className={cx(`${isMobile ? 'col-span-12' : 'col-span-4'}`)}>
            <Skeleton height={110} />
          </div>
        </>
      )}
    </>
  );
}
