import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  BBBModal,
  BBBSelect,
  BBBTelInput,
  BBBTelInputValue,
} from '@/components/ui';
import {
  ChannelIntegration,
  mapKnownIntegrationsToSources,
} from '@/constants/integrations';
import useWACloudTemplates from '@/hooks/bitChat/settings/useWACloudTemplates';
import useCustomForm from '@/hooks/common/useCustomForm';
import useDefaultCountryCode from '@/hooks/common/useDefaultCountryCode';
import useHistory from '@/hooks/common/useHistory';
import { useStartChat } from '@/hooks/whatsApp/chat';
import useAvailableSources from '@/hooks/whatsApp/useAvailableSources';
import { ChannelOptions } from '@/pages/Analytics';
import { WACloudTemplate } from '@/types/whatsApp/settings';
import { formatPhonePayload, phoneValidator } from '@/utils/common/phone';

type Props = {
  showModal: boolean;
  onChangeShowModal: (val: boolean) => void;
};

const schema = yup.object().shape({
  source: yup
    .mixed<ReturnType<typeof useAvailableSources>[number] | null>()
    .label('Source')
    .required(),
  template: yup.mixed().label('Template'),
  phone: phoneValidator(true).when('source', {
    is: (opt: ReturnType<typeof useAvailableSources>[number] | null) =>
      !!opt?.name,
    then: () => phoneValidator(),
  }),
});

type FormSchema = {
  phone: BBBTelInputValue | null;
  source: ChannelIntegration | null;
  template: WACloudTemplate | null;
};

export default function StartChat({ showModal, onChangeShowModal }: Props) {
  const history = useHistory();

  const phoneDefaultValue = useDefaultCountryCode();

  const { handleSubmit, formState, control, watch, setValue } =
    useCustomForm<FormSchema>({
      resolver: yupResolver(schema),
      defaultValues: {
        phone: null,
        source: null,
        template: null,
      },
    });

  const { data: templatesData } = useWACloudTemplates(
    { status: 'APPROVED' },
    { enabled: watch('source')?.value === 'whatsapp_meta' }
  );

  useEffect(() => {
    setValue('phone', phoneDefaultValue || null);
  }, [phoneDefaultValue, setValue]);

  const { mutate: startChat, isLoading: loadingStartChat } = useStartChat();

  const onSubmit = ({ phone, source, template }: FormSchema) => {
    const parsedPhoneNumber = formatPhonePayload(phone!);

    const channel = source!.value;

    const sources = mapKnownIntegrationsToSources[channel];

    if (sources) {
      startChat(
        {
          conversationId: parsedPhoneNumber,
          sources,
          ...(sources === 'WHATSAPP_META' &&
            template && { templateId: template.id, message: template.message }),
        },
        {
          onSuccess: () => {
            onChangeShowModal(false);
          },
        }
      );
    }
  };

  return (
    <BBBModal
      title="Start chat with new number"
      show={showModal}
      onHide={() => {
        onChangeShowModal(false);
      }}
      disableSave={!watch('phone')?.phoneNumber || !watch('source')}
      size="lg"
      handleSave={() => handleSubmit(onSubmit)()}
      footer
      submitText="Start chat"
      loadingSave={loadingStartChat}
    >
      <Controller
        control={control}
        name="source"
        render={({ field }) => (
          <ChannelOptions
            defaultPlaceholder
            channel={field.value}
            onChangeChannel={(val) => field.onChange(val!)}
            label="Source"
            className="mb-3"
            source="chat-initiation"
            placeholder="Choose source"
          />
        )}
      />
      {watch('source')?.value === 'whatsapp_meta' && (
        <Controller
          control={control}
          name="template"
          render={({ field }) => (
            <BBBSelect
              options={templatesData}
              optionLabel="name"
              optionValue="id"
              placeholder="Choose message template"
              label="Template"
              containerClassName="mb-3"
              value={field.value}
              onValueChange={(opt) => {
                field.onChange(opt!);
              }}
              withCreateRedirectOption
              onClickCreateRedirect={() => {
                history.push({
                  pathname: `/bitchat/settings/wa-cloud-template/new`,
                });
              }}
              createRedirectLabel="Create new template"
              persistCreateRedirectOption
            />
          )}
        />
      )}
      {watch('source.value') && (
        <BBBTelInput
          isHookForm
          label="Phone number"
          control={control}
          controlName="phone"
          placeholder="23 456 789"
          error={formState.errors.phone?.message}
        />
      )}
    </BBBModal>
  );
}
