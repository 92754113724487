import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeft, Edit2, MoreHorizontal } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { isEqual, omit, pick } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import * as yup from 'yup';
import { shallow } from 'zustand/shallow';
import useStore, { RFState } from '../store';

import {
  BBBButton,
  BBBCard,
  BBBPrimarySwitch,
  BBBTooltip,
} from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import { EVERYONE_AGENT_ID, staticAgents } from '@/constants/bitChat/agent';
import { activeTimeOptions, fallbackOptions } from '@/constants/bitChat/flows';
import { mapIntegrationChannels } from '@/constants/whatsApp';
import useUserCompanyDetail from '@/hooks/auth/useUserCompanyDetail';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { useConfirmLeaveModal, useDiscardOrLeaveModal } from '@/hooks/modal';
import {
  useDeleteFlow,
  useDuplicateFlow,
  useFlow,
  useToggleFlow,
  useUpdateFlow,
} from '@/hooks/whatsApp/flow';
import { AgentOption } from '@/pages/BitChat/components/AgentOptions';
import { ChannelIntegrations } from '@/types/bitChat/integrations';
import { UserCompanyWithAssociation } from '@/types/systemLogin/association';
import { Flow } from '@/types/whatsApp/flow';
import { formatUserDisplayName } from '@/utils/auth';

type FlowInfo = Pick<
  Flow,
  'activeTime' | 'fallbackAgent' | 'fallbackMessage' | 'fallbackType' | 'name'
>;

const defaultValue = {
  name: 'Untitled chatbot',
  fallbackType: null,
  activeTime:
    activeTimeOptions.find((opt) => opt.value === 'always_on')?.value || null,
  fallbackAgent: undefined,
  fallbackMessage: '',
};

const sortOrder = {
  message: 0,
  welcome_action: 1,
  trigger: 2,
  action: 3,
  child1: 4,
  back: 5,
};

const flowInfoSchema = yup.object({
  name: yup.string().required().label('Flow name'),
  fallbackType: yup
    .mixed<typeof fallbackOptions[number] | null>()
    .label('Fallback type'),
  activeTime: yup
    .mixed<typeof activeTimeOptions[number]>()
    .label('Active time'),
  fallbackMessage: yup
    .string()
    .label('Fallback message')
    .when('fallbackType', {
      is: (value: typeof fallbackOptions[number] | null) =>
        value?.value === 'send_message',
      then: (rule) => rule.required(),
    }),
  fallbackAgent: yup
    .mixed<UserCompanyWithAssociation>()
    .label('Fallback agent')
    .when('fallbackType', {
      is: (value: typeof fallbackOptions[number] | null) =>
        value?.value === 'assign_to_agent',
      then: (rule) => rule.required(),
    }),
});

type FlowInfoForm = Omit<FlowInfo, 'fallbackAgent' | 'fallbackType'> & {
  fallbackAgent: AgentOption | undefined;
  fallbackType: typeof fallbackOptions[number] | null;
};

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
});

export default function FlowInfo() {
  const { nodes, edges, setNodes, setEdges } = useStore(selector, shallow);
  const { mutate: toggleFlow } = useToggleFlow();

  const { id } = useParams<{ id: string }>();

  const [showOptions, setShowOptions] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FlowInfoForm>({
    resolver: yupResolver(flowInfoSchema),
    defaultValues: defaultValue,
  });

  const queryParams = useQuerySearchParams();

  const triggerType = queryParams.get('trigger');
  const channel = queryParams.get('source') as ChannelIntegrations | undefined;

  const { mutate: updateFlow, isLoading: loadingUpdate } = useUpdateFlow();
  const { data: settingsData } = useSettings();

  const { data } = useFlow(id);

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(
    id === 'new'
  );

  //@ts-ignore
  const customSort = useCallback(
    (a, b) => {
      if (data?.nodes) {
        //@ts-ignore
        const typeComparison = sortOrder[a.type] - sortOrder[b.type];

        return typeComparison;
      }

      return 0;
    },
    [data?.nodes]
  );

  const nodesFromApi = useMemo(
    () =>
      data?.nodes
        .sort(customSort)
        .sort((a, b) => {
          if (a.type === 'child1' && b.type === 'child1') {
            return a.data.index - b.data.index;
          }
          return 0;
        })
        .map((node) => ({
          id: node.id,
          type: node.type || undefined,
          position: { x: Math.floor(node.x), y: Math.floor(node.y) },
          data: {
            action: node.jobType,
            ...node.data,
            ...(node.type === 'child1' && { parentId: node.parentNode }),
          },
          parentNode: node.parentNode,
          extent: 'parent',
          draggable: !node.parentNode,
        })) ?? [],
    [customSort, data?.nodes]
  );

  const edgesFromApi = useMemo(
    () =>
      data?.edges.map((edge) => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
        type: 'custom',
      })) ?? [],
    [data?.edges]
  );

  const { data: _fallbackAgent } = useUserCompanyDetail(
    data?.fallbackAgent?.toString() || undefined
  );

  const flowInfoFromApi = useMemo<FlowInfoForm>(() => {
    const fallbackAgent = _fallbackAgent
      ? {
          ..._fallbackAgent,
          formattedName: formatUserDisplayName(_fallbackAgent.user),
        }
      : undefined;

    return {
      name: data?.name || defaultValue.name,
      fallbackType: data?.fallbackType
        ? fallbackOptions.find(
            (option) => option.value === data.fallbackType
          ) || null
        : defaultValue.fallbackType,
      activeTime: data?.activeTime || defaultValue.activeTime,
      fallbackAgent,
      fallbackMessage: data?.fallbackMessage || defaultValue.fallbackMessage,
    };
  }, [
    data?.activeTime,
    data?.fallbackMessage,
    data?.fallbackType,
    data?.name,
    _fallbackAgent,
  ]);

  useEffect(() => {
    if (data) {
      reset(flowInfoFromApi);
      //@ts-ignore
      setNodes(nodesFromApi);
      setEdges(edgesFromApi);
      setIsInitialLoadComplete(true);
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [
    data,
    edgesFromApi,
    flowInfoFromApi,
    nodesFromApi,
    reset,
    setEdges,
    setNodes,
  ]);

  const isFormEqual = useMemo(() => {
    if (loadingUpdate || isInitialLoadComplete) {
      const formData = {
        ...watch(),
        nodes: nodes.map((node) => {
          const { data, id, position, type, extent, draggable, parentNode } =
            node;

          return {
            data,
            id,
            position: node.parentNode
              ? null
              : {
                  x: Math.floor(position.x),
                  y: Math.floor(position.y),
                },
            type,
            extent,
            draggable,
            parentNode,
          };
        }),
        edges: edges.map(({ id, source, target }) => ({
          id,
          source,
          target,
          type: 'custom',
        })),
      };

      return isEqual(formData, {
        ...flowInfoFromApi,
        nodes: nodesFromApi.map((node) => ({
          ...node,
          position: node.parentNode ? null : node.position,
        })),
        edges: edgesFromApi,
      });
    }

    return true;
  }, [
    loadingUpdate,
    isInitialLoadComplete,
    watch(),
    nodes,
    edges,
    flowInfoFromApi,
    nodesFromApi,
    edgesFromApi,
  ]);

  const [handleDiscard] = useDiscardOrLeaveModal({
    isFormEqual,
    module: id !== 'new' ? 'changes' : 'flow',
    onSave: (hide, leaveCb) => {
      submit({
        onSuccess: () => {
          hide();
          leaveCb?.();
        },
      });
    },
    loadingSave: loadingUpdate,
  });

  const submit = (params?: {
    onSuccess?: (data: Flow) => void;
    skipRedirect?: boolean;
  }) => {
    handleSubmit((_payload) => {
      const { fallbackAgent, fallbackMessage, fallbackType, ..._flowInfo } =
        _payload;
      const payload = {
        ..._flowInfo,
        fallbackType: fallbackType?.value,
        ...(fallbackType?.value === 'assign_to_agent'
          ? {
              fallbackAgent: fallbackAgent?.userId || null,
            }
          : fallbackType?.value === 'send_message'
          ? { fallbackMessage }
          : {}),
        nodes: nodes.map((node) => {
          const { action, ...data } = node.data;

          return {
            id: node.id,
            type: node.type,
            data:
              Object.keys(data).length > 0
                ? node.type === 'action'
                  ? action === 'assign_ticket'
                    ? {
                        agent: data.agent
                          ? !('user' in data.agent)
                            ? data.agent
                            : {
                                ...(staticAgents.includes(data.agent.user.id)
                                  ? data.agent.user.id === EVERYONE_AGENT_ID
                                    ? {
                                        id: EVERYONE_AGENT_ID,
                                        meta: {
                                          count: parseInt(
                                            data.agent.formattedName.match(
                                              /\((\d+)\)/
                                            )[1]
                                          ),
                                        },
                                      }
                                    : {
                                        id: data.agent.user.id,
                                        name: data.agent.formattedName2,
                                      }
                                  : {
                                      id: data.agent.user.id,
                                      email: data.agent.user.email,
                                      profile: pick(data.agent.user.profile, [
                                        'firstName',
                                        'lastName',
                                      ]),
                                    }),
                              }
                          : null,
                      }
                    : action === 'set_ticket_category'
                    ? {
                        ticketCategory: data.ticketCategory,
                      }
                    : action === 'run_flow'
                    ? { flow: pick(data.flow, ['id', 'name']) }
                    : { customerTags: data.customerTags }
                  : node.type === 'child1'
                  ? omit(data, ['parentId'])
                  : node.type === 'welcome_action'
                  ? {
                      ...omit(data, ['flow', 'message']),
                      ...(action === 'run_flow' && {
                        flow: pick(data.flow, ['id', 'name']),
                      }),
                      ...(action === 'send_message' &&
                        data.message && {
                          message: data.message,
                        }),
                    }
                  : data
                : undefined,
            jobType: action,
            x: node.position.x,
            y: node.position.y,
            parentNode: node.parentNode,
          };
        }),
        edges: edges.map((edge) => ({
          id: edge.id,
          source: edge.source,
          target: edge.target,
        })),
      };

      setIsInitialLoadComplete(false);

      updateFlow(
        {
          triggerType: triggerType
            ? triggerType === 'ai'
              ? 'auto_pilot'
              : triggerType === 'message'
              ? 'message'
              : 'welcome_action'
            : undefined,
          channel: channel ? mapIntegrationChannels[channel] : undefined,
          ...data,
          ...payload,
          skipRedirect: params?.skipRedirect,
        },
        {
          onSuccess: params?.onSuccess,
          onSettled: () => {
            setIsInitialLoadComplete(true);
          },
        }
      );
    })();
  };

  return (
    <div className="bg-white p-6 flex items-center gap-4" id="flow-info-ref">
      <Link
        to={`/bitchat/flows?${queryParams.toString()}`}
        className="w-8 h-8 rounded border border-primary-border flex justify-center items-center cursor-pointer"
      >
        <ArrowLeft size={16} />
      </Link>
      {!isInitialLoadComplete ? (
        <Skeleton width={100} />
      ) : (
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <NameInput
              value={field.value}
              onValueChange={field.onChange}
              errors={errors.name?.message}
            />
          )}
        />
      )}
      {data?.triggerType === 'welcome_action' &&
        settingsData?.welcomeMessage?.id === data.id && (
          <BBBBadge type="info" text="Welcome message" />
        )}
      {data?.activeTime === 'off_time' &&
        settingsData?.awayMessage?.id === data.id && (
          <BBBBadge type="ai" text="Away message" />
        )}
      {data && (
        <BBBPrimarySwitch
          checked={data.status === 'ACTIVE'}
          onChange={(value) => {
            toggleFlow({
              id: data.id,
              status: value ? 'ACTIVE' : 'INACTIVE',
              name: data.name,
            });
          }}
        />
      )}
      <div className="grow" />
      <BBBButton
        variant="secondary"
        text={id !== 'new' ? 'Discard changes' : 'Discard chatbot'}
        disabled={isFormEqual}
        onClick={() => handleDiscard()}
      />
      <BBBButton
        text={id !== 'new' ? 'Save changes' : 'Save chatbot'}
        disabled={isFormEqual}
        loadingState={loadingUpdate}
        onClick={() => {
          submit();
        }}
      />
      {((id === 'new' && triggerType !== 'welcome-action') ||
        (data && data?.triggerType !== 'welcome_action')) && (
        <div className="relative">
          <MoreHorizontal
            className="cursor-pointer"
            onClick={() => {
              setShowOptions(true);
            }}
          />
          <AnimatePresence>
            {showOptions && (
              <OptionsCard
                isFormEqual={isFormEqual}
                onClose={() => setShowOptions(false)}
                submit={submit}
                loadingSave={loadingUpdate}
              />
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}

function OptionsCard({
  onClose,
  isFormEqual,
  submit,
  loadingSave,
}: {
  onClose: () => void;
  isFormEqual: boolean;
  submit: (params?: {
    onSuccess?: (data: Flow) => void;
    skipRedirect?: boolean;
  }) => void;
  loadingSave: boolean;
}) {
  const { id } = useParams<{ id: string }>();

  const optionsRef = useRef<HTMLDivElement | null>(null);
  const reactFlowRef = document.querySelector('.react-flow__pane')!;

  const { mutate: deleteFlow } = useDeleteFlow();
  const { mutate: duplicateFlow } = useDuplicateFlow();
  const { data: settingsData, status: settingsStatus } = useSettings();

  const confirm = useConfirmationModal();

  useOutsideAlerter(optionsRef, onClose, true, reactFlowRef);
  useOutsideAlerter(optionsRef, onClose, true);

  const leaveModal = useConfirmLeaveModal();

  return (
    <motion.div
      className="absolute right-0 z-20"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <BBBCard className="p-0 md:p-0 w-48" ref={optionsRef}>
        <div
          className="px-5 pb-2 pt-3 cursor-pointer transition-colors rounded-tl-xl rounded-tr-xl hover:bg-secondary-surface"
          onClick={() => {
            if (isFormEqual) {
              duplicateFlow(id);
            } else {
              leaveModal({
                module: 'flow',
                onSaveProgress: (hide) => {
                  submit({
                    onSuccess: (data) => {
                      hide();
                      duplicateFlow(data.id);
                    },
                    skipRedirect: true,
                  });
                },
                cancelText: 'Cancel',
                loadingSave,
              });
            }

            onClose();
          }}
        >
          Duplicate chatbot
        </div>
        {id !== 'new' && settingsStatus === 'success' && (
          <div
            className="px-5 pb-3 pt-2 hover:bg-secondary-surface transition-colors rounded-bl-2xl rounded-br-2xl text-danger-main cursor-pointer"
            onClick={() => {
              confirm({
                title: 'Delete chatbot',
                description:
                  settingsData.awayMessage?.id === id &&
                  settingsData.awayMessageActive
                    ? 'This chatbot is being used for away message. Are you sure you want to delete this chatbot? Once you delete the action cannot be undone.'
                    : settingsData.welcomeMessage?.id === id &&
                      settingsData.welcomeMessageActive
                    ? 'This chatbot is being used for welcome message. Are you sure you want to delete this chatbot? Once you delete the action cannot be undone.'
                    : 'Are you sure want to delete this chatbot? Once you delete the action cannot be undone',
                onAccept: (hide) => {
                  deleteFlow(id, {
                    onSuccess: () => {
                      hide();
                    },
                  });
                },
                deleteModal: true,
                submitText: 'Delete chatbot',
              });
            }}
          >
            Delete chatbot
          </div>
        )}
      </BBBCard>
    </motion.div>
  );
}

function NameInput({
  value,
  errors,
  onValueChange,
}: {
  value: string;
  errors?: string;
  onValueChange: (val: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [nameWidth, setNameWidth] = useState<number>();
  const [localValue, setLocalValue] = useState(value);

  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLDivElement | null>(null);
  const flowInputRef = useRef<HTMLDivElement | null>(null);
  const reactFlowRef = document.querySelector('.react-flow__pane')!;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const blurNameInput = useCallback(() => {
    setEditing(false);
    setTimeout(() => {
      if (!localValue) {
        onValueChange(defaultValue.name);
      } else {
        onValueChange(localValue);
      }
    }, 0);
  }, [localValue, onValueChange]);

  useOutsideAlerter(flowInputRef, blurNameInput, undefined, reactFlowRef);
  useOutsideAlerter(flowInputRef, blurNameInput, undefined);

  return (
    <div
      className={cx(
        'cursor-pointer flex items-center gap-1',
        !editing && 'cursor-pointer'
      )}
      onClick={() => {
        if (!editing) {
          setEditing(true);
          setNameWidth(nameRef.current?.clientWidth);
          setTimeout(() => {
            nameInputRef.current?.focus();
          }, 0);
        }
      }}
      ref={flowInputRef}
    >
      <div>
        {!editing ? (
          <BBBTooltip
            targetRef={nameRef}
            content={localValue}
            position="bottom"
          >
            <div
              ref={nameRef}
              className="text-2xl max-w-[256px] truncate border-b-[2px] border-transparent text-neutral-60 font-medium"
            >
              {localValue}
            </div>
          </BBBTooltip>
        ) : (
          <input
            className="text-2xl border-b-[2px] border-secondary-main font-medium max-w-[256px]"
            style={{ width: nameWidth }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                blurNameInput();
              }
            }}
            value={localValue}
            onChange={({ target: { value } }) => setLocalValue(value)}
            ref={nameInputRef}
            placeholder="Input chatbot"
          />
        )}
        {errors && <div className="text-danger-main">{errors}</div>}
      </div>
      {!editing && (
        <div className="flex-none">
          <Edit2 size={14} />
        </div>
      )}
    </div>
  );
}
