import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import MoonLoader from 'react-spinners/MoonLoader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { socketBitChat } from 'socket';
import {
  setConnectIntegration,
  setConnectIntegrationMeta,
} from 'stores/common';
import { twMerge as cx } from 'tailwind-merge';
import { FacebookAccountList } from 'types/facebook';
import { InstagramAccountList } from 'types/instagram';
import * as yup from 'yup';
import BetaBadge from '../Badge/BetaBadge';
import { BBBRadio } from '../ui/BBBRadio';

import { Spreadsheet, Worksheet } from '@/api/services/bitChat/google-sheets';
import GoogleRainbowIcon from '@/assets/icons/GoogleRainbowIcon';
import MetaIcon from '@/assets/icons/MetaIcon';
import PlusIcon from '@/assets/icons/PlusIcon';
import {
  BBBAlert,
  BBBButton,
  BBBCard,
  BBBModal,
  BBBSelect,
  BBBSpinner,
  BBBTextInput,
  CustomModalProp,
} from '@/components/ui';
import api from '@/config/api';
import { env } from '@/config/env';
import { integrationsMeta, KnownIntegration } from '@/constants/integrations';
import {
  useConnectStamped,
  useConnectYotpo,
} from '@/hooks/bitApp/integration/integrations';
import {
  useAccounts,
  useSpreadsheets,
  useWorksheets,
} from '@/hooks/bitChat/google-sheets';
import { useToggleIntegration } from '@/hooks/bitChat/integration/integrations';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useCustomForm from '@/hooks/common/useCustomForm';
import useConnectFacebook from '@/hooks/crm/useConnectFacebook';
import useConnectInstagram from '@/hooks/crm/useConnectInstagram';
import useConnectWhatsApp from '@/hooks/crm/useConnectWhatsApp';
import useConnectWhatsAppCloud from '@/hooks/crm/useConnectWhatsAppCloud';
import useListFacebook from '@/hooks/integrations/useListFacebook';
import useListInstagram from '@/hooks/integrations/useListInstagram';
import useSaveFacebook from '@/hooks/integrations/useSaveFacebook';
import useSaveInstagram from '@/hooks/integrations/useSaveInstagram';
import useSaveWhatsAppCloud from '@/hooks/integrations/useSaveWhatsAppCloud';
import useWhatsAppCloudAccounts from '@/hooks/integrations/useWhatsAppCloudAccounts';
import { useActiveCompany, useUserId } from '@/hooks/rtk/selector';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { useSaveGoogleSheets } from '@/hooks/whatsApp/form';
import { WhatsAppCloudAccountList } from '@/types/whatsApp/chat';
import { toast } from '@/utils/common/toast';
import { cn } from '@/utils/styles';

const schema = yup.object({
  account: yup.mixed().required().label('Account'),
});

export default function IntegrationModal() {
  const connectionName = useAppSelector(
    (state) => state.common.connectIntegration?.name
  );

  if (!connectionName) return null;

  return connectionName === 'whatsapp' ? (
    <WhatsAppBusinessModal />
  ) : connectionName === 'whatsapp_meta' ? (
    <WhatsAppCloudModal />
  ) : connectionName === 'facebook' ? (
    <FacebookModal />
  ) : connectionName === 'instagram' ? (
    <InstagramModal />
  ) : connectionName === 'stamped' ? (
    <StampedModal />
  ) : connectionName === 'yotpo' ? (
    <YotpoModal />
  ) : connectionName === 'google_sheets' ? (
    <GoogleSheetsModal />
  ) : null;
}

const reviewIntegrationSchema = yup.object({
  publicKey: yup.string().required().label('Public key'),
  privateKey: yup.string().required().label('Private key'),
  storeHash: yup.string().label('Store hash'),
});

type ReviewIntegrationSchema = {
  publicKey: string;
  privateKey: string;
  storeHash?: string;
};

const reviewIntegrationDefaultValue: ReviewIntegrationSchema = {
  publicKey: '',
  privateKey: '',
  storeHash: '',
};

const yotpoMeta = integrationsMeta['yotpo'];
const stampedMeta = integrationsMeta['stamped'];
const whatsAppMeta = integrationsMeta['whatsapp'];

function _IntegrationModal({
  name,
  beta,
  subtitle,
  ...props
}: { name: KnownIntegration; beta?: boolean } & CustomModalProp) {
  const dispatch = useAppDispatch();
  const meta = integrationsMeta[name];

  return (
    <BBBModal
      title={
        <div className="flex items-center gap-2">
          {meta.title} {beta && <BetaBadge />}
        </div>
      }
      titleIcon={meta.icon && <meta.icon size={48} />}
      subtitle={subtitle || meta.description}
      onHide={() => {
        dispatch(setConnectIntegration(null));
      }}
      size="2xl"
      {...props}
    />
  );
}

function YotpoModal() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useCustomForm<ReviewIntegrationSchema>({
    resolver: yupResolver(reviewIntegrationSchema),
    defaultValues: reviewIntegrationDefaultValue,
  });

  const { mutate: connect, isLoading: loadingSave } = useConnectYotpo();

  return (
    <_IntegrationModal
      show
      name="yotpo"
      loadingSave={loadingSave}
      footer
      cancelText="Discard"
      submitText="Continue"
      handleSave={() => {
        handleSubmit((data) => {
          connect(data);
        })();
      }}
    >
      <div className="text-neutral-70 mb-3">How to connect?</div>
      <div>
        <ol>
          {yotpoMeta.guides?.map((p, index) => (
            <li className="mb-4" key={p}>
              {index + 1}. {p}
            </li>
          ))}
        </ol>
      </div>
      <div className="flex flex-col gap-4">
        <BBBTextInput
          placeholder="Input your app key"
          label="App Key"
          isHookForm
          control={control}
          controlName="publicKey"
          error={errors.publicKey?.message}
        />
        <BBBTextInput
          placeholder="Input your app secret"
          label="App Secret"
          isHookForm
          control={control}
          controlName="privateKey"
          error={errors.privateKey?.message}
        />
      </div>
    </_IntegrationModal>
  );
}

function GoogleSheetsModal() {
  const [selectedUser, setSelectedUser] = useState<string>();
  const [selectedSpreadsheet, setSelectedSpreadsheet] =
    useState<Spreadsheet | null>(null);

  const [selectedWorksheet, setSelectedWorksheet] = useState<Worksheet | null>(
    null
  );

  const [steps, setSteps] = useState<number>(0);

  const { mutate: saveGoogleSheets, isLoading: loadingSaveGoogleSheets } =
    useSaveGoogleSheets();
  const formId = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'google_sheets'
      ? state.common.connectIntegration?.meta?.formId
      : undefined
  );

  return (
    <_IntegrationModal
      name="google_sheets"
      show
      footer
      withoutCancel
      submitText="Continue"
      disableSave={!selectedUser}
      handleSave={() => {
        if (steps === 0) {
          setSteps(1);
        } else if (steps === 1) {
          saveGoogleSheets({
            formId,
            spreadSheetId: selectedSpreadsheet!.id,
            sheetId: selectedWorksheet!.sheetId.toString(),
            emailAccount: selectedUser!,
          });
        }
      }}
      loadingSave={loadingSaveGoogleSheets}
    >
      {steps === 0 && (
        <GoogleSheetsStep0
          selectedUser={selectedUser}
          onChangeSelectedUser={setSelectedUser}
        />
      )}
      {steps === 1 && (
        <GoogleSheetsStep1
          selectedSpreadsheet={selectedSpreadsheet}
          selectedWorksheet={selectedWorksheet}
          onChangeSpreadsheet={setSelectedSpreadsheet}
          onChangeWorksheet={setSelectedWorksheet}
          email={selectedUser!}
        />
      )}
    </_IntegrationModal>
  );
}

function GoogleSheetsStep0({
  selectedUser,
  onChangeSelectedUser,
}: {
  selectedUser: string | undefined;
  onChangeSelectedUser: (val: string) => void;
}) {
  const { data: accounts, isLoading: loadingAccounts } = useAccounts();

  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  useEffect(() => {
    socketBitChat.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  const onAuth = useCallback(() => {
    queryClient.invalidateQueries([`google-sheets-accounts`]);
    toast.success('Succesfully connected to google sheets');
  }, [queryClient]);

  useEffect(() => {
    socketBitChat.on(`google-sheets-authorized`, onAuth);

    return () => {
      socketBitChat.off(`google-sheets-authorized`, onAuth);
    };
  }, [onAuth]);

  return (
    <>
      {loadingAccounts ? (
        <>
          {Array.from({ length: 4 }).map((_, i) => (
            <Skeleton
              borderRadius={'1rem'}
              height={'4rem'}
              className={cx(i < 3 && 'mb-2')}
              key={i}
            />
          ))}
        </>
      ) : (
        accounts?.map((user) => (
          <BBBCard
            className={cx(
              'flex items-center gap-2.5 mb-2 last:mb-0 cursor-pointer transition-colors',
              selectedUser === user.email &&
                'border-secondary-main pointer-events-none'
            )}
            key={user.email}
            onClick={() => {
              onChangeSelectedUser(user.email);
            }}
          >
            <GoogleRainbowIcon size={'1.25rem'} />
            <div className="text-sm text-neutral-60">{user.email}</div>
          </BBBCard>
        ))
      )}
      <BBBButton
        text="Add account"
        variant="secondary"
        icon={<PlusIcon />}
        iconPosition="right"
        className="mt-6"
        onClick={() => {
          window.open(
            `${env.REACT_APP_API_BBBGATEWAY_BASEURL}/bitchat/google-sheets/oauth?companyId=${activeCompany}`,
            'mywindow',
            'menubar=1,resizable=1,width=1200,height=700'
          );
        }}
      />
    </>
  );
}

function GoogleSheetsStep1({
  email,
  selectedSpreadsheet,
  selectedWorksheet,
  onChangeSpreadsheet,
  onChangeWorksheet,
}: {
  email: string;
  selectedSpreadsheet: Spreadsheet | null;
  selectedWorksheet: Worksheet | null;
  onChangeSpreadsheet: (val: Spreadsheet) => void;
  onChangeWorksheet: (val: Worksheet) => void;
}) {
  const [searchSpreadsheet, setSearchSpreadsheet] = useState('');
  const [searchWorksheet, setSearchWorksheet] = useState('');

  const { data: spreadSheets, isLoading: loadingSpreadsheets } =
    useSpreadsheets(email, { search: searchSpreadsheet });

  const { data: workSheets, isLoading: loadingWorksheets } = useWorksheets(
    email,
    selectedSpreadsheet?.id,
    { search: searchWorksheet }
  );

  return (
    <>
      <BBBSelect
        label="Spreadsheet"
        placeholder="Choose spreadsheet"
        containerClassName="mb-6"
        options={spreadSheets}
        optionLabel="name"
        optionValue="id"
        value={selectedSpreadsheet}
        onValueChange={(val) => onChangeSpreadsheet(val!)}
        loading={loadingSpreadsheets}
        isSearchable
        search={searchSpreadsheet}
        onChangeSearch={setSearchSpreadsheet}
      />
      <BBBSelect
        label="Worksheet"
        placeholder="Choose worksheet"
        options={workSheets}
        optionLabel="title"
        optionValue="index"
        isDisabled={!selectedSpreadsheet}
        loading={loadingWorksheets}
        isSearchable
        search={searchWorksheet}
        onChangeSearch={setSearchWorksheet}
        value={selectedWorksheet}
        onValueChange={(val) => onChangeWorksheet(val!)}
      />
    </>
  );
}

function StampedModal() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useCustomForm<ReviewIntegrationSchema>({
    resolver: yupResolver(reviewIntegrationSchema),
    defaultValues: reviewIntegrationDefaultValue,
  });

  const { mutate: connect, isLoading: loadingSave } = useConnectStamped();

  return (
    <_IntegrationModal
      show
      name="stamped"
      footer
      cancelText="Discard"
      submitText="Continue"
      handleSave={() => {
        handleSubmit((data) => {
          connect(data);
        })();
      }}
      loadingSave={loadingSave}
    >
      <div className="text-neutral-70 mb-3">How to connect?</div>
      <div>
        <ol>
          {stampedMeta.guides?.map((p, index) => (
            <li className="mb-4" key={p}>
              {index + 1}. {p}
            </li>
          ))}
        </ol>
      </div>
      <div className="flex flex-col gap-4">
        <BBBTextInput
          placeholder="Input your API public key"
          label="API Public Key"
          isHookForm
          control={control}
          controlName="publicKey"
          error={errors.publicKey?.message}
        />
        <BBBTextInput
          placeholder="Input your API private key"
          label="API Private Key"
          isHookForm
          control={control}
          controlName="privateKey"
          error={errors.privateKey?.message}
        />
        <BBBTextInput
          placeholder="Input your store hash"
          label="Store hash"
          isHookForm
          control={control}
          controlName="storeHash"
          error={errors.storeHash?.message}
        />
      </div>
    </_IntegrationModal>
  );
}

function InstagramModal() {
  const activeCompany = useActiveCompany();
  const dispatch = useAppDispatch();
  const isConnecting = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'instagram'
      ? state.common.connectIntegration?.meta?.isLoadingConnect
      : false
  );

  const userId = useUserId();
  const { data: instagramLists } = useListInstagram();

  const { mutate: instagramSave, isLoading: isLoadingInstagramSave } =
    useSaveInstagram();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useCustomForm<{ account: InstagramAccountList }>({
    resolver: yupResolver(schema),
  });

  const [, setEnable] = useConnectInstagram();
  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    toggle('instagram-connecting', {
      show: isLoadingInstagramSave,
      text: 'Connecting to your Instagram',
      variant: 'loading',
    });
  }, [isLoadingInstagramSave, toggle]);

  useEffect(() => {
    dispatch(
      setConnectIntegrationMeta({ isLoadingConnect: isLoadingInstagramSave })
    );
  }, [dispatch, isLoadingInstagramSave]);

  if (!instagramLists?.length) {
    return (
      <_IntegrationModal
        name="instagram"
        show={!isConnecting}
        subtitle={'Connect Instagram via Facebook Business Manager'}
        footer
        submitText={
          <div className="flex items-center gap-1.5">
            <MetaIcon /> Connect to Meta
          </div>
        }
        submitClassName={'bg-[#1877f2] border-none text-white font-normal'}
        submitVariant={null}
        submitHref={`${env.REACT_APP_API_BBBGATEWAY_BASEURL}/whatsapp/instagram/integrations?companyId=${activeCompany}&userId=${userId}`}
        handleSave={() => {
          setEnable(true);
        }}
      >
        <p className="mb-6">
          Full setup guide{' '}
          <a
            className="text-link underline"
            href="https://www.bitbybit.studio/knowledge-base/how-to-integrate-your-instagram-business-with-bitchat"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
        <p>
          <span className="font-bold">What you will need:</span>
          <ul className="list-disc ml-4">
            <li>Instagram Business Account</li>
            <li>A Facebook Page that is linked to your Instagram</li>
            <li>Enable “Allow access to messages” in Instagram</li>
          </ul>
        </p>
      </_IntegrationModal>
    );
  }

  const hasSelectedValue = !!watch('account');

  return (
    <_IntegrationModal
      name="instagram"
      show={!isConnecting}
      subtitle={'Photo and video sharing social networking service'}
      footer
      submitText="Connect"
      handleSave={() => {
        setEnable(true);
        handleSubmit((data) => {
          instagramSave(data.account.pageId);
        })();
      }}
      loadingSave={isLoadingInstagramSave}
      disableSave={!hasSelectedValue}
      customDelete={
        <Disconnect integrationId={3} integrationName="instagram" />
      }
    >
      <Controller
        control={control}
        name="account"
        render={({ field }) => (
          <BBBSelect
            label="Choose your Instagram account to connect"
            options={instagramLists}
            optionLabel="instagramUsername"
            optionValue="pageId"
            placeholder="Choose instagram account"
            value={field.value}
            onValueChange={field.onChange}
            error={errors.account?.message}
          />
        )}
      />
    </_IntegrationModal>
  );
}

function FacebookModal() {
  const activeCompany = useActiveCompany();
  const userId = useUserId();
  const isConnecting = useAppSelector((state) =>
    state.common.connectIntegration?.name === 'facebook'
      ? state.common.connectIntegration?.meta?.isLoadingConnect
      : false
  );
  const { data: facebookLists } = useListFacebook();

  const { mutate: facebookSave, isLoading: isLoadingFacebookSave } =
    useSaveFacebook();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useCustomForm<{ account: FacebookAccountList }>({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const { toggle } = useConfirmationBanner();

  const [, setEnable] = useConnectFacebook();

  useEffect(() => {
    toggle('facebook-connecting', {
      show: isLoadingFacebookSave,
      text: 'Connecting to your Facebook',
      variant: 'loading',
    });
  }, [isLoadingFacebookSave, toggle]);

  useEffect(() => {
    dispatch(
      setConnectIntegrationMeta({ isLoadingConnect: isLoadingFacebookSave })
    );
  }, [dispatch, isLoadingFacebookSave]);

  if (!facebookLists?.length) {
    return (
      <_IntegrationModal
        name="facebook"
        subtitle={
          'Social networking site connect and share with friends online'
        }
        show
        footer
        submitText={
          <div className="flex items-center gap-1.5">
            <MetaIcon /> Connect to Meta
          </div>
        }
        submitClassName={'bg-[#1877f2] border-none text-white font-normal'}
        submitVariant={null}
        submitHref={`${env.REACT_APP_API_BBBGATEWAY_BASEURL}/whatsapp/meta/facebook/oauth?companyId=${activeCompany}&userId=${userId}`}
        handleSave={() => {
          setEnable(true);
        }}
      >
        <p className="mb-6">
          Full setup guide{' '}
          <a
            className="text-link underline"
            href="https://www.bitbybit.studio/knowledge-base/how-to-integrate-facebook-messenger-with-bitchat"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
        <p>
          <span className="font-bold">What you will need:</span>
          <ul className="list-disc ml-4">
            <li>Facebook Account</li>
            <li>Facebook Page with admin access</li>
          </ul>
        </p>
      </_IntegrationModal>
    );
  }

  const hasSelectedValue = !!watch('account');

  return (
    <_IntegrationModal
      name="facebook"
      subtitle={
        'Integrate with Facebook to manage interactions and boost conversions.'
      }
      show={!isConnecting}
      onHide={() => {
        dispatch(setConnectIntegration(null));
      }}
      loadingSave={isLoadingFacebookSave}
      footer
      submitText="Connect"
      handleSave={() => {
        setEnable(true);
        handleSubmit((data) => {
          facebookSave(data.account.id);
        })();
      }}
      disableSave={!hasSelectedValue}
      customDelete={<Disconnect integrationId={6} integrationName="facebook" />}
    >
      <Controller
        control={control}
        name="account"
        render={({ field }) => (
          <BBBSelect
            label="Choose your Facebook page to connect"
            options={facebookLists}
            optionLabel="name"
            optionValue="id"
            placeholder="Choose facebook page"
            value={field.value}
            onValueChange={field.onChange}
            error={errors.account?.message}
          />
        )}
      />
    </_IntegrationModal>
  );
}

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

const preferenceOptions = [
  { label: 'I already have a WhatsApp API account', value: 'have' },
  { label: "I don't have WhatsApp API", value: 'dont-have' },
];

function WhatsAppCloudModal() {
  const [preference, setPreference] = useState<string | null>(null);

  const activeCompany = useActiveCompany();
  const userId = useUserId();

  const [, setEnable] = useConnectWhatsAppCloud();

  useEffect(() => {
    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      let js: HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // JavaScript SDK configuration and setup
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '298540639720865', // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v20.0', // Graph API version
      });
    };
  }, []);

  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          const { code, accessToken } = response.authResponse;

          api.whatsApp.v1
            .post(
              `/meta/embed-signup/callback`,
              {
                companyId: activeCompany,
                userId: userId,
                code,
                accessToken,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .catch((err) => {
              toast.error('Failed while authenticating, please try again');
            });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '838530751584045', // configuration ID goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {},
        },
      }
    );
  };

  const { data: whatsappList } = useWhatsAppCloudAccounts();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useCustomForm<{ account: WhatsAppCloudAccountList }>({
    resolver: yupResolver(schema),
  });

  const { mutate: whatsappCloudSave, isLoading: isLoadingWhatsappCloudSave } =
    useSaveWhatsAppCloud();

  const hasSelectedValue = !!watch('account');

  const hasWhatsappLists = !!whatsappList?.length;

  return (
    <_IntegrationModal
      name="whatsapp_meta"
      show
      footer
      subtitle="Connect and unlock the full potential of WhatsApp API."
      submitText={
        <div className="flex items-center gap-1.5">
          <MetaIcon /> Connect to Meta
        </div>
      }
      submitClassName={'bg-[#1877f2] border-none text-white font-normal'}
      submitVariant={null}
      handleSave={() => {
        if (!whatsappList?.length) {
          if (preference === 'dont-have') {
            setEnable('embedded');
            launchWhatsAppSignup();
          } else {
            setEnable('default');
            window.open(
              `${env.REACT_APP_API_BBBGATEWAY_BASEURL}/whatsapp/meta/whatsapp_cloud_api/oauth?companyId=${activeCompany}&userId=${userId}`,
              '_blank'
            );
          }
        } else {
          handleSubmit((data) => {
            whatsappCloudSave(data.account.id);
          })();
        }
      }}
      disableSave={hasWhatsappLists ? !hasSelectedValue : !preference}
      loadingSave={isLoadingWhatsappCloudSave}
      customDelete={
        hasWhatsappLists && (
          <Disconnect integrationId={2} integrationName="whatsapp_meta" />
        )
      }
    >
      {hasWhatsappLists ? (
        <Controller
          control={control}
          name="account"
          render={({ field }) => (
            <BBBSelect
              label="Choose your WhatsApp account to connect"
              options={whatsappList}
              optionLabel="metadata.number"
              optionValue="id"
              placeholder="Choose WhatsApp account"
              value={field.value}
              onValueChange={field.onChange}
              error={errors.account?.message}
            />
          )}
        />
      ) : (
        <>
          <p className="mb-6">
            Full setup guide{' '}
            <a
              className="text-link underline"
              href="https://www.bitbybit.studio/knowledge-base/how-to-create-and-connect-whatsapp-cloud-api-with-bitbybit"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
          <p className="mb-3">
            <span className="font-bold">What you will need:</span>
            <ul className="list-disc ml-4">
              <li>Available phone number</li>
            </ul>
          </p>
          <BBBRadio
            options={preferenceOptions}
            vertical
            value={preference}
            onChange={setPreference}
          />
        </>
      )}
    </_IntegrationModal>
  );
}

function WhatsAppBusinessModal() {
  const meta = useAppSelector((s) => s.common.connectIntegration?.meta);

  useConnectWhatsApp();

  return (
    <>
      {meta?.isLoadingConnect && (
        <BBBModal show z={101} centerBody>
          <div className="flex items-center gap-2">
            Connecting to WhatsApp <BBBSpinner height={16} />
          </div>
        </BBBModal>
      )}

      <_IntegrationModal show={meta?.modalShow} name="whatsapp" size="3xl">
        <div className="flex flex-col gap-8">
          <div className="flex">
            <div className="grow">
              <div className="text-neutral-70 mb-3">How to connect?</div>
              <div>
                <ol>
                  {whatsAppMeta.guides?.map((p, index) => (
                    <li className="mb-4" key={p}>
                      {index + 1}. {p}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="text-left flex items-center">
              {meta?.isLoading ? (
                <MoonLoader />
              ) : (
                <div className="flex flex-col items-center">
                  <div>
                    <img src={meta?.qrUrl} alt="QR" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <BBBAlert
            message="Your WhatsApp number is potentially banned by Meta if you use this feature for spam or scam activities. Use this feature at your own risk."
            type="secondary"
            className="leading-5"
          />
        </div>
      </_IntegrationModal>
    </>
  );
}

function Disconnect({
  integrationId,
  integrationName,
}: {
  integrationId: number;
  integrationName: KnownIntegration;
}) {
  const { mutate: toggleIntegration, isLoading: loadingToggleIntegration } =
    useToggleIntegration();

  const confirm = useConfirmationModal();

  const integrationTitle = integrationsMeta[integrationName].title;

  return (
    <span
      className={cn(
        'underline cursor-pointer',
        loadingToggleIntegration && 'pointer-events-none opacity-50'
      )}
      onClick={() => {
        confirm({
          title: `Disconnect from ${integrationTitle}`,
          onAccept: (hide) => {
            toggleIntegration({
              integrationId,
              status: 'DISCONNECTED',
            });
            hide();
          },
          deleteModal: true,
          submitText: 'Disconnect',
          description: `Are you sure want to disconnect from ${integrationTitle}? You can reconnect anytime.`,
        });
      }}
    >
      Disconnect
    </span>
  );
}
