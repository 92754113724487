import { useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import useColumn from '../hooks/useColumn';

import BBBTableV2 from '@/components/ui/BBBTableV2/BBBTableV2';
import { useAutomationMessageLog } from '@/hooks/bitCRM/automation/automation';
import useResponsive from '@/hooks/common/useResponsive';
import { MessageHistory } from '@/types/bitCRM/message-history';

type Props = {
  id: string;
  startDate: string;
  endDate: string;
};

const defaultValue: MessageHistory[] = [];

export default function MessageLog({ id, startDate, endDate }: Props) {
  const isTablet = useResponsive('lg');

  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selected, setSelected] = useState<MessageHistory[]>([]);

  const { data: logData, isLoading } = useAutomationMessageLog(id, {
    size: pageSize,
    page: pageIndex,
    search,
    startDate,
    endDate,
  });

  const defaultColumns = useColumn();

  return (
    <>
      <div className={cx(`my-6 ${isTablet && 'px-4 hidden'}`)}>
        <p className="text-2xl text-primary-main">Message Log</p>
      </div>
      {!isTablet && (
        <BBBTableV2
          loadingBody={isLoading}
          data={logData?.content || defaultValue}
          headers={defaultColumns}
          dataId="id"
          isSelectable
          withoutActionOption
          isColumnHeightFixed
          onChangeSelectable={setSelected}
          selected={selected}
          searchPlaceholder="Search on message log"
          isPaginate
          isSearchable
          searchValue={search}
          onChangeSearch={setSearch}
          pagination={{
            page: pageIndex + 1,
            limit: pageSize,
            onChange: (page) => setPageIndex(page - 1),
            total: logData?.totalElements || 0,
            maxShown: 3,
          }}
          isShowLimit
          limitValue={pageSize}
          onLimitChange={(page) => {
            setPageSize(page!);
            setPageIndex(0);
          }}
        />
      )}
    </>
  );
}
