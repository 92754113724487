import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { BaseEdge, EdgeProps, getSmoothStepPath } from 'reactflow';
import colors from 'constants/common/colors';
import {
  useInstagramIntegrations,
  useWhatsappBusinessIntegration,
  useWhatsappCloudIntegration,
} from 'hooks/bitChat/integration/integrations';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import { ChannelIntegrations } from 'types/bitChat/integrations';
import { shallow } from 'zustand/shallow';
import Flow from 'components/Flow/Flow';
import FlowInfo from './components/FlowInfo';
import Modals from './components/Modals/Modals';
import ActionNode from './components/Nodes/ActionNode';
import BackNode from './components/Nodes/BackNode';
import Child1Node from './components/Nodes/Child1Node';
import MessageNode from './components/Nodes/MessageNode';
import TriggerNode from './components/Nodes/TriggerNode';
import WelcomeActionNode from './components/Nodes/WelcomeActionNode';
import useStore, { RFState } from './store';

import { BBBSpinner } from '@/components/ui';
import { channelOptions } from '@/constants/bitChat/channel';
import useResponsive from '@/hooks/common/useResponsive';
import DesktopOptimized from '@/layouts/DesktopOptimized';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  panOnDrag: !state.connectingNode,
});

const nodeTypes = {
  trigger: TriggerNode,
  action: ActionNode,
  message: MessageNode,
  child1: Child1Node,
  welcome_action: WelcomeActionNode,
  back: BackNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

function CustomEdge({ id, ...props }: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    ...props,
    borderRadius: 20,
  });

  const hasExpandedState = useStore((state) => {
    const expandedKeys = state.expandState
      ? Object.entries(state.expandState)
          .filter(([k, v]) => !!v)
          .map(([k]) => k)
      : [];

    return !!state.edges.filter(
      (edge) => expandedKeys.includes(edge.target) && edge.id === id
    ).length;
  });

  return (
    <BaseEdge
      id={id}
      path={edgePath}
      style={hasExpandedState ? { stroke: colors.secondary.main } : undefined}
    />
  );
}

const channelOptionsValues = channelOptions.map((c) => c.value);

export default function Chatbot() {
  const isMobile = useResponsive('sm');

  if (isMobile) {
    return (
      <DesktopOptimized
        backLink="/bitchat/flows"
        description="Chatbot is more optimized in desktop website. Switch over to be able to
        set up your chatbot."
      />
    );
  }

  return <_ChatbotDefault />;
}

function _ChatbotDefault() {
  const { id } = useParams<{ id: string }>();

  const queryParams = useQuerySearchParams();

  const triggerType = queryParams.get('trigger');
  const source = queryParams.get('source') as ChannelIntegrations | undefined;

  if (
    id === 'new' &&
    (!triggerType || (source && !channelOptionsValues.includes(source)))
  )
    return <Redirect to={'/404'} />;

  return <ChatbotCheckChannelConnection />;
}

function ChatbotCheckChannelConnection() {
  const queryParams = useQuerySearchParams();

  const source = queryParams.get('source');

  const { data: waCloudIntegration, status: statusWaCloudIntegration } =
    useWhatsappCloudIntegration({
      enabled: source === 'wa-cloud',
    });

  const { data: waBusinessIntegration, status: statusWaIntegration } =
    useWhatsappBusinessIntegration({
      enabled: source === 'wa-business',
    });

  const { data: instagramIntegration, status: statusInstagramIntegration } =
    useInstagramIntegrations({
      enabled: source === 'instagram',
    });

  if (
    statusInstagramIntegration === 'loading' ||
    statusWaCloudIntegration === 'loading' ||
    statusWaIntegration === 'loading'
  ) {
    return <BBBSpinner />;
  }

  if (source === 'wa-cloud' && waCloudIntegration?.status !== 'CONNECTED')
    return <Redirect to={'/bitchat/flows'} />;

  if (source === 'wa-business' && waBusinessIntegration?.status !== 'CONNECTED')
    return <Redirect to={'/bitchat/flows'} />;

  if (source === 'instagram' && instagramIntegration?.status !== 'CONNECTED')
    return <Redirect to={'/bitchat/flows'} />;

  return <_Chatbot />;
}

function _Chatbot() {
  const { nodes, edges, onEdgesChange, onNodesChange, onConnect, panOnDrag } =
    useStore(selector, shallow);

  const loadingDuplicateFlow = useStore((s) => s.loadingDuplicateFlow);

  if (loadingDuplicateFlow)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="mb-2">
          <BBBSpinner />
        </div>
        Duplicating chatbot
      </div>
    );

  return (
    <>
      <FlowInfo />
      <Flow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        panOnDrag={panOnDrag}
        edgeTypes={edgeTypes}
      >
        <Modals />
      </Flow>
      <CustomStyle />
    </>
  );
}

function CustomStyle() {
  const activeAddState = useStore((s) => s.activeAddState);

  const activeChildNodes = useStore((s) =>
    s.nodes
      .filter((node) =>
        s.activeAddState ? node.parentNode === s.activeAddState : false
      )
      .map((node) => node.id)
  );

  return (
    <>
      {activeAddState && (
        <style>
          {`.react-flow__node[data-id="${activeAddState}"] {
        z-index: 1000 !important;
      }
      `}
        </style>
      )}
      {!!activeChildNodes.length &&
        activeChildNodes.map((node) => (
          <style key={node}>
            {`.react-flow__node[data-id="${node}"] {
        z-index: 1000 !important;
      }
      `}
          </style>
        ))}
    </>
  );
}
