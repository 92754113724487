import { useMemo, useState } from 'react';

import { BBBRenderHtml } from '@/components/ui';
import { HeadersTypes } from '@/components/ui/BBBTableV2/BBBTableV2.type';
import { Link } from '@/components/ui/Link';
import { CampaignTemplate } from '@/types/bitCRM/template';
import { getTemplateUrl, isTemplateNoAction } from '@/utils/bitCRM';
import { formatDate2 } from '@/utils/common/date';

const useColumnMobile = () => {
  const [showReadMore, setShowReadMore] = useState('');

  return useMemo<HeadersTypes<CampaignTemplate>>(() => {
    return [
      {
        accesor: 'templateName',
        renderHeader: () => 'Template',
        isSortable: true,
        render: (info) => (
          <div className="flex flex-col gap-[10px] px-2">
            {!isTemplateNoAction(info) ? (
              <Link
                to={getTemplateUrl(info.type, info.id)}
                className="text-primary-main group-hover:underline"
              >
                {info.templateName}
              </Link>
            ) : (
              info.templateName
            )}
            <div className="max-w-[280px] text-sm text-primary-main">
              <span
                className={`${showReadMore !== info.id ? 'line-clamp-2' : ''}`}
              >
                <BBBRenderHtml
                  content={
                    info.type === 'CAMPAIGN_WHATSAPP' ||
                    info.type === 'AUTOMATION_WHATSAPP'
                      ? info.message
                      : info.body?.message || ''
                  }
                />
              </span>
              {(info.type === 'CAMPAIGN_WHATSAPP' ||
              info.type === 'AUTOMATION_WHATSAPP'
                ? (info.message || '').length
                : (info.body?.message || '').length) < 150 ? null : (
                <span className="underline font-medium cursor-pointer hover:text-[#767676] transition-all">
                  {showReadMore !== info.id ? (
                    <p
                      onClick={(e) => {
                        setShowReadMore(info.id);
                        e.stopPropagation();
                      }}
                    >
                      Read more
                    </p>
                  ) : (
                    <p
                      onClick={(e) => {
                        setShowReadMore('');
                        e.stopPropagation();
                      }}
                    >
                      Read less
                    </p>
                  )}
                </span>
              )}
            </div>
            <p className="text-[#757575] text-sm truncate">
              {formatDate2(info.updatedAt, '12-hour', 'chat')}
            </p>
          </div>
        ),
      },
    ];
  }, [showReadMore]);
};

export default useColumnMobile;
