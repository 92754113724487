import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Edit3 } from 'react-feather';
import { Control, Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';
import { v4 } from 'uuid';
import * as yup from 'yup';
import AiTagging from './AiTagging';
import CoPilot from './CoPilot';

import BetaBadge from '@/components/Badge/BetaBadge';
import ShopifyAwareWrapper from '@/components/ShopifyAwareWrapper';
import {
  BBBCard,
  BBBFileUpload,
  BBBModal,
  BBBPrimarySwitch,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';
import {
  aiAgentOptions,
  RECO_AGENT_ID,
  ROBO_AGENT_ID,
  TRACKO_AGENT_ID,
} from '@/constants/bitChat/agent';
import { acceptedCrmFileTypes } from '@/constants/crm';
import {
  useAgentVariables,
  useUpdateAgentVariables,
} from '@/hooks/ai/agent-variables';
import useAutoPilotRules from '@/hooks/bitChat/autoPilot/useAutoPilotRules';
import useDeleteResource from '@/hooks/bitChat/autoPilot/useDeleteResource';
import useUploadKnowledgeBase from '@/hooks/bitChat/autoPilot/useUploadKnowledgeBase';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useUpdateSettings from '@/hooks/bitChat/settings/useUpdateSettings';
import { useUploadFile } from '@/hooks/common/upload';
import useConfirmationBanner from '@/hooks/common/useConfirmationBanner';
import useCustomForm from '@/hooks/common/useCustomForm';
import { AgentVariableAdditionalInstruction } from '@/types/ai/agent-variables';
import { FileType } from '@/types/utils/file';
import { Nullable } from '@/types/utils/nullable';
import { Settings, SettingsAITagging } from '@/types/whatsApp/settings';
import { convertRemoteUrlToFileType } from '@/utils/bitCRM';

export type AISettingsForm = Pick<Settings, 'copilot' | 'aiDescription'> & {
  aiTags: (Omit<SettingsAITagging, 'id'> & {
    aiTagId: string;
  })[];
  aiChatbotName: string;
  aiRole: string;
  aiBrandName: string;
};

const aiSettingsSchema = yup.object().shape({
  aiTags: yup.array(
    yup.object().shape({
      label: yup.string().required().label('Label'),
      description: yup.string().required().label('Description'),
      values: yup.string().required().label('Value'),
      aiTagId: yup.string(),
    })
  ),
});

export default function AISettings() {
  const { data } = useSettings();
  const { data: agentVariablesData } = useAgentVariables();
  const { mutate: updateSettings, isLoading: loadingUpdateSettings } =
    useUpdateSettings();
  const { mutate: updateAgentVariables, isLoading: loadingAgentVariables } =
    useUpdateAgentVariables();

  const { control, reset, watch, handleSubmit } = useForm<AISettingsForm>({
    defaultValues: {
      copilot: false,
      aiTags: [],
      aiDescription: '',
      aiBrandName: '',
      aiChatbotName: '',
      aiRole: '',
    },
    resolver: yupResolver(aiSettingsSchema),
  });

  const dataFromApi = useMemo<Partial<AISettingsForm>>(
    () => ({
      copilot: data?.copilot || false,
      aiTags: data?.aiTags?.length
        ? data?.aiTags.map(({ id, ...tag }) => ({ ...tag, aiTagId: id }))
        : [],
      aiDescription: agentVariablesData?.brandDescription || '',
      aiBrandName: agentVariablesData?.brandName || '',
      aiChatbotName: agentVariablesData?.name || '',
      aiRole: agentVariablesData?.role || '',
    }),
    [
      data?.copilot,
      data?.aiTags,
      agentVariablesData?.brandDescription,
      agentVariablesData?.brandName,
      agentVariablesData?.name,
      agentVariablesData?.role,
    ]
  );

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const { toggle } = useConfirmationBanner();

  useEffect(() => {
    const onSubmit = ({
      aiTags,
      copilot,
      ...data
    }: Partial<AISettingsForm>) => {
      updateAgentVariables(
        {
          name: data.aiChatbotName,
          role: data.aiRole,
          brandName: data.aiBrandName,
          brandDescription: data.aiDescription,
        },
        {
          onSuccess: () => {
            updateSettings({
              copilot,
              aiTags: aiTags?.map((tag) => ({
                ...tag,
                id: tag.aiTagId || v4(),
              })),
            });
          },
        }
      );
    };

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('save-ai', {
      show: !isFormEqual,
      text: 'Unsaved changes',
      isCancelable: true,
      cancelLabel: 'Discard changes',
      acceptLabel: 'Save changes',
      variant:
        loadingUpdateSettings || loadingAgentVariables
          ? 'loading'
          : 'actionable',
      onCancel: reset,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    loadingAgentVariables,
    loadingUpdateSettings,
    reset,
    toggle,
    updateAgentVariables,
    updateSettings,
    watch(),
  ]);

  return (
    <>
      <CoPilot control={control} />
      <AiAgent control={control} />
      <AiTagging control={control} />
    </>
  );
}

function AiAgent({ control }: { control: Control<AISettingsForm> }) {
  const { data: agentVariablesData } = useAgentVariables();
  const { mutate: updateAgentVariables } = useUpdateAgentVariables();

  const [editAgent, setEditAgent] = useState<Omit<
    EditAgentFormSchema,
    'files'
  > | null>(null);

  const additionalInstruction = agentVariablesData?.additionalInstructions;

  const recoEnabled = additionalInstruction?.recommendationEnabled || false;
  const recoInstructions = additionalInstruction?.recommendation || '';

  const trackoEnabled = additionalInstruction?.orderEnabled || false;
  const trackoInstructions = additionalInstruction?.order || '';

  const roboEnabled = additionalInstruction?.faqEnabled || false;
  const roboInstructions = additionalInstruction?.faq || '';

  const previousEnabledData: Partial<AgentVariableAdditionalInstruction> = {
    recommendationEnabled: recoEnabled,
    faqEnabled: roboEnabled,
    orderEnabled: trackoEnabled,
  };

  return (
    <>
      {editAgent && (
        <EditAgentModal
          onClose={() => setEditAgent(null)}
          agent={editAgent}
          previousEnabledData={previousEnabledData}
        />
      )}
      <BBBCard
        title={
          <>
            AI agents <BetaBadge />
          </>
        }
        className="mb-cardBottom"
        desc="Let AI handle your customer’s questions first"
      >
        <div className="flex flex-col mb-6">
          <BBBTextInput
            isHookForm
            control={control}
            controlName="aiChatbotName"
            label="Chatbot name"
            placeholder="Give a name to your AI agent"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="aiRole"
            label="Role"
            placeholder="What is the main job of this AI agent? eg: customer service, sales agent, etc"
            containerClassname="mb-5"
          />
          <BBBTextInput
            isHookForm
            control={control}
            controlName="aiBrandName"
            label="Brand name"
            placeholder="What is your brand name?"
            containerClassname="mb-5"
          />
          <BBBTextAreaInput
            isHookForm
            control={control}
            controlName="aiDescription"
            label="Description of your business (optional)"
            rows={5}
            placeholder="Describe your business to give more context to AI Agents. Some examples below:“ZARA is a Spanish multinational fast-fashion company with over 2,000 stores worldwide. We specialize in selling clothing, accessories, beauty products, and perfumes.”"
          />
        </div>

        <div className="text-xl text-primary-main mb-4">Skillset</div>

        {aiAgentOptions.map((agent) => {
          const data =
            agent.value === RECO_AGENT_ID
              ? {
                  instructions: recoInstructions,
                  active: recoEnabled,
                }
              : agent.value === ROBO_AGENT_ID
              ? {
                  instructions: roboInstructions,
                  active: roboEnabled,
                }
              : {
                  instructions: trackoInstructions,
                  active: trackoEnabled,
                };

          return (
            <div
              key={agent.name}
              className="flex mb-4 last:mb-0 items-center gap-5"
            >
              <div className="grow">
                <div
                  className="flex items-center group cursor-pointer gap-1"
                  onClick={() => {
                    setEditAgent({
                      instruction1: data.instructions,
                      agentType: agent.value,
                    });
                  }}
                >
                  <div className="mb-1 group-hover:underline">
                    {agent.speciality.label}
                  </div>
                  <span className="text-[#535353] opacity-0 group-hover:opacity-100 transition-opacity">
                    <Edit3 size={'0.5625rem'} />
                  </span>
                </div>
                <div className="text-xs text-neutral-50">
                  {agent.speciality.description}
                </div>
              </div>
              <ShopifyAwareWrapper appType="BITCHAT" fallbackToChildren>
                {({ connectMiddleware }) => (
                  <BBBPrimarySwitch
                    checked={data.active}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(value) => {
                      function update() {
                        updateAgentVariables({
                          additionalInstructions: {
                            ...previousEnabledData,
                            ...(agent.value === RECO_AGENT_ID
                              ? {
                                  recommendationEnabled: value,
                                }
                              : agent.value === ROBO_AGENT_ID
                              ? {
                                  faqEnabled: value,
                                }
                              : {
                                  orderEnabled: value,
                                }),
                          },
                        });
                      }
                      if (value) {
                        connectMiddleware(() => update());
                      } else {
                        update();
                      }
                    }}
                  />
                )}
              </ShopifyAwareWrapper>
            </div>
          );
        })}
      </BBBCard>
    </>
  );
}

const editAgentSchema = yup.object().shape({
  files: yup.mixed<Nullable<FileType[]>>(),
  instruction1: yup.string().label('Instruction'),
});

const defaultValues = {
  files: null,
  instruction1: '',
};

type EditAgentFormSchema = {
  files: Nullable<FileType[]>;
  instruction1: string;
  agentType: number;
};

function EditAgentModal({
  agent,
  onClose,
  previousEnabledData,
}: {
  agent: Omit<EditAgentFormSchema, 'files'>;
  onClose: () => void;
  previousEnabledData: Partial<AgentVariableAdditionalInstruction>;
}) {
  const { mutate: updateAgentVariables, isLoading: loadingAgentVariables } =
    useUpdateAgentVariables();

  const { handleSubmit, control, watch, reset, setValue } =
    useCustomForm<EditAgentFormSchema>({
      resolver: yupResolver(editAgentSchema),
      defaultValues,
    });

  const { data } = useAutoPilotRules();
  const deleteResource = useDeleteResource();
  const { mutate: uploadKnowledge } = useUploadKnowledgeBase();

  const dataFromApi = useMemo<EditAgentFormSchema>(() => {
    return {
      files: data?.AutopilotResources
        ? [
            ...data.AutopilotResources.map((resource) => {
              const originalFileNameWithUniqueId =
                resource.filename.split('/')[1];
              return {
                ...convertRemoteUrlToFileType(resource.fileurl)!,
                meta: {
                  ...resource,
                  __fileName: originalFileNameWithUniqueId.slice(
                    originalFileNameWithUniqueId.indexOf('-') + 1
                  ),
                },
              };
            }),
          ]
        : null,
      instruction1: agent.instruction1,
      agentType: agent.agentType,
    };
  }, [agent.agentType, agent.instruction1, data?.AutopilotResources]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  const onSubmit = ({ files, instruction1 }: EditAgentFormSchema) => {
    updateAgentVariables(
      {
        additionalInstructions: {
          ...previousEnabledData,
          ...(agent.agentType === RECO_AGENT_ID
            ? {
                recommendation: instruction1,
              }
            : agent.agentType === TRACKO_AGENT_ID
            ? {
                order: instruction1,
              }
            : {
                faq: instruction1,
              }),
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );

    const filterDeletedResource = dataFromApi.files?.filter(
      (resource) =>
        !files?.some(
          (upResource) =>
            upResource.meta?.documentId === resource.meta?.documentId
        )
    );

    const uploadedResource = files?.filter(
      (resource) =>
        !filterDeletedResource?.some(
          (deletedResource) =>
            deletedResource.meta?.documentId === resource.meta?.documentId
        ) &&
        !dataFromApi.files?.some(
          (resourceFromApi) =>
            resourceFromApi.meta?.documentId === resource.meta?.documentId
        )
    );

    filterDeletedResource?.forEach((deletedResource) =>
      deleteResource(false).mutate({
        documentId: deletedResource.meta?.documentId,
      })
    );

    uploadedResource
      ?.filter((resource) => !!resource.remoteUrl)
      .forEach((file) =>
        uploadKnowledge({
          fileUrl: file?.remoteUrl,
        })
      );
  };

  const isFormEqual = isEqual(dataFromApi, watch());

  const agentType = watch('agentType');

  return (
    <BBBModal
      show
      title={`${
        agentType === RECO_AGENT_ID
          ? 'Product recommendation'
          : agentType === TRACKO_AGENT_ID
          ? 'Order tracking'
          : 'FAQ'
      } settings`}
      onHide={onClose}
      footer
      cancelText="Discard"
      disableSave={isFormEqual}
      handleSave={() => {
        handleSubmit(onSubmit)();
      }}
      submitText="Save"
      loadingSave={loadingAgentVariables}
    >
      <BBBTextAreaInput
        isHookForm
        control={control}
        controlName="instruction1"
        label="Additional instructions for AI agent (optional)"
        placeholder='Personalize your AI with additional prompts and instructions. Some examples below:"Ask the customer whether they are buying for themselves or for a gift. If a customer is purchasing as a gift, ask if they want it gift wrapped."'
        rows={5}
      />
      {agentType === ROBO_AGENT_ID && (
        <div className="mt-3">
          <div>FAQ File</div>
          <FileUploadDropzone
            onChangeFile={(file) =>
              setValue('files', [...(watch('files') || []), file])
            }
            accept={acceptedCrmFileTypes}
          />
          <Controller
            control={control}
            name="files"
            render={({ field }) => (
              <BBBFileUpload
                containerClassName="mt-2"
                files={field.value}
                onChangeFile={field.onChange}
                accept={acceptedCrmFileTypes}
                withoutAddButton
              />
            )}
          />
        </div>
      )}
    </BBBModal>
  );
}

function FileUploadDropzone({
  onChangeFile,
  accept,
}: {
  onChangeFile: (file: FileType) => void;
  accept: string;
}) {
  const { uploadFile } = useUploadFile();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: async (changedFiles) => {
        const file = changedFiles?.[0];
        const remoteUrl = await uploadFile(file);
        onChangeFile({
          fileData: file,
          remoteUrl,
        });
      },
      accept: Object.fromEntries(
        accept?.split(',').map((data) => [data, []]) || []
      ),
    });

  return (
    <div
      {...getRootProps()}
      className={cx(
        'mt-2 h-32 flex flex-col justify-center items-center border-dashed border-[1.5px] rounded-lg hover:border-secondary-main transition-colors cursor-pointer',
        isDragReject
          ? 'border-danger-main'
          : isDragAccept && 'border-secondary-main'
      )}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center justify-center">
        <div className="text-sm text-neutral-40">
          Upload your file here (CSV, PDF, TXT, JSON)
        </div>
      </div>
    </div>
  );
}
