import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Menu } from 'react-feather';
import {
  AnimatePresence,
  AnimationControls,
  motion,
  Target,
} from 'framer-motion';
import useOutsideAlerter from 'hooks/common/useOutsideAlerterv2';
import { twMerge as cx } from 'tailwind-merge';
import SidebarSectionNav from './SidebarSectionNav';
import SidebarSubNav from './SidebarSubNav';
import SidebarWrapper from './SidebarWrapper';

import BBBLogo from '@/assets/icons/BBBLogo';
import { appType } from '@/constants/common/app';
import { sidebarData, sidebarDatav2 } from '@/constants/layouts/sidebarData';
import useResponsive from '@/hooks/common/useResponsive';
import useSidebarMeta from '@/hooks/common/useSidebarMeta';
import { useAppDispatch, useAppSelector } from '@/hooks/rtk/store';
import { setSidebar } from '@/stores/common';

export const COLLAPSED_WIDTH = 80.5;
export const EXPANDED_WIDTH = 240;

type SidebarProps = {
  headerSlotRef: HTMLDivElement | null;
  animateControl: AnimationControls;
};

function Sidebar({ headerSlotRef, animateControl }: SidebarProps) {
  const isMobile = useResponsive('sm');

  const [activeSidebar, setActiveSidebar] = useState(false);

  const appWrapperRef = document.getElementById('app');

  return (
    <>
      {isMobile && headerSlotRef && (
        <>
          {createPortal(
            <HeaderMobile
              onHamburgerClick={() => setActiveSidebar((prev) => !prev)}
            />,
            headerSlotRef
          )}
        </>
      )}
      {isMobile && appWrapperRef ? (
        createPortal(
          <_Sidebar
            activeSidebar={activeSidebar}
            setActiveSidebar={setActiveSidebar}
            animateControl={animateControl}
          />,
          appWrapperRef
        )
      ) : (
        <_Sidebar
          activeSidebar={activeSidebar}
          setActiveSidebar={setActiveSidebar}
          animateControl={animateControl}
        />
      )}
    </>
  );
}

export default Sidebar;

function _Sidebar({
  activeSidebar,
  setActiveSidebar,
  animateControl,
}: {
  activeSidebar: boolean;
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  animateControl: AnimationControls;
}) {
  const [count, setCount] = useState(0);
  const [isAnimationMounted, setIsAnimationMounted] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const isMobile = useResponsive('sm');

  const expanded = useAppSelector((state) => state.common.sidebar.expanded);
  const enableMapping = useAppSelector(
    (state) => state.common.sidebar.enableMapping
  );

  const dispatch = useAppDispatch();

  const { parentPath, app, mapActiveSidebar } = useSidebarMeta();

  const [isHovering, setIsHovering] = useState(false);

  const hideLeftPanel =
    expanded && isHovering && enableMapping === false && parentPath.length > 1;

  const computedWidth = expanded
    ? isHovering
      ? EXPANDED_WIDTH
      : COLLAPSED_WIDTH
    : EXPANDED_WIDTH;

  const rightPanelProperties: Target = {
    top: 0,
    bottom: 0,
    position: 'absolute',
    width: computedWidth,
    zIndex: 200,
  };

  useOutsideAlerter(sidebarRef, () => setActiveSidebar(false), true);

  useEffect(() => {
    if (!isMobile) {
      if (count <= 1 && !isAnimationMounted) {
        setTimeout(() => {
          animateControl.set({
            marginLeft: computedWidth,
            transition: {
              type: 'tween',
              duration: 0.3,
            },
          });
          setIsAnimationMounted(true);
        }, 0);
      } else {
        animateControl.start({
          marginLeft: computedWidth,
          transition: {
            type: 'tween',
            duration: 0.3,
          },
        });
      }
    } else {
      animateControl.set({ marginLeft: 0 });
    }
  }, [animateControl, computedWidth, count, isAnimationMounted, isMobile]);

  useEffect(() => {
    setCount((prev) => prev + 1);
  }, [computedWidth]);

  return (
    <motion.div
      className={cx(
        'overflow-hidden',
        isMobile
          ? cx(
              'absolute top-0 left-0 w-60 overflow-auto bg-white transition-transform bottom-0 z-[200] transform -translate-x-full',
              activeSidebar && 'translate-x-0'
            )
          : cx('flex-none absolute top-0 left-0  bottom-0 z-[200]')
      )}
      animate={{
        width: computedWidth,
      }}
      initial={false}
      transition={{
        duration: 0.3,
        type: 'tween',
      }}
      id="sidebar-root"
      ref={sidebarRef}
    >
      {!hideLeftPanel && (
        <motion.div
          className={cx(
            'bg-white relative scrollbar-hide h-full flex flex-col overflow-hidden'
          )}
          animate={
            isMobile
              ? undefined
              : {
                  width: computedWidth,
                }
          }
          initial={false}
          transition={{
            duration: 0.3,
            type: 'tween',
          }}
          onHoverStart={() => setIsHovering(true)}
          onHoverEnd={() => setIsHovering(false)}
        >
          <SidebarWrapper
            isHovering={isHovering}
            onChangeHover={setIsHovering}
            app={app}
            setActiveSidebar={setActiveSidebar}
          >
            {sidebarData
              .filter((data) => !data.disabled)
              .map((item) => {
                return (
                  <div className="border-b" key={item.id}>
                    {item.child.map((subNav) => {
                      const realSubData = sidebarDatav2.find(
                        (data) => data.path === subNav
                      );

                      if (!realSubData) return null;

                      return (
                        <SidebarSectionNav
                          key={realSubData?.path}
                          data={realSubData}
                          onClick={() => {
                            dispatch(
                              setSidebar({
                                enableMapping: false,
                              })
                            );
                            setActiveSidebar(false);
                          }}
                          isWrapperHovering={isHovering}
                        />
                      );
                    })}
                  </div>
                );
              })}
          </SidebarWrapper>
        </motion.div>
      )}
      <AnimatePresence initial={false}>
        {appType.includes(app) && !enableMapping && (
          <motion.div
            initial={{
              x: '100%',
              ...rightPanelProperties,
            }}
            animate={{
              x: '0%',
              ...rightPanelProperties,
            }}
            exit={{
              x: '100%',
              ...rightPanelProperties,
            }}
            transition={{
              duration: 0.3,
            }}
            className={cx(
              'bg-white relative scrollbar-hide min-h-full flex flex-col overflow-auto'
            )}
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
          >
            <SidebarWrapper
              isHovering={isHovering}
              onChangeHover={setIsHovering}
              expandedVersion
              app={app}
              setActiveSidebar={setActiveSidebar}
            >
              <SidebarSubNav
                data={mapActiveSidebar}
                parent={sidebarDatav2.find(
                  (data) => data.path === parentPath.join('/')
                )}
                onBack={() =>
                  dispatch(
                    setSidebar({
                      enableMapping: true,
                    })
                  )
                }
                onClick={() => setActiveSidebar(false)}
                expanded={expanded && !isHovering}
              />
            </SidebarWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

function HeaderMobile({ onHamburgerClick }: { onHamburgerClick: () => void }) {
  return (
    <>
      <div className="flex justify-between items-center px-3 py-2">
        <div>
          <Menu size={30} onClick={onHamburgerClick} />
        </div>
        <div>
          <BBBLogo />
        </div>
        <div className="rounded-full w-[36px] h-[36px]" />
      </div>
    </>
  );
}
