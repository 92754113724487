import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { Virtuoso } from 'react-virtuoso';
import { twMerge as cx } from 'tailwind-merge';
import type { Ticket as TicketType } from 'types/whatsApp/ticket';
import { formatUserDisplayName } from 'utils/auth';
import { formatDate2 } from 'utils/common/date';
import { toast } from 'utils/common/toast';
import { dateFilter } from 'utils/ticket';
import TicketModal from './TicketModal/TicketModal';
import AssignTicketModal from './AssignTicketModal';
import RealtimeUpdates from './RealtimeUpdates';
import TicketCustomerName from './TicketCustomerName';
import UserDetail from './UserDetail';

import CheckIcon from '@/assets/icons/CheckIcon';
import InboxIcon from '@/assets/icons/InboxIcon';
import LinkOutIcon from '@/assets/icons/LinkOutIcon';
import { BBBCard, BBBContainer, BBBSelect } from '@/components/ui';
import BBBBadge from '@/components/ui/BBBBadge/BBBBadge';
import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import UpgradeText from '@/components/ui/BBBLimitAlert/UpgradeText';
import SearchInput from '@/components/ui/SearchInput';
import {
  aiOptions,
  EVERYONE_AGENT_ID,
  staticAgents,
} from '@/constants/bitChat/agent';
import {
  dateOptions,
  orderOptions,
  statusOptions,
} from '@/constants/bitChat/ticket';
import { integrationsMeta } from '@/constants/integrations';
import { mapSourceToKnownIntegrations } from '@/constants/whatsApp';
import useCompanyUsers from '@/hooks/auth/useCompanyUsers';
import useIsModuleRestricted from '@/hooks/auth/useIsModuleRestricted';
import useSettings from '@/hooks/bitChat/settings/useSettings';
import useQuerySearchParams from '@/hooks/common/url/useQuerySearchParams';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useResponsive from '@/hooks/common/useResponsive';
import useIsMaximumLevel from '@/hooks/pricing/useIsMaximumLevel';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { useUpdateChat } from '@/hooks/whatsApp/chat';
import useTickets from '@/hooks/whatsApp/useTickets';
import DesktopOptimized from '@/layouts/DesktopOptimized';

window.addEventListener('error', (e) => {
  if (
    e.message ===
      'ResizeObserver loop completed with undelivered notifications.' ||
    e.message === 'ResizeObserver loop limit exceeded'
  ) {
    e.stopImmediatePropagation();
  }
});

const defaultFilter = [
  {
    label: 'All',
    value: 'all',
    parentValue: 'ticket-tag',
  },
  {
    label: 'All',
    value: 'all',
    parentValue: 'agent',
  },
];

export default function Ticket() {
  const isMobile = useResponsive('sm');

  if (isMobile) {
    return (
      <DesktopOptimized
        description="Ticket page is more optimized in desktop website. Switch over to be able to see your tickets."
        backLink="/home"
      />
    );
  }

  return <_Ticket />;
}

function _Ticket() {
  const {
    data: pricingFeatureData,
    limit,
    validatePricing,
    loading: loadingPricing,
  } = useWithCRMPricing('BITCHAT', 'conversation');
  const { data: pricingData } = usePricingByApp('BITCHAT');
  const { isMaximumLevel } = useIsMaximumLevel('BITCHAT');

  const [filter, setFilter] = useState<Filter[]>(defaultFilter);
  const [order, setOrder] = useState<typeof orderOptions[number]>(
    orderOptions[0]
  );
  const [date, setDate] = useState<typeof dateOptions[number]>(dateOptions[0]);
  const [search, setSearch] = useState('');

  const [selectedTicket, setSelectedTicket] =
    useState<Pick<TicketType, 'id' | 'sources'>>();

  const confirm = useConfirmationModal();

  const ticketDateFilter = dateFilter(date.value);

  const tagFilters = filter.filter((opt) => opt.parentValue === 'ticket-tag');
  const agentFilters = filter.filter((opt) => opt.parentValue === 'agent');

  const {
    data: ticketsData,
    isInitialLoading: loadingTickets,
    hasNextPage: hasNextTickets,
    fetchNextPage: fetchNextTickets,
  } = useTickets({
    search,
    sort: order.value === 'newest' ? 'desc' : 'asc',
    ...ticketDateFilter,
    tags: tagFilters.some((opt) => opt.value === 'all')
      ? undefined
      : tagFilters.map((opt) => opt.value).join(','),
    agents: agentFilters.some((opt) => opt.value === 'all')
      ? undefined
      : agentFilters.map((opt) => opt.value).join(','),
  });

  const [draggingStatus, setDraggingStatus] =
    useState<typeof statusOptions[number]['value']>();

  const flattenedTicketsData = ticketsData?.pages.flatMap((page) => page.data);
  const meta = ticketsData?.pages.length
    ? ticketsData.pages[0].summary
    : undefined;

  const [draggedTicket, setDraggedTicket] = useState<{
    selectedTicket: TicketType;
    type: 'assign' | 'takeover-waiting' | 'takeover-active';
  }>();

  const { mutate: updateChat } = useUpdateChat();

  const action = (
    type: 'assign' | 'takeover-waiting' | 'takeover-active',
    selectedTicket: TicketType
  ) => {
    if (type === 'assign') {
      setDraggedTicket({
        selectedTicket,
        type,
      });
      return;
    }

    const agent = selectedTicket.agents?.[0];

    if (agent) {
      const displayName = staticAgents.includes(agent.userId)
        ? agent.userId === EVERYONE_AGENT_ID
          ? 'everyone'
          : 'AI agent'
        : document.getElementById(`ticket-user-${agent.userId}`)?.innerHTML;

      confirm({
        title: 'Takeover chat & resolve ticket',
        description: `Are you sure to takeover chat from ${displayName} and resolve this ticket?${
          type === 'takeover-active'
            ? ` ${displayName} will receive notification for this action`
            : ''
        }`,
        submitText: 'Takeover & resolve',
        onAccept: (hide) => {
          updateChat(
            {
              status: 'close',
              target: selectedTicket,
            },
            {
              onSuccess: () => {
                toast.success(`Ticket ${selectedTicket.id} has been resolved`);
                hide();
              },
            }
          );
        },
      });
    }
  };

  const { data: isRestricted } = useIsModuleRestricted(
    'BITCHAT_EDIT_TICKET_DIRECT'
  );

  const handleOnDragEnd = (result: DropResult) => {
    if (isRestricted) {
      return toast.error("You're not authorized to perform this action");
    }

    const { source, destination } = result;

    if (
      source.droppableId === 'Waiting' &&
      destination?.droppableId === 'Active' &&
      !loadingPricing
    ) {
      const _draggedTicket = flattenedTicketsData!.filter(
        (ticket) => ticket.status === 'Waiting'
      )[source.index];
      validatePricing(() => action('assign', _draggedTicket));
    }

    if (
      source.droppableId === 'Active' &&
      destination?.droppableId === 'Resolved'
    ) {
      const _draggedTicket = flattenedTicketsData!.filter(
        (ticket) => ticket.status === 'Active'
      )[source.index];

      action('takeover-active', _draggedTicket);
    }

    if (
      source.droppableId === 'Waiting' &&
      destination?.droppableId === 'Resolved'
    ) {
      const _draggedTicket = flattenedTicketsData!.filter(
        (ticket) => ticket.status === 'Waiting'
      )[source.index];

      action('takeover-waiting', _draggedTicket);
    }

    setDraggingStatus(undefined);
  };

  const query = useQuerySearchParams();
  const id = query.get('id');
  const source = query.get('source');

  useEffect(() => {
    if (id && source) {
      setSelectedTicket({
        id: Number(id),
        sources: source as TicketType['sources'],
      });
    }
  }, [id, source]);

  const [containerRef, setContainerRef] = useState(getTicketContainerRef());

  useEffect(() => {
    setTimeout(() => {
      setContainerRef(getTicketContainerRef());
    }, 0);
  });

  return (
    <BBBContainer id="ticket-container" className="pt-0">
      <div className="sticky top-0 pt-4 z-10 bg-neutral-20">
        <div className="text-2xl mb-titleToCard">Ticket</div>
        {selectedTicket && (
          <TicketModal
            ticket={selectedTicket}
            onHide={() => setSelectedTicket(undefined)}
          />
        )}
        {draggedTicket?.type === 'assign' && (
          <AssignTicketModal
            ticket={draggedTicket.selectedTicket}
            onHide={() => setDraggedTicket(undefined)}
          />
        )}
        <Filter
          search={search}
          setSearch={setSearch}
          order={order}
          setOrder={setOrder}
          date={date}
          setDate={setDate}
          filter={filter}
          setFilter={setFilter}
        />
        <div className="mb-2 grid grid-cols-3 gap-4">
          {statusOptions.map(({ label, value }) => {
            const ticketLength =
              value === 'Waiting'
                ? meta?.waiting_all
                : value === 'Active'
                ? meta?.active
                : meta?.resolved;

            return (
              <div key={value}>
                <BBBCard className="text-center md:py-4">
                  {label}{' '}
                  {loadingTickets ? (
                    <span>
                      <Skeleton width={20} height={20} borderRadius={9999} />
                    </span>
                  ) : (
                    <span>({ticketLength})</span>
                  )}
                </BBBCard>
              </div>
            );
          })}
        </div>
      </div>
      {pricingFeatureData && pricingData && (
        <BBBLimitAlert
          usage={pricingFeatureData.usage}
          appType="BITCRM"
          module={pricingFeatureData.pricingFeature.label}
          limit={limit}
          currentPlan={{
            label: pricingData.pricingModule.label,
            name: pricingData.pricingName,
          }}
          className="mb-4 rounded-lg"
          customExceedTresholdLabel={
            <>
              You have reached your conversation limit, all ticket changes from
              Waiting to Active will be disabled.{' '}
              {!isMaximumLevel && (
                <>
                  <UpgradeText appType="BITCRM" /> now
                </>
              )}
            </>
          }
          customTip={`Tickets are related to conversations. Conversations limit will refresh every month. Limit refresh on: ${formatDate2(
            pricingData.lastBillingCycle || ''
          )}.`}
        />
      )}
      <RealtimeUpdates />
      <style>
        {`
      .height-preserving-container:empty {
        min-height: calc(var(--child-height));
        box-sizing: border-box;
        margin-bottom: 0.5rem;
        }
    `}
      </style>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={(drag) => setDraggingStatus(drag.source.droppableId)}
      >
        <InfiniteScroll
          scrollableTarget="ticket-container"
          next={fetchNextTickets}
          hasMore={!!hasNextTickets}
          dataLength={flattenedTicketsData?.length ?? 0}
          loader={null}
          className="!overflow-visible mb-12"
        >
          <div className="grid grid-cols-3 gap-4">
            {loadingTickets ? (
              <TicketsSkeleton />
            ) : statusOptions.every(
                (status) =>
                  !flattenedTicketsData?.filter(
                    (ticket) => ticket.status === status.value
                  ).length
              ) ? (
              <div className="text-center col-span-3 my-8">
                {search
                  ? 'Ticket you’ve searched not found'
                  : `There is no ticket ${
                      filter
                        ? `${
                            agentFilters.length
                              ? `for ${agentFilters
                                  .map((agent) => agent.label)
                                  .join(', ')}`
                              : ``
                          }${
                            tagFilters.length
                              ? ` with tag ${tagFilters
                                  .map((option) => option.label)
                                  .join(', ')}`
                              : ``
                          }`
                        : ``
                    } ${date.value}`}
              </div>
            ) : (
              flattenedTicketsData?.length &&
              statusOptions.map(({ value }) => {
                const ticketsData = flattenedTicketsData.filter(
                  (ticket) => ticket.status === value
                );
                return (
                  <Droppable
                    key={value}
                    droppableId={value}
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                      <TicketCard
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        ticket={ticketsData[rubric.source.index]}
                        status={value}
                        onClick={setSelectedTicket}
                        draggingStatus={draggingStatus}
                      />
                    )}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={cx(
                          'rounded-xl relative transition-[background]'
                        )}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {snapshot.isDraggingOver && (
                          <div className="absolute rounded-xl inset-0 z-10 bg-neutral-30/50" />
                        )}
                        {containerRef && (
                          <Virtuoso
                            customScrollParent={containerRef}
                            style={{ height: '100%' }}
                            data={ticketsData}
                            components={{
                              //@ts-ignore
                              Item: HeightPreservingItem,
                            }}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            itemContent={(index, ticket) => (
                              <Draggable
                                draggableId={ticket.id.toString()}
                                index={index}
                                key={ticket.id}
                              >
                                {(provided) => (
                                  <TicketCard
                                    provided={provided}
                                    ticket={ticket}
                                    isDragging={false}
                                    onClick={setSelectedTicket}
                                    status={value}
                                    draggingStatus={draggingStatus}
                                  />
                                )}
                              </Draggable>
                            )}
                          />
                        )}
                      </div>
                    )}
                  </Droppable>
                );
              })
            )}
          </div>
        </InfiniteScroll>
      </DragDropContext>
    </BBBContainer>
  );
}

function TicketCard({
  provided,
  ticket,
  isDragging,
  status: value,
  onClick,
  draggingStatus,
}: {
  provided: DraggableProvided;
  ticket: TicketType;
  isDragging: boolean;
  status: string;
  onClick: (ticket: TicketType) => void;
  draggingStatus: string | undefined;
}) {
  const Icon =
    integrationsMeta[mapSourceToKnownIntegrations[ticket.sources]].icon;

  return (
    <BBBCard
      key={ticket.id}
      className={cx(
        'mb-2 flex items-center gap-4 cursor-pointer',
        isDragging ? 'is-dragging' : ''
      )}
      onClick={() => onClick(ticket)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
        ...(draggingStatus !== value ? { transform: 'none' } : {}),
      }}
      data-ticket-status={value}
    >
      <div className="flex-none">{Icon && <Icon />}</div>
      <div className="grow">
        <div className="flex mb-3 items-center gap-1 ">
          <span className="font-bold text-primary-main">#{ticket.id}</span>
          <span className="grow">
            <LinkOutIcon color="#9E9E9E" size={8} />
          </span>
          <span className="text-xs text-neutral-50">
            {formatDate2(ticket.createdAt)}
          </span>
        </div>
        <div className="mb-3">
          From:{' '}
          <TicketCustomerName
            phoneNumber={ticket.clientNumber}
            from={ticket.from || undefined}
          />
        </div>
        {ticket.orderId && (
          <div className="mb-3 text-neutral-50">Order: {ticket.orderId}</div>
        )}
        {ticket.notes && (
          <div className="mb-3 text-neutral-50 break-words">
            Notes: {ticket.notes}
          </div>
        )}
        {value === 'Waiting' ? (
          <div className="flex gap-1.5 items-center">
            <InboxIcon />
            <div className="text-sm text-neutral-40">Waiting</div>
          </div>
        ) : value === 'Active' ? (
          <div className="flex items-center">
            <div className="flex items-center gap-1 grow">
              {!!ticket.agents?.length && (
                <UserDetail
                  userId={ticket.agents[0].userId}
                  name={ticket.agents[0].agentName || ''}
                  accent={ticket.agents[0].accent || undefined}
                  profilePicture={ticket.agents[0].profilePicture || undefined}
                />
              )}
            </div>
            {!!ticket.tags?.length &&
              ticket.tags.map((tag) => (
                <BBBBadge
                  key={tag.Tags.label}
                  text={tag.Tags.label}
                  type={tag.Tags.color}
                />
              ))}
          </div>
        ) : (
          <div className="flex gap-1.5 items-center">
            <CheckIcon color="#65B242" />
            <div className="text-sm grow text-success-hover">Resolved</div>
            {!!ticket.agents?.length && (
              <div className="flex gap-1 items-center">
                <UserDetail
                  userId={ticket.agents[0].userId}
                  name={ticket.agents[0].agentName || ''}
                  accent={ticket.agents[0].accent || undefined}
                  profilePicture={ticket.agents[0].profilePicture || undefined}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </BBBCard>
  );
}

type Filter = {
  label: ReactNode;
  value: string | number;
  parentValue?: string | undefined;
  isNestedDropdown?: boolean | undefined;
  hasMore?: boolean | undefined;
  fetchNext?: (() => void) | undefined;
};

function Filter({
  filter,
  setFilter,
  order,
  setOrder,
  search,
  setSearch,
  date,
  setDate,
}: {
  filter: Filter[];
  setFilter: React.Dispatch<React.SetStateAction<Filter[]>>;
  order: {
    label: string;
    value: string;
  };
  setOrder: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  date: {
    label: string;
    value: string;
  };
  setDate: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) {
  const companyUsersQuery = useCompanyUsers({
    limit: 5,
  });

  const { data: userCompanies, hasNextPage, fetchNextPage } = companyUsersQuery;
  const { data: settingsData } = useSettings();

  const userCompaniesData = useMemo(
    () =>
      userCompanies?.pages.flatMap((page) =>
        page.data.map((uC) => ({
          label: formatUserDisplayName(uC.user) ?? `-`,
          value: uC.userId,
          parentValue: 'agent',
        }))
      ),
    [userCompanies]
  );

  const ticketTagsData = useMemo(
    () =>
      settingsData?.ticketTags?.map((tag) => ({
        label: tag.label,
        value: tag.id,
        parentValue: 'ticket-tag',
      })),
    [settingsData]
  );

  const filterOptions = useMemo<Filter[]>(
    () => [
      {
        label: 'Ticket tag',
        value: 'ticket-tag',
      },
      {
        label: 'Agent',
        value: 'agent',
      },
      ...defaultFilter,
      ...(ticketTagsData ?? []),
      ...aiOptions,
      ...(userCompaniesData ?? []),
    ],
    [ticketTagsData, userCompaniesData]
  );

  return (
    <div className="flex justify-between mb-4">
      <div className="flex items-center gap-2">
        <BBBSelect
          placeholder="Filter"
          options={filterOptions}
          optionLabel="label"
          optionValue="value"
          value={filter}
          onValueChange={(opt, selected) => {
            if (selected!.value !== 'all') {
              setFilter(
                opt!.filter((_opt) =>
                  _opt.parentValue !== selected!.parentValue
                    ? true
                    : _opt.parentValue === selected!.parentValue &&
                      _opt.value !== 'all'
                )
              );
            } else {
              const currentFilterValue = filter.filter(
                (f) => f.parentValue === selected!.parentValue
              );
              if (currentFilterValue.some((f) => f.value !== 'all')) {
                setFilter(
                  opt!.filter((_opt) =>
                    _opt.parentValue !== selected!.parentValue
                      ? true
                      : _opt.parentValue === selected!.parentValue &&
                        _opt.value === 'all'
                  )
                );
              }
            }
          }}
          containerClassName="w-52"
          isGrouped
          optionGroupKey="parentValue"
          isMulti
          renderCustomSelectedValues={(values) =>
            !values?.filter((val) => val.value !== 'all').length ? (
              <div className="text-gray-400">Filter</div>
            ) : (
              <>{values?.filter((val) => val.value !== 'all').length} Filters</>
            )
          }
          isPaginated
          hasMore={!!hasNextPage}
          fetchNext={fetchNextPage}
          isClearable={
            filter.length ? !filter.every((opt) => opt.value === 'all') : false
          }
          enableToggleOption
          persistShowOptionsOnClick
        />
        <BBBSelect
          placeholder="Order"
          options={orderOptions}
          optionLabel="label"
          optionValue="value"
          value={order}
          onValueChange={(opt) => setOrder(opt!)}
        />
        <BBBSelect
          placeholder="Date"
          options={dateOptions}
          optionLabel="label"
          optionValue="value"
          value={date}
          onValueChange={(opt) => setDate(opt!)}
          containerClassName="w-40"
        />
      </div>
      <SearchInput
        placeholder="Search ticket"
        value={search}
        onValueChange={(value) => setSearch(value)}
      />
    </div>
  );
}

function TicketsSkeleton() {
  return (
    <>
      {statusOptions.map((status) => (
        <div className="w-full" key={status.value}>
          {Array.from({ length: 10 }).map((status, index) => (
            <Skeleton
              containerClassName="flex-1"
              key={index}
              height={100}
              className="mb-5"
              borderRadius={16}
            />
          ))}
        </div>
      ))}
    </>
  );
}

//@ts-ignore
export const HeightPreservingItem = ({ children, ...props }): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize == 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        //@ts-ignore
        '--child-height': `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

function getTicketContainerRef() {
  return document.getElementById('ticket-container');
}
