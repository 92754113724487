import { Link } from 'react-router-dom';

import InfoList from '@/components/InfoList/InfoList';
import TruncatedTags from '@/components/TruncatedTags/TruncatedTags';
import BBBLimitAlert from '@/components/ui/BBBLimitAlert/BBBLimitAlert';
import usePricingByApp from '@/hooks/pricing/usePricingByApp';
import useWithCRMPricing from '@/hooks/pricing/useWithCRMPricing';
import { cn } from '@/utils/styles';

type Props = {
  tags: string[] | undefined;
  withGap?: boolean;
};

export default function AITagging({ tags, withGap }: Props) {
  const { data, limit, usage } = useWithCRMPricing('BITCHAT', 'ai_credits');
  const { data: pricingData } = usePricingByApp('BITCHAT');

  const sortedTags = tags?.sort((a, b) => {
    if (a.includes(':') && !b.includes(':')) return -1;
    if (!a.includes(':') && b.includes(':')) return 1;
    return 0;
  });

  const actualUsage = limit - usage;

  return (
    <InfoList
      label="AI tagging (1 token/ticket)"
      withGap={withGap}
      isChildrenNewLine
    >
      {!sortedTags?.length ? (
        '-'
      ) : (
        <TruncatedTags items={sortedTags} withoutDelete maxLines={2} />
      )}
      {pricingData?.useAiCreditLimit && data && (
        <BBBLimitAlert
          usage={data.usage}
          appType="BITCHAT"
          module={data.pricingFeature.label}
          limit={limit}
          currentPlan={{
            label: pricingData.pricingModule.label,
            name: pricingData.pricingName,
          }}
          withoutTip
          className={cn('mt-4 rounded-lg')}
          customTresholdFn={() => 0}
          accent={usage < limit ? 'info' : 'secondary'}
          customLabel={
            usage >= limit ? (
              <>
                <span className="font-bold">
                  Your free AI token has reached its limit.
                </span>
                <br />
                <Link
                  to={`/pricing?tab=bitchat`}
                  className="font-bold underline text-link"
                >
                  Upgrade now
                </Link>{' '}
                to continue using AI tagging or{' '}
                <a className="font-bold underline">learn more</a>.
              </>
            ) : (
              <>
                <span className="font-bold">
                  You only have {actualUsage} free AI tokens left.
                </span>
                <br />
                {!pricingData?.aiCreditAutoCharge ? (
                  <Link
                    to={`/settings?section=plan-management`}
                    className="font-bold underline text-link"
                  >
                    Activate Auto-Charge
                  </Link>
                ) : (
                  <Link
                    to={`/pricing?tab=bitchat`}
                    className="font-bold underline text-link"
                  >
                    Upgrade now
                  </Link>
                )}{' '}
                to avoid running out of AI tokens or{' '}
                <a className="font-bold underline">learn more</a>.
              </>
            )
          }
        />
      )}
    </InfoList>
  );
}
