import * as yup from 'yup';
import { ShopifyCollectionPayload } from '../components/Shopify/CollectionListOptions';
import { ShopifyProductPayload } from '../components/Shopify/ProductListOptions';

import {
  DataFieldOption,
  DataFieldValue,
  RunIfValue,
} from '@/constants/bitCRM/automation-new';
import {
  LoginChannel,
  LoginChannelOption,
} from '@/constants/bitLogin/appearance';
import {
  ChannelIntegration,
  ChannelIntegrationValues,
} from '@/constants/integrations';
import { Tags } from '@/types/customers';

export type Action =
  | 'send_message'
  | 'ask_review'
  | 'add_customer_tag'
  | 'get_customer_information'
  | 'add_loyalty_point'
  | 'subtract_loyalty_point'
  | 'get_loyalty_membership_info';

export type ActionDelay = 'hours' | 'minutes' | 'seconds';

export type ActionData = {
  source: ActionSource | null;
  action: Action | null;
  message: string | null;
  delayCount: number | null;
  delayUnit: ActionDelay | null;
  params: string[] | null;
  templateId: string | null;
  templateName: string | null;
  templateMessage: string | null;
  customerTags: Pick<Tags, 'id' | 'name'>[] | null;
  addedLoyaltyPoints: number | null;
  subtractedLoyaltyPoints: number | null;
};

export type ActionSource =
  | 'WHATSAPP'
  | 'WHATSAPP_META'
  | 'STAMPED'
  | 'YOTPO'
  | 'time_delay'
  | 'INSTAGRAM'
  | 'conditions'
  | 'stamped_loyalty'
  | 'customer';

export type TriggerData = {
  source: TriggerSource | null;
  triggerName: string | null;
  triggerValue: string | null;
  jubelioChannelLabel: string | null;
  jubelioChannelValue: number | null;
  visitedPage: string | null;
  visitedCollection: ShopifyCollectionPayload | null;
  visitedProduct: ShopifyProductPayload | null;
  visitedXCollections: number | null;
  visitedXProducts: number | null;
};

export type TriggerSource =
  | 'shopify'
  | 'jubelio'
  | 'bitlogin'
  | 'stamped_loyalty'
  | 'web_activity';

export const triggerSchema = yup.object({
  source: yup.mixed<TriggerSource | null>().required(),
  triggerName: yup.string().required(),
  triggerValue: yup.string().required(),
  jubelioChannelLabel: yup.string().when('source', {
    is: (value: TriggerSource) => value === 'jubelio',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
  templateName: yup.string().when('source', {
    is: (value: TriggerSource) => value === 'jubelio',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
});

export const actionSchema = yup.object({
  source: yup.mixed<ActionSource>().required(),
  action: yup.mixed<'send_message' | 'ask_review' | null>().when('source', {
    is: (value: ActionSource) => !['time_delay', 'conditions'].includes(value),
    then: (rule) => rule.required(),
  }),
  message: yup.string().when('source', {
    is: (value: ActionSource) => value === 'WHATSAPP' || value === 'INSTAGRAM',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
  delayCount: yup.number().when('source', {
    is: (value: ActionSource) => value === 'time_delay',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
  delayUnit: yup
    .mixed<'hours' | 'minutes' | 'seconds' | null>()
    .when('source', {
      is: (value: ActionSource) => value === 'time_delay',
      then: (rule) => rule.required(),
      otherwise: (rule) => rule.nullable(),
    }),
  params: yup.mixed<string[] | null>(),
  templateId: yup.string().when('source', {
    is: (value: ActionSource) => value === 'WHATSAPP_META',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
  templateName: yup.string().when('source', {
    is: (value: ActionSource) => value === 'WHATSAPP_META',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
  templateMessage: yup.string().when('source', {
    is: (value: ActionSource) => value === 'WHATSAPP_META',
    then: (rule) => rule.required(),
    otherwise: (rule) => rule.nullable(),
  }),
});

export type ConditionData = {
  dataField: DataFieldValue;
  runIf: RunIfValue;
  currency: string | null;
  totalPrice: number | null;
  delay: number | null;
  totalProductVariant: number | null;
  totalQuantity: number | null;
  starsTreshold: number | null;
  visitedPageXTimes: number | null;
  visitedCollectionXTimes: number | null;
  visitedProductXTimes: number | null;
  addXCartItems: number | null;
  signupChannel: LoginChannel | null;
  loginXTimes: number | null;
  loginRange: number | null;
  customerTags: Pick<Tags, 'id' | 'name'>[] | null;
  loyaltyXPoints: number | null;
  loyaltyRewardsEarnMoreThan: number | null;
  loyaltyRewardsEarnLessThan: number | null;
  loyaltyRewardsUsedMoreThan: number | null;
  loyaltyRewardsUsedLessThan: number | null;
};

type DataFieldForm = DataFieldOption | DataFieldValue | null;
type RunIfForm = RunIfValue | { label: string; value: RunIfValue } | null;

export type ConditionForm = {
  dataField: DataFieldForm;
  runIf: RunIfForm;
  currency: { code: string } | null;
  totalPrice: string | null;
  totalProductVariant: string | null;
  totalQuantity: string | null;
  starsTreshold: string | null;
  delay: string | null;
  visitedPageXTimes: string | null;
  visitedCollectionXTimes: string | null;
  visitedProductXTimes: string | null;
  addXCartItems: string | null;
  signupChannel: LoginChannelOption | null;
  loginXTimes: string | null;
  loginRange: string | null;
  customerTags: (Pick<Tags, 'id' | 'name'> | Tags)[] | null;
  loyaltyXPoints: string | null;
  loyaltyRewardsEarnMoreThan: string | null;
  loyaltyRewardsEarnLessThan: string | null;
  loyaltyRewardsUsedMoreThan: string | null;
  loyaltyRewardsUsedLessThan: string | null;
};

export const conditionSchema = yup.object({
  dataField: yup.mixed<DataFieldForm>().label('Data field').required(),
  runIf: yup
    .mixed<RunIfForm>()
    .label('Run if')
    .when('dataField', {
      is: (value: DataFieldForm) => !!value,
      then: (rule) => rule.required(),
    }),
  currency: yup
    .mixed()
    .label('Currency')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return value === 'total_price_less' || value === 'total_price_more';
      },
      then: (rule) => rule.required(),
    }),
  totalPrice: yup
    .string()
    .label('Total price')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return value === 'total_price_less' || value === 'total_price_more';
      },
      then: (rule) => rule.required(),
    }),
  delay: yup
    .string()
    .label('Delay')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return (
          value === 'product_not_purchased' || value === 'review_not_submitted'
        );
      },
      then: (rule) => rule.required(),
    }),
  totalProductVariant: yup
    .string()
    .label('Total product variant')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return (
          value === 'product_variant_less' || value === 'product_variant_more'
        );
      },
      then: (rule) => rule.required(),
    }),
  totalQuantity: yup
    .string()
    .label('Total quantity')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return value === 'quantity_less' || value === 'quantity_more';
      },
      then: (rule) => rule.required(),
    }),
  starsTreshold: yup
    .string()
    .label('Stars treshold')
    .when('runIf', {
      is: (_value: RunIfForm) => {
        const value = getRunIfFormValue(_value);

        return (
          value === 'review_submitted_stars_less' ||
          value === 'review_submitted_stars_more'
        );
      },
      then: (rule) => rule.required(),
    }),
});

function getRunIfFormValue(value: RunIfForm) {
  return value ? (typeof value === 'object' ? value.value : value) : null;
}
