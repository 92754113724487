import { ReactNode, useEffect, useRef, useState } from 'react';
import { ChevronDown, Plus, PlusCircle } from 'react-feather';
import { EditorState } from 'draft-js';
import isEqual from 'lodash-es/isEqual';

import {
  BBBCard,
  BBBRichTextEditor,
  BBBSelect,
  BBBTextInput,
  SingleOnValueChangeParam,
} from '@/components/ui';
import {
  useDynamicVariables,
  useParametersOptions,
} from '@/hooks/bitCRM/template/template';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import { AvailableSources } from '@/hooks/whatsApp/useAvailableSources';
import TrashWithTransition from '@/pages/BitChat/Chatbot/components/TrashWithTransition';
import DynamicVariableLink from '@/pages/BitCRM/Campaign/components/DynamicVariableLink';
import { TemplateBodyParameters } from '@/types/bitCRM/template';
import {
  convertEditorStateToHtml,
  convertHtmlToEditorState,
  emptyEditor,
  insertText,
} from '@/utils/common/rich';
import { cn } from '@/utils/styles';

type Props = {
  editorState?: EditorState;
  onChangeEditorState: (val: EditorState) => void;
  placeholder?: string;
  title?: ReactNode;
  source?: AvailableSources;
  params?: TemplateBodyParameters[];
  onChangeParams?: (
    val: SingleOnValueChangeParam<TemplateBodyParameters>[]
  ) => void;
  onChange?: (val: {
    params: SingleOnValueChangeParam<TemplateBodyParameters>[];
    editorState: EditorState;
  }) => void;
  onlyCustomer?: boolean;
  withoutDynamicVariable?: boolean;
  error?: string;
  type?: 'campaign' | 'automation';
  paramErrors?: string[];
};

export default function _CampaignMessage({
  editorState = emptyEditor,
  onChangeEditorState,
  placeholder,
  title = 'Message',
  source,
  params,
  onChangeParams,
  onlyCustomer,
  withoutDynamicVariable,
  error,
  type,
  paramErrors,
  onChange,
}: Props) {
  const htmlMessage = convertEditorStateToHtml(editorState);

  const ref = useRef<Draft.DraftComponent.Base.DraftEditor | null>(null);

  const { data: parameterOptions } = useParametersOptions(type!, {
    enabled: source === 'whatsapp-cloud' && !!type,
  });

  const handleAppendDynamicVariable = (val: string) => {
    const newState = insertText(editorState, val);
    onChangeEditorState(newState);

    setTimeout(() => {
      ref.current?.focus();
    }, 0);
  };

  const convertedParamsFromEditor =
    convertEditorStateToHtml(editorState)?.match(/{{[0-9]}}/gm);

  const handleAppendParam = () => {
    const textToInsert = `{{${(convertedParamsFromEditor?.length ?? 0) + 1}}}`;

    onChangeEditorState(insertText(editorState, textToInsert));
  };

  const removeParam = (param: string, index: number) => {
    const match = htmlMessage?.match(/{{[0-9]}}/gm);
    if (!match) return;

    // find the params inside the editor and if match then remove it
    const newHtmlMessage = match.reduce((acc, item) => {
      if (item === param) return acc?.replace(item, '');
      return acc;
    }, htmlMessage);

    onChange?.({
      params: params?.filter((_, i) => i !== index) || [],
      editorState: EditorState.moveFocusToEnd(
        convertHtmlToEditorState(newHtmlMessage)
      ),
    });
  };

  useEffect(() => {
    if (source === 'whatsapp-cloud') {
      let counter = -1;
      const replaced = htmlMessage?.replaceAll(/{{[0-9]}}/gm, () => {
        counter += 1;
        return `{{${counter + 1}}}`;
      });

      if (counter >= 0) {
        if (replaced) {
          if (htmlMessage === replaced) {
            // do nothing
          } else {
            onChangeEditorState?.(
              EditorState.moveFocusToEnd(convertHtmlToEditorState(replaced))
            );
          }
        }
      }
    }
  }, [htmlMessage, onChangeEditorState, source]);

  const prevParamsRef = useRef<any[]>();

  useEffect(() => {
    const newParams =
      convertedParamsFromEditor?.map((_, index) => params?.[index]) || [];

    if (!isEqual(newParams, prevParamsRef.current)) {
      onChangeParams?.(newParams);
      prevParamsRef.current = newParams;
    }
  }, [convertedParamsFromEditor, onChangeParams, params]);

  return (
    <>
      <div id="template-body-message">
        <div>{title}</div>
        {!withoutDynamicVariable && source === 'whatsapp-business' && (
          <>
            <div className="flex">
              <div className="text-sm mb-2">
                You can use variable to personalize your message
              </div>
              <div className="ml-auto">
                <SelectVariables
                  onlyCustomer={onlyCustomer}
                  onSelect={handleAppendDynamicVariable}
                />
              </div>
            </div>
          </>
        )}

        <BBBRichTextEditor
          editorState={editorState}
          onChangeEditorState={onChangeEditorState}
          additionalInlineComponent={
            source &&
            (source !== 'whatsapp-business' && !withoutDynamicVariable ? (
              <div className="hover:bg-gray-100 transition-[background] rounded-lg p-1">
                <Plus onClick={handleAppendParam} cursor="pointer" />
              </div>
            ) : undefined)
          }
          placeholder={placeholder}
          error={error}
          ref={ref}
        />
        {source === 'whatsapp-cloud' && (
          <div className="flex">
            <div
              className="flex items-center gap-1 cursor-pointer"
              onClick={() => {
                onChangeEditorState(
                  insertText(editorState, '{{customer.name}}')
                );
              }}
            >
              <PlusCircle size={12} />
              <p className="text-neutral-900 text-sm underline">
                Add dynamic customer name
              </p>
            </div>
          </div>
        )}
        {source === 'whatsapp-cloud' && (
          <div className="flex flex-col gap-2 mb-5">
            {convertedParamsFromEditor?.map((_, index) => {
              return (
                <div key={index} className="flex gap-2 justify-center">
                  <div className="w-fit flex-none cursor-pointer">
                    <BBBTextInput
                      disabled
                      readOnly
                      value={`{{${index + 1}}}`}
                      label="Parameter"
                      containerClassname="w-20 mb-0"
                      className="opacity-100"
                    />
                  </div>
                  <div className="grow flex-1 w-full">
                    <BBBSelect
                      options={parameterOptions}
                      placeholder="Select variable"
                      onValueChange={(val) => {
                        onChangeParams?.(
                          convertedParamsFromEditor?.map(
                            (_param, paramIndex) => {
                              if (paramIndex === index) return val;
                              return params?.[paramIndex];
                            }
                          ) ?? []
                        );
                      }}
                      value={params?.[index]}
                      label="Variable"
                      optionLabel="label"
                      optionValue="value"
                      error={paramErrors?.[index]}
                    />
                  </div>
                  <div className="flex-none">
                    <TrashWithTransition
                      className="mt-4"
                      onClick={() => removeParam(`{{${index + 1}}}`, index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!withoutDynamicVariable && source === 'whatsapp-business' && (
          <div className="flex justify-end">
            <DynamicVariableLink />
          </div>
        )}
      </div>
    </>
  );
}

function SelectVariables({
  onlyCustomer,
  onSelect,
}: {
  onlyCustomer?: boolean;
  onSelect: (val: string) => void;
}) {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const { data: options } = useDynamicVariables(onlyCustomer);

  useOutsideAlerter(ref, () => {
    setShow(false);
  });

  return (
    <div className="relative">
      <div
        className="text-link flex items-center gap-1 cursor-pointer"
        onClick={() => setShow(true)}
      >
        Add variable{' '}
        <span>
          <ChevronDown size={8} />
        </span>
      </div>
      {show && (
        <>
          <div className="absolute right-0 z-10" ref={ref}>
            <BBBCard className="p-0 md:p-0 min-w-[15rem] shadow max-h-60 overflow-auto">
              {options?.map((option, index) => (
                <div
                  className={cn(
                    'px-4 py-2 hover:bg-secondary-surface transition-colors cursor-pointer',
                    index === 0 && 'rounded-tr-2xl rounded-tl-2xl'
                  )}
                  key={option.value}
                  onClick={() => {
                    onSelect(option.value);
                    setShow(false);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </BBBCard>
          </div>
        </>
      )}
    </div>
  );
}
