import { Fragment, useCallback, useEffect, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createId } from '@paralleldrive/cuid2';
import { useQueryClient } from '@tanstack/react-query';
import { socketIntegrations } from 'socket';
import * as yup from 'yup';

import ConnectShopifyButton from '@/components/ConnectShopifyButtons';
import { BBBModal, BBBTextInput } from '@/components/ui';
import { env, isProd } from '@/config/env';
import { integrationsApiBaseUrl } from '@/constants/api';
import useCustomForm from '@/hooks/common/useCustomForm';
import { useUserId } from '@/hooks/rtk/selector';
import { useAppSelector } from '@/hooks/rtk/store';
import useShopifyConnectModal from '@/hooks/shopify/useShopifyConnectModal';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { CommonStore, ShopifyConnectDefaultMode } from '@/stores/common';
import { AppType } from '@/types/systemLogin';
import { toast } from '@/utils/common/toast';

const schema = yup.object({
  companyUrl: yup.string().required('Shopify Company URL is required'),
});

type ShopifyIntegrationModalFormSchema = {
  companyUrl: string;
};

const mapConnectAppDescription: Record<AppType, string> = {
  BITAPP:
    'Integrate to Shopify Connect bitApp to build and customize your mobile app with direct Shopify sync.',
  BITLINK:
    'Integrate to Shopify Connect bitLink to showcase your Shopify collections and elevate your social media presence.',
  BITCHAT:
    'Integrate to Shopify Connect bitChat to manage all customer conversations and Shopify data effortlessly.',
  BITCRM:
    'Integrate to Shopify Connect bitCRM to streamline CRM and customer interactions with seamless Shopify integration.',
  BITLOGIN:
    'Integrate to Shopify Connect bitLogin to enable quick and secure logins with direct Shopify integration.',
  BITAI:
    'Integrate to Shopify Connect bitAI to automate customer interactions and Shopify data management.',
};

const shopifyAppUrl: Record<AppType, string> = {
  BITAPP: 'https://apps.shopify.com/bitapp-mobile-app-builder',
  BITCHAT:
    'https://apps.shopify.com/bitchat-whatsapp-chatgpt-ai-omnichannel-inbox',
  BITLINK: 'https://apps.shopify.com/bitlink-shopify-link-bio-builder',
  BITLOGIN: 'https://apps.shopify.com/bitbybit-google-signin',
  BITCRM: 'https://apps.shopify.com/bitcrm-whatsapp-automation-1',
  BITAI: 'https://apps.shopify.com/bitai-advance-sort-collection',
};

function ShopifyIntegrationModal() {
  const { show } = useShopifyConnectModal();

  if (!show) return null;

  return <_ShopifyIntegrationModal {...show} />;
}

type Props = NonNullable<CommonStore['shopifyConnectModalShow']>;

function _ShopifyIntegrationModal({
  appType,
  defaultMode,
  onSuccess,
  skipSocketDisconnectOnSuccess,
}: Props) {
  const activeCompany = useAppSelector((state) => state.auth.activeCompany);
  const userId = useUserId();

  const client = useQueryClient();

  const { show, setShow } = useShopifyConnectModal();

  const { data } = useShopifyIntegrationByApp(appType);

  const [mode, setMode] = useState<ShopifyConnectDefaultMode>();
  const [showShopifyConnect, setShowShopifyConnect] = useState(false);

  useEffect(() => {
    setMode(defaultMode);
  }, [defaultMode, show]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useCustomForm<ShopifyIntegrationModalFormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      companyUrl: '',
    },
  });

  const onSubmit = (data: ShopifyIntegrationModalFormSchema) =>
    window.open(
      `https://${
        data.companyUrl
      }.myshopify.com/admin/oauth/authorize?client_id=${
        env[`REACT_APP_SHOPIFY_API_KEY_${appType}`]
      }&scope=${
        env[`REACT_APP_SHOPIFY_${appType}_SCOPES`]
      }&redirect_uri=${encodeURIComponent(
        `${integrationsApiBaseUrl}/shopify/${appType}/auth/connect/bbb`
      )}&state=${encodeURIComponent(
        `code=${createId()}&userId=${userId}&companyId=${activeCompany}`
      )}`,
      'mywindow',
      'menubar=1,resizable=1,width=1200,height=700'
    );

  const [success, setSuccess] = useState(false);

  const onIntegrationAuthorized = useCallback(() => {
    toast.success('Succesfully connected to shopify');
    setMode('pending-success');
    setTimeout(() => {
      Promise.all([
        client.invalidateQueries([
          `${appType.toLowerCase()}-company-integrations`,
          activeCompany,
        ]),
        client.invalidateQueries([
          `${appType.toLowerCase()}-shopify`,
          activeCompany,
        ]),
        client.invalidateQueries(['pricing']),
      ]).then(() => {
        setSuccess(true);
      });
    }, 1000);
  }, [activeCompany, appType, client]);

  useEffect(() => {
    if (success && data?.domain) {
      onSuccess?.(data);
      setSuccess(false);
      setShow(null);
    }
  }, [data, onSuccess, setShow, success]);

  const storeUrl = watch('companyUrl');

  useEffect(() => {
    const regex = /^(?:https:\/\/)?([^.]+)(?:\.myshopify\.com)?.*/;
    const match = storeUrl.match(regex);

    if (match) {
      const storeName = match[1];
      setValue('companyUrl', storeName);
    }
  }, [setValue, storeUrl]);

  useEffect(() => {
    socketIntegrations.emit('join', {
      companyId: activeCompany,
      userId: userId,
      appType: appType,
    });
  }, [activeCompany, appType, userId]);

  useEffect(() => {
    socketIntegrations.on(
      `${appType.toLowerCase()}-integration-authorized`,
      onIntegrationAuthorized
    );
    return () => {
      socketIntegrations.off(
        `${appType.toLowerCase()}-integration-authorized`,
        onIntegrationAuthorized
      );
    };
  }, [appType, onIntegrationAuthorized]);

  const appUrl =
    shopifyAppUrl[appType] +
    '?utm_source=bitbybit&utm_medium=button_connect_to_shopify&utm_campaign=bitLogin';

  return mode === undefined ? (
    <BBBModal
      show={mode === undefined}
      onHide={() => {
        setShow(null);
      }}
      footer
      submitText={
        isProd ? (
          <a href={appUrl} target="_blank" rel="noreferrer">
            <ConnectShopifyButton appType={appType} onClick={() => ({})} />
          </a>
        ) : (
          <ConnectShopifyButton
            appType={appType}
            onClick={() => {
              setMode('ask-permission');
            }}
          />
        )
      }
      overrideSubmitBtn
      title="Integrate to Shopify"
      size="md"
      centerFooter
      centerTitle
      centerBody
    >
      {mapConnectAppDescription[appType]}
    </BBBModal>
  ) : mode === 'ask-permission' ? (
    <BBBModal
      show={mode === 'ask-permission'}
      onHide={() => {
        setShow(null);
      }}
      handleSave={() => {
        if (!isProd) {
          setMode('input-store');
        }
      }}
      submitHref={isProd && appUrl}
      footer
      size="3xl"
      cancelText={`I don't have Shopify account`}
      cancelHref="http://shopify.pxf.io/bitbybit"
      submitText="Connect to Shopify account"
    >
      <div className="flex justify-center flex-col">
        <div className="flex gap-2 mb-5">
          <div className="border border-slate-200 rounded-lg px-2 py-2">
            <img
              alt=""
              src={`${env.REACT_APP_CDN_URL}/bitbybit/static/v1/shopify.png`}
              className="w-[72px] h-[72px] object-contain"
            />
          </div>
          <div>
            <div className="flex items-center mb-2">
              <div className="text-2xl text-neutral-60">Shopify</div>
              <FaExternalLinkAlt className="ml-3" />
            </div>
            <div className="text-neutral-40">
              No.1 eCommerce Platform for All Businesses
            </div>
          </div>
        </div>
        <div className="mb-3 text-neutral-70">
          In this integration, Shopify will share:
        </div>
        <div className="ml-4">
          <div className="mb-4">1. Product and collection informations</div>
          <div className="mb-4">2. Order details</div>
          <div>3. Your Shopify store name</div>
        </div>
      </div>
    </BBBModal>
  ) : mode === 'input-store' ? (
    <>
      <BBBModal
        title="Login to your Shopify Store"
        submitText="Login"
        show={mode === 'input-store'}
        handleSave={handleSubmit(onSubmit)}
        onHide={() => {
          setShow(null);
        }}
        footer
      >
        <BBBTextInput
          placeholder="myshopifyurl"
          isHookForm
          isFixed
          fixedLabel="https://"
          fixedSuffixLabel=".myshopify.com"
          containerClassname="mb-0"
          isFixedSuffix
          control={control}
          controlName="companyUrl"
          error={errors.companyUrl?.message}
        />
        <div className="flex justify-end underline">
          <div
            className="cursor-pointer"
            onClick={() => setShowShopifyConnect(!showShopifyConnect)}
          >
            Where can i find this?
          </div>
        </div>
      </BBBModal>
      <Transition appear show={showShopifyConnect} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[110]"
          onClose={() => setShowShopifyConnect(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-100">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <h1 className="text-[20px] font-medium text-primary-main">
                    Finding your .my.shopify.com URL
                  </h1>
                  <p className="text-sm text-primary-main mt-4">
                    Your URL will be in the format
                    [your-shop-name].myshopify.com. You can find it by logging
                    into your Shopify admin, located in the URL/address bar of
                    your browser.
                  </p>
                  <img
                    src="https://assets.production.linktr.ee/8d8d3d513b199383c3a86eb2aacde512e9324ee1/images/shopify-integration-tooltip.png"
                    className="object-cover w-full mt-4"
                    alt="shopify-image"
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  ) : null;
}

export default ShopifyIntegrationModal;
