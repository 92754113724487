/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  isValidElement,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChevronDown, PlusCircle } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createId } from '@paralleldrive/cuid2';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import SearchInput from '../SearchInput';
import BBBSelectDropdownOption from './BBBSelectDropdownOption/BBBSelectDropdownOption';
import BBBSelectDropdownOptionMobile from './BBBSelectDropdownOptionMobile/BBBSelectDropdownOptionMobile';
import BBBSelectOptionIcon from './BBBSelectOptionIcon/BBBSelectOptionIcon';
import {
  DropdownMultiTypes,
  DropdownTypes,
  MultipleOnValueChangeCallback,
  MultipleOnValueChangeParam,
  SingleOnValueChangeCallback,
  SingleOnValueChangeParam,
} from './BBBSelect.type';

import SearchIcon from '@/assets/icons/SearchIcon';
import BBBSpinner from '@/components/ui/BBBSpinner/BBBSpinner';
import { BBBTag } from '@/components/ui/BBBTag';
import useOutsideAlerter from '@/hooks/common/useOutsideAlerterv2';
import useResponsive from '@/hooks/common/useResponsive';
import getDescendantProp from '@/types/utils/getDescendantProp';
import { Nullable } from '@/types/utils/nullable';
import { arrowFn } from '@/utils/bitChat';

const BBBSelect = <
  T extends {
    [k: string]: any;
  }
>(
  props: DropdownTypes<T>,
  ref: Ref<HTMLDivElement>
) => {
  const {
    options: optionsFromProps,
    optionLabel,
    optionValue,
    optionIcon,
    optionGroupKey,
    parentOptions,
    selectedLabel = optionLabel,
    noOptionMessage,
    dropdownPosition = 'auto',
    dropdownOptionsX,
    containerClassName,
    dropdownClassName,
    dropdownOptionsWrapperClassName,
    dropdownOptionClassName,
    selectedDropdownClassName,
    loading = false,
    isSearchable,
    search: searchFromProps,
    isMenuOpen: isMenuOpenFromProps,
    closeMenuOnSelect,
    isClearable,
    isPaginated,
    hasMore,
    fetchNext,
    onChangeSearch,
    onChangeMenuOpen,
    isMulti,
    isDisabled,
    isGrouped,
    label,
    onValueChange,
    value: valueFromProps,
    error,
    placeholder = isSearchable ? (
      <div className="flex items-center gap-3">
        <SearchIcon />
        <div className="text-neutral-30">Search</div>
      </div>
    ) : (
      'Choose option'
    ),
    menuContainerClassName,
    activeInputClassName,
    activeLabelClassName,
    isCreatable,
    onCreateOption,
    renderCustomSelectedValues,
    withoutChevronIcon,
    fallbackToPlaceholder,
    enableToggleOption,
    forceCreatable,
    strictCheckLabel,
    optionDisabled,
    renderSelectedValues,
    labelClassName,
    withCreateRedirectOption,
    createRedirectLabel,
    onClickCreateRedirect,
    persistShowOptionsOnClick,
    showChevronOnHover,
    placeholderClearOption = 'Clear tag filter',
    searchOnOptions,
    withSearchIcon,
    ...otherProps
  } = props;
  const isMobile = useResponsive('sm');

  const options =
    typeof optionsFromProps === 'function'
      ? optionsFromProps()
      : optionsFromProps;

  // Define whether state is controlled or not
  const isValueControlled = typeof valueFromProps !== 'undefined';
  const isMenuOpenControlled = typeof isMenuOpenFromProps !== 'undefined';
  const isSearchControlled = typeof searchFromProps !== 'undefined';

  // Internal state declaration
  const [expanded, setExpanded] = useState(false);
  const [search, setLocalSearch] = useState(searchFromProps || '');
  const [searchDebounced] = useDebounce(search, 500);

  const debouncedSearch = useDebouncedCallback((value) => {
    onChangeSearch?.(value);
  }, 500);

  const [localValue, setLocalValue] =
    useState<DropdownMultiTypes<T>['value']>();
  const [localOptions, setLocalOptions] = useState<Nullable<T[]>>();

  const [focused, setFocused] = useState(false);

  // Source of truth for component lifecycle
  const value = isValueControlled ? valueFromProps : localValue;
  const isMenuOpen = isMenuOpenControlled ? isMenuOpenFromProps : expanded;

  const filteredValues = Array.isArray(value)
    ? value?.filter((opt) =>
        !optionGroupKey || !parentOptions
          ? true
          : getDescendantProp(opt, optionGroupKey) ===
            getDescendantProp(parentOptions, optionValue)
      )
    : !parentOptions
    ? value
    : value &&
      optionGroupKey &&
      getDescendantProp(value, optionGroupKey) ===
        getDescendantProp(parentOptions, optionValue)
    ? value
    : undefined;

  const id = createId();

  useEffect(() => {
    if (!isSearchControlled) {
      setLocalOptions(
        options?.filter((o) =>
          !searchDebounced
            ? true
            : o[optionLabel]
                .toLowerCase()
                .includes(searchDebounced.toLowerCase())
        )
      );
    } else {
      setLocalOptions(options);
    }
  }, [isSearchControlled, optionLabel, options, searchDebounced]);

  const onOutsideClick = useCallback(() => {
    onChangeMenuOpen?.(false);
    if (!isMenuOpenControlled) {
      setExpanded(false);
    }
    setHovering(false);
    resizeSelectedLabel();
    setLocalSearch('');
    debouncedSearch('');
  }, [debouncedSearch, isMenuOpenControlled, onChangeMenuOpen]);

  const handleSelectOption = useCallback(
    (option: T, _parentOptions?: T) => {
      const __parentOptions = _parentOptions || parentOptions;

      if (isMulti) {
        const typedValue = (value as MultipleOnValueChangeParam<T>) || [];
        const newValue = !enableToggleOption
          ? [...typedValue, option]
          : typedValue.some(
              (opt) =>
                opt[optionValue] === option[optionValue] &&
                (isGrouped && __parentOptions
                  ? getDescendantProp(opt, optionGroupKey) ===
                    getDescendantProp(__parentOptions, optionValue)
                  : true)
            )
          ? typedValue.filter(
              (opt) =>
                !(
                  opt[optionValue] === option[optionValue] &&
                  (isGrouped && __parentOptions
                    ? getDescendantProp(opt, optionGroupKey) ===
                      getDescendantProp(__parentOptions, optionValue)
                    : true)
                )
            )
          : [...typedValue, option];
        if (!isValueControlled) {
          setLocalValue(newValue);
        }
        (onValueChange as MultipleOnValueChangeCallback<T>)?.(newValue, option);
        onChangeMenuOpen?.(
          closeMenuOnSelect ? !closeMenuOnSelect?.(newValue) : false
        );
      } else {
        const newValue = !enableToggleOption
          ? option
          : option[optionValue] ===
            (value as SingleOnValueChangeParam<T>)?.[optionValue]
          ? null
          : option;
        if (!isValueControlled) {
          setLocalValue(newValue);
        }
        (onValueChange as SingleOnValueChangeCallback<T>)?.(newValue);
        onChangeMenuOpen?.(
          closeMenuOnSelect ? !closeMenuOnSelect?.(option) : false
        );
      }
      setLocalSearch('');
      debouncedSearch('');
      if (!persistShowOptionsOnClick && !isMenuOpenControlled) {
        setExpanded(false);
      }

      setHovering(false);
      resizeSelectedLabel();
    },
    [
      closeMenuOnSelect,
      debouncedSearch,
      enableToggleOption,
      isGrouped,
      isMenuOpenControlled,
      isMulti,
      isValueControlled,
      onChangeMenuOpen,
      onValueChange,
      optionGroupKey,
      optionValue,
      parentOptions,
      persistShowOptionsOnClick,
      value,
    ]
  );

  const handleDeleteSingleActive = useCallback(
    (opt: T, e: React.MouseEvent<SVGElement, MouseEvent>) => {
      const filteredOptions = (value as T[])?.filter(
        (v) =>
          !(
            getDescendantProp(v, optionValue) ===
              getDescendantProp(opt, optionValue) &&
            (isGrouped
              ? getDescendantProp(v, optionGroupKey) ===
                getDescendantProp(opt, optionGroupKey)
              : true)
          )
      );
      (onValueChange as MultipleOnValueChangeCallback<T>)?.(
        filteredOptions,
        opt,
        true
      );
      if (!isValueControlled) {
        setLocalValue(filteredOptions);
      }
      e.stopPropagation();
    },
    [
      value,
      onValueChange,
      isValueControlled,
      optionValue,
      isGrouped,
      optionGroupKey,
    ]
  );

  const handleClearActive = useCallback(() => {
    (onValueChange as SingleOnValueChangeCallback<T>)?.(null);
    if (!isValueControlled) {
      setLocalValue(null);
    }
    if (!isMenuOpenControlled) {
      setExpanded(false);
    }
    onChangeMenuOpen?.(false);
  }, [
    onValueChange,
    isValueControlled,
    isMenuOpenControlled,
    onChangeMenuOpen,
  ]);

  const handleChangeSearchableInput = useCallback(
    (value: string) => {
      setLocalSearch(value);
      debouncedSearch(value);
      if (!isMenuOpenControlled) {
        setExpanded(true);
      }
      onChangeMenuOpen?.(true);
    },
    [debouncedSearch, isMenuOpenControlled, onChangeMenuOpen]
  );

  const handleBlurSearch = useCallback(
    //@ts-ignore
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (isMobile) {
        return;
      }

      if (
        e.relatedTarget?.id === 'bbb-single-option' ||
        e.relatedTarget?.id === 'no-option' ||
        e.relatedTarget?.id === 'create-option-prompt'
      ) {
        return;
      }

      setLocalSearch('');
      debouncedSearch('');

      if (!isMenuOpenControlled) {
        setExpanded(false);
      }
      onChangeMenuOpen?.(false);
    },
    [isMobile, debouncedSearch, isMenuOpenControlled, onChangeMenuOpen]
  );

  const handleToggleMenuShown = () => {
    if (!isMenuOpenControlled) {
      setExpanded((prev) => !prev);
    }
    onChangeMenuOpen?.(!isMenuOpen);
  };

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  const labelCheckStrictness = !strictCheckLabel
    ? true
    : !localOptions?.some((data) => data[optionLabel] === search);

  const onClickCreate = useCallback(async () => {
    if (
      isCreatable &&
      (!localOptions?.length || forceCreatable) &&
      search.length &&
      labelCheckStrictness
    ) {
      if (typeof onCreateOption !== 'undefined') {
        if (onCreateOption(search) instanceof Promise) {
          try {
            await onCreateOption(search);
            setLocalSearch('');
            debouncedSearch('');
          } catch (err) {
            // omit
          }
        } else {
          onCreateOption(search);
          setLocalSearch('');
          debouncedSearch('');
        }
      }
      if (!isMenuOpenControlled) {
        setExpanded(false);
      }
      onChangeMenuOpen?.(false);
    }
  }, [
    debouncedSearch,
    forceCreatable,
    isCreatable,
    isMenuOpenControlled,
    labelCheckStrictness,
    localOptions?.length,
    onChangeMenuOpen,
    onCreateOption,
    search,
  ]);

  const selectedOptionPosition = useMemo(
    () =>
      localOptions?.findIndex(
        (val) =>
          val[optionValue] ===
          (!isMulti
            ? (value as SingleOnValueChangeParam<T>)?.[optionValue]
            : (value as MultipleOnValueChangeParam<T>)?.[0]?.[optionValue])
      ),
    [isMulti, localOptions, optionValue, value]
  );

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const selectedOptionRef = useRef<HTMLDivElement>(null);

  const [hovering, setHovering] = useState(false);
  const [computedWidth, setComputedWidth] = useState<number>();

  const contentRef = useRef<HTMLDivElement | null>(null);

  function resizeSelectedLabel() {
    setTimeout(() => {
      if (contentRef.current) {
        //@ts-ignore
        setComputedWidth(contentRef.current.childNodes[0].clientWidth + 32);
      }
    }, 0);
  }

  return (
    <div
      className={cx(containerClassName)}
      ref={(_containerRef) => setContainerRef(_containerRef)}
    >
      <div className={labelClassName}>{label}</div>
      <div
        ref={ref}
        className={cx(
          isDisabled && 'pointer-events-none opacity-50',
          dropdownClassName
        )}
        onKeyDown={(e) => {
          if (isMenuOpen) {
            if (e.key === 'ArrowDown') {
              arrowFn('down', e);
            }
            if (e.key === 'ArrowUp') {
              arrowFn('up', e);
            }
            if (e.key === 'Escape') {
              if (!isMenuOpenControlled) {
                setExpanded(false);
              }
              onChangeMenuOpen?.(false);
            }
          }
        }}
        tabIndex={0}
        {...otherProps}
      >
        {renderSelectedValues?.({ toggleDropdown: handleToggleMenuShown }) || (
          <motion.div
            onClick={(e) => {
              handleToggleMenuShown();
            }}
            className={cx(
              'flex relative items-center gap-1 px-4 py-2 transition rounded-lg bg-white border select-none overflow-hidden',
              isMenuOpenControlled && !isMenuOpen
                ? ''
                : isMenuOpen || focused
                ? 'border-secondary-main shadow-[0px_0px_0px_2.5px_#FED5BF]'
                : hovering
                ? 'border-neutral-40'
                : 'border-neutral-30',
              selectedDropdownClassName
            )}
            id={`selected-option-${id}`}
            onFocus={handleFocus}
            role="button"
            onBlur={handleBlur}
            ref={selectedOptionRef}
            onMouseEnter={() => {
              if (showChevronOnHover) {
                setHovering(true);
                if (!hovering) {
                  const currentRef = document.getElementById(
                    `selected-option-${id}`
                  );
                  if (currentRef) {
                    setComputedWidth(currentRef.clientWidth + 32);
                  }
                }
              } else {
                setHovering(true);
              }
            }}
            onMouseLeave={(e) => {
              if (showChevronOnHover) {
                if (!isMenuOpen) {
                  setHovering(false);

                  resizeSelectedLabel();
                }
              } else {
                setHovering(false);
              }
            }}
            tabIndex={0}
            animate={
              showChevronOnHover
                ? { width: computedWidth, transition: { type: 'tween' } }
                : undefined
            }
          >
            <div
              className={cx(
                'relative flex items-center gap-2 grow',
                isMulti && 'flex-wrap',
                activeInputClassName
              )}
              ref={contentRef}
            >
              <SelectedValues
                onDeleteTag={handleDeleteSingleActive}
                optionValue={optionValue}
                optionLabel={optionLabel}
                optionIcon={optionIcon}
                optionGroupKey={optionGroupKey}
                filteredValues={filteredValues}
                value={value}
                fallbackToPlaceholder={fallbackToPlaceholder}
                isMulti={isMulti}
                search={search}
                renderCustomSelectedValues={renderCustomSelectedValues}
                activeLabelClassName={activeLabelClassName}
                parentOptions={parentOptions}
                selectedLabel={selectedLabel}
                isGrouped={isGrouped}
              />

              <Placeholder
                fallbackToPlaceholder={fallbackToPlaceholder}
                isMulti={isMulti}
                search={search}
                value={filteredValues}
                isSearchable={isSearchable}
                isCreatable={isCreatable}
                focused={focused}
                placeholder={placeholder}
                isMenuOpen={isMenuOpen}
                onBlur={handleBlurSearch}
                onValueChange={handleChangeSearchableInput}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickCreate();
                  }
                  if (
                    !fallbackToPlaceholder &&
                    e.key === 'Backspace' &&
                    isMulti &&
                    (isSearchable || isCreatable) &&
                    !search
                  ) {
                    const _value = value as T[] | undefined;

                    if (_value?.length) {
                      const filteredOptions = _value.filter(
                        (v, index) => index < _value.length - 1
                      );

                      onValueChange?.(filteredOptions);
                      if (!isValueControlled) {
                        setLocalValue(filteredOptions);
                      }
                    }
                  }
                }}
                searchOnOptions={searchOnOptions}
                withSearchIcon={withSearchIcon}
              />
            </div>
            {!withoutChevronIcon && (
              <motion.div
                className={cx(
                  showChevronOnHover
                    ? 'absolute right-4'
                    : 'flex gap-2 items-center flex-none'
                )}
                animate={
                  showChevronOnHover
                    ? {
                        x: hovering ? 0 : 32,
                        opacity: hovering ? 1 : 0,
                      }
                    : undefined
                }
                initial={{
                  x: showChevronOnHover ? 32 : 0,
                  opacity: showChevronOnHover ? 0 : 1,
                }}
                transition={{ type: 'tween', duration: 0.3 }}
              >
                <div
                  className={cx(
                    'transition-transform duration-150',
                    isMenuOpen && 'rotate-180'
                  )}
                >
                  <ChevronDown size={16} />
                </div>
              </motion.div>
            )}
          </motion.div>
        )}
        <OptionSheets
          {...props}
          _options={localOptions}
          onClickCreate={onClickCreate}
          onOutsideClick={onOutsideClick}
          containerRef={containerRef}
          selectedOptionRef={selectedOptionRef.current}
          isMenuOpen={isMenuOpen}
          handleSelectOption={handleSelectOption}
          id={id}
          _search={search}
          labelCheckStrictness={labelCheckStrictness}
          selectedOptionPosition={selectedOptionPosition}
          onBlur={handleBlurSearch}
          onChangeSearchDropdown={handleChangeSearchableInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClickCreate();
            }
            if (
              !fallbackToPlaceholder &&
              e.key === 'Backspace' &&
              isMulti &&
              (isSearchable || isCreatable) &&
              !search
            ) {
              const _value = value as T[] | undefined;

              if (_value?.length) {
                const filteredOptions = _value.filter(
                  (v, index) => index < _value.length - 1
                );

                onValueChange?.(filteredOptions);
                if (!isValueControlled) {
                  setLocalValue(filteredOptions);
                }
              }
            }
          }}
          onChangeClearSelected={handleClearActive}
          placeholderClearOption={placeholderClearOption}
        />
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export function SelectedValues<T extends Record<string, any>>({
  onDeleteTag,
  value,
  isMulti,
  fallbackToPlaceholder,
  search,
  parentOptions,
  optionValue,
  optionIcon,
  optionGroupKey,
  renderCustomSelectedValues,
  activeLabelClassName,
  optionLabel,
  selectedLabel = optionLabel,
  filteredValues,
  isGrouped,
}: {
  onDeleteTag: (opt: T, e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  filteredValues: DropdownTypes<T>['value'];
} & Pick<
  DropdownTypes<T>,
  | 'fallbackToPlaceholder'
  | 'isMulti'
  | 'search'
  | 'value'
  | 'parentOptions'
  | 'optionValue'
  | 'renderCustomSelectedValues'
  | 'optionGroupKey'
  | 'optionIcon'
  | 'activeLabelClassName'
  | 'selectedLabel'
  | 'optionLabel'
  | 'isGrouped'
>) {
  // If dropdown is in multi mode, the options is always there regardless of searching status
  const isSelectedOptionsShown = useMemo(
    () =>
      fallbackToPlaceholder
        ? false
        : (isMulti ? true : !search) &&
          (!isMulti ? !!filteredValues : !!filteredValues?.length),
    [fallbackToPlaceholder, isMulti, search, filteredValues]
  );

  return (
    <AnimatePresence mode="popLayout">
      {isSelectedOptionsShown &&
        (renderCustomSelectedValues?.(
          filteredValues as (T[] & T) | null | undefined
        ) ||
          (isMulti ? (
            (value as MultipleOnValueChangeParam<T>)
              ?.filter((opt) =>
                !optionGroupKey || !parentOptions
                  ? true
                  : getDescendantProp(opt, optionGroupKey) ===
                    getDescendantProp(parentOptions, optionValue)
              )
              .map((opt) => {
                const _value = getDescendantProp(opt, optionValue);
                return (
                  <motion.div
                    key={
                      isGrouped && optionGroupKey
                        ? `${getDescendantProp(opt, optionGroupKey)}_${_value}`
                        : _value
                    }
                    layout
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.3, type: 'tween' }}
                  >
                    <BBBTag
                      icon={
                        <BBBSelectOptionIcon value={opt} accesor={optionIcon} />
                      }
                      text={getDescendantProp(opt, selectedLabel)}
                      textClassName={activeLabelClassName}
                      onDelete={(e) => onDeleteTag(opt, e)}
                      className="bg-neutral-20"
                    />
                  </motion.div>
                );
              })
          ) : (
            <>
              <BBBSelectOptionIcon
                value={value as NonNullable<SingleOnValueChangeParam<T>>}
                accesor={optionIcon}
              />
              <span className={cx('line-clamp-1', activeLabelClassName)}>
                {getDescendantProp(
                  value as NonNullable<SingleOnValueChangeParam<T>>,
                  selectedLabel
                )}
              </span>
            </>
          )))}
    </AnimatePresence>
  );
}

export function Placeholder<T extends Record<string, any>>({
  onKeyDown,
  onValueChange,
  onBlur,
  search,
  fallbackToPlaceholder,
  isMulti,
  isSearchable,
  isCreatable,
  placeholder,
  isMenuOpen,
  focused,
  value: filteredValues,
  searchOnOptions,
  withSearchIcon,
}: {
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onValueChange: (search: string) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  focused: boolean;
} & Pick<
  DropdownTypes<T>,
  | 'search'
  | 'fallbackToPlaceholder'
  | 'isMulti'
  | 'isSearchable'
  | 'isCreatable'
  | 'placeholder'
  | 'isMenuOpen'
  | 'value'
  | 'searchOnOptions'
  | 'withSearchIcon'
>) {
  // The placeholder will show if there is no search value and no selected options available
  const isPlaceholderShown = useMemo(
    () =>
      !search &&
      (fallbackToPlaceholder
        ? true
        : isMulti
        ? !filteredValues?.length
        : !filteredValues),
    [fallbackToPlaceholder, search, isMulti, filteredValues]
  );

  const searchInputClassNames = cx(
    !search &&
      (fallbackToPlaceholder
        ? true
        : !isMulti
        ? true
        : !filteredValues?.length) &&
      cx(
        `absolute inset-0 bg-white z-10`,
        isMenuOpen || focused
          ? 'caret-current'
          : 'caret-transparent cursor-pointer'
      ),
    'bg-transparent p-0 outline-0 w-full'
  );

  if (!(isSearchable || isCreatable || isPlaceholderShown)) return null;

  return (
    <div className="grow">
      {(isSearchable || isCreatable) && !searchOnOptions && (
        <input
          type="text"
          className={searchInputClassNames}
          onBlur={onBlur}
          value={search}
          onChange={({ target: { value } }) => onValueChange(value)}
          onKeyDown={onKeyDown}
        />
      )}
      {isPlaceholderShown &&
        (isValidElement(placeholder) ? (
          placeholder
        ) : (
          <div className="text-gray-400 flex items-center gap-2.5">
            {!isMenuOpen && withSearchIcon && (
              <div>
                <SearchIcon />
              </div>
            )}
            <span className="line-clamp-1">{placeholder}</span>
          </div>
        ))}
    </div>
  );
}

export function OptionSheets<T extends Record<string, any>>(
  props: DropdownTypes<T> & {
    _options: DropdownTypes<T>['options'];
    onClickCreate?: () => void;
    onOutsideClick?: () => void;
    containerRef?: HTMLDivElement | null;
    selectedOptionRef?: HTMLDivElement | null;
    handleSelectOption: (opt: T) => void;
    _search: string | undefined;
    labelCheckStrictness?: boolean;
    selectedOptionPosition?: number;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChangeSearchDropdown: (search: string) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChangeClearSelected?: () => void;
    placeholderClearOption?: string;
  }
) {
  const {
    isMenuOpen,
    dropdownPosition = 'auto',
    id,
    menuContainerClassName,
    dropdownOptionsWrapperClassName,
    dropdownOptionsX = 'left',
    loading,
    isCreatable,
    isPaginated,
    fetchNext,
    isFetchingNextPage,
    hasMore,
    noOptionMessage = (
      <div className="px-4 py-2 whitespace-nowrap">No options</div>
    ),
    isGrouped,
    optionGroupKey,
    optionValue,
    parentOptions,
    forceCreatable,
    labelCheckStrictness,
    selectedOptionPosition = 0,
    _search,
    _options,
    selectedOptionRef,
    onClickCreate,
    containerRef,
    onOutsideClick,
    handleSelectOption,
    isMulti,
    onBlur,
    onKeyDown,
    onChangeSearchDropdown,
    withClearableOption,
    onChangeClearSelected,
    placeholderClearOption,
    searchOnOptions,
  } = props;

  const options = _options as Nullable<T[]> | undefined;
  const search = _search;

  const isMobile = useResponsive('sm');

  const dropdownOptionsRef = useRef<HTMLDivElement>(null);

  const filteredDropdownOptions = options?.filter((opt) =>
    !isGrouped || !optionGroupKey
      ? true
      : !parentOptions
      ? !getDescendantProp(opt, optionGroupKey)
      : getDescendantProp(opt, optionGroupKey) ===
        getDescendantProp(parentOptions, optionValue)
  );

  useOutsideAlerter(dropdownOptionsRef, (target) => {
    if (
      !isMobile &&
      (target as HTMLElement).closest(`#selected-option-${id}`)?.id !==
        `selected-option-${id}`
    ) {
      onOutsideClick?.();
    }
  });

  const [autoConfiguredPosition, setAutoConfiguredPosition] = useState<
    'top' | 'bottom' | undefined
  >(dropdownPosition === 'auto' ? undefined : dropdownPosition);

  useEffect(() => {
    if (isMenuOpen && dropdownPosition === 'auto') {
      const rect = containerRef?.getBoundingClientRect();
      if (
        (rect?.bottom ?? 0) + (dropdownOptionsRef.current?.clientHeight ?? 0) >
        window.innerHeight
      ) {
        setAutoConfiguredPosition('top');
      } else {
        setAutoConfiguredPosition('bottom');
      }
    }
  }, [containerRef, dropdownPosition, isMenuOpen]);

  useEffect(() => {
    if (!isMulti && isMenuOpen && selectedOptionRef) {
      dropdownOptionsRef.current?.scrollTo({
        top: Math.round(
          selectedOptionRef.getBoundingClientRect().height *
            selectedOptionPosition -
            dropdownOptionsRef.current?.getBoundingClientRect().height / 2 -
            dropdownOptionsRef.current.getBoundingClientRect().height / 2
        ),
        behavior: 'auto',
      });
    }
  }, [isMenuOpen, isMulti, selectedOptionPosition, selectedOptionRef]);

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          initial={{
            y: autoConfiguredPosition
              ? autoConfiguredPosition === 'bottom'
                ? -32
                : 32
              : undefined,
            opacity: 0,
            pointerEvents: 'none',
          }}
          animate={{ y: 0, opacity: 1, pointerEvents: 'auto' }}
          exit={{
            y: autoConfiguredPosition
              ? autoConfiguredPosition === 'bottom'
                ? -32
                : 32
              : undefined,
            opacity: 0,
            pointerEvents: 'none',
          }}
          tabIndex={0}
          transition={{ type: 'tween', duration: 0.15 }}
          className={cx('relative z-40', menuContainerClassName)}
          id={`options-sheets`}
        >
          {isMobile ? (
            <BBBSelectDropdownOptionMobile
              {...props}
              data={options}
              isMenuOpen={isMenuOpen}
              onClose={onOutsideClick}
            >
              <DropdownOptions
                {...props}
                _options={filteredDropdownOptions}
                handleSelectOption={handleSelectOption}
              />
            </BBBSelectDropdownOptionMobile>
          ) : (
            <div
              className={cx(
                `absolute mt-1 z-30 rounded-lg border-neutral-30 border bg-white max-h-[200px] overflow-y-auto min-w-full`,
                dropdownOptionsWrapperClassName,
                dropdownOptionsX === 'left' ? 'left-0 ' : 'right-0'
              )}
              id="scrollable-options"
              ref={dropdownOptionsRef}
              style={
                autoConfiguredPosition
                  ? {
                      [autoConfiguredPosition === 'bottom' ? 'top' : 'bottom']:
                        autoConfiguredPosition === 'bottom'
                          ? 0
                          : (selectedOptionRef?.clientHeight ?? 0) + 4,
                    }
                  : undefined
              }
              tabIndex={0}
            >
              {loading && !searchOnOptions ? (
                <BBBSpinner width={3} height={16} />
              ) : isPaginated ? (
                <>
                  <InfiniteScroll
                    dataLength={options?.length ?? 0}
                    next={fetchNext}
                    hasMore={!!hasMore}
                    loader={
                      isFetchingNextPage && <BBBSpinner width={3} height={16} />
                    }
                    scrollableTarget="scrollable-options"
                  >
                    <DropdownOptions
                      {...props}
                      _options={filteredDropdownOptions}
                      handleSelectOption={handleSelectOption}
                      onClickCreate={onClickCreate}
                      searchLabel={search}
                      labelCheckStrictness={labelCheckStrictness}
                    />
                  </InfiniteScroll>
                </>
              ) : (
                <>
                  <DropdownOptions
                    {...props}
                    _options={filteredDropdownOptions}
                    handleSelectOption={handleSelectOption}
                    onClickCreate={onClickCreate}
                    searchLabel={search}
                    labelCheckStrictness={labelCheckStrictness}
                  />
                </>
              )}
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function DropdownOptions<T extends Record<string, any>>(
  props: DropdownTypes<T> & {
    handleSelectOption: (opt: T) => void;
    _options: DropdownTypes<T>['options'];
    labelCheckStrictness?: boolean;
    onClickCreate?: () => void;
    searchLabel?: string;
    onChangeClearSelected?: () => void;
  }
) {
  const {
    optionValue,
    enableToggleOption,
    handleSelectOption,
    _options,
    withCreateRedirectOption,
    onClickCreateRedirect,
    createRedirectLabel = 'Create new option',
    options,
    persistCreateRedirectOption,
    noOptionMessage = (
      <div className="px-4 py-2 whitespace-nowrap text-neutral-40 text-center">
        No options found
      </div>
    ),
    isGrouped,
    optionGroupKey,
    isCreatable,
    forceCreatable,
    labelCheckStrictness,
    searchLabel: search,
    onClickCreate,
    searchOnOptions,
    search: searchValue,
    onChangeSearch,
    isSearchable,
    searchPlaceholder,
    withClearableOption,
    placeholderClearOption,
    onChangeClearSelected,
    value,
    loading,
  } = props;

  return (
    <>
      {isSearchable && searchOnOptions && (
        <SearchInput
          className="border-none outline-none rounded-none"
          placeholder={searchPlaceholder}
          value={searchValue || ''}
          onValueChange={(val) => {
            onChangeSearch?.(val);
          }}
          ignoreFocus
        />
      )}
      {withClearableOption && (Array.isArray(value) ? value.length : !!value) && (
        <div
          className="py-2 px-4 flex gap-2 items-center hover:bg-secondary-surface cursor-pointer transition-colors duration-300 active:bg-secondary-border"
          onClick={() => onChangeClearSelected?.()}
        >
          {placeholderClearOption}
        </div>
      )}
      {withCreateRedirectOption
        ? (persistCreateRedirectOption ? true : !_options?.length) && (
            <BBBSelectDropdownOption
              data={{
                label: (
                  <div className="flex items-center gap-2">
                    <PlusCircle width={16} height={16} />
                    {createRedirectLabel}
                  </div>
                ),
                value: 'create',
              }}
              optionLabel="label"
              optionValue="value"
              onSelectOption={() => onClickCreateRedirect?.()}
              id="bbb-single-option"
              value={null}
            />
          )
        : !loading &&
          !_options?.length &&
          (isCreatable || forceCreatable ? (
            <div
              className={cx(
                'px-4 py-2 hover:bg-secondary-surface transition-colors duration-300 relative active:bg-secondary-border',
                search?.length && labelCheckStrictness && 'cursor-pointer'
              )}
              id="create-option-prompt"
              onClick={onClickCreate}
              tabIndex={0}
            >
              {!labelCheckStrictness
                ? `"${search}" option has already exist`
                : search
                ? `Enter to add ${JSON.stringify(search)}`
                : `Type something to add option`}
            </div>
          ) : (
            <div id="no-option" tabIndex={0}>
              {noOptionMessage}
            </div>
          ))}
      {(_options as MultipleOnValueChangeParam<T>)?.map((option, index) => {
        const value = getDescendantProp(option, optionValue);
        return (
          <BBBSelectDropdownOption
            {...props}
            data={option}
            id="bbb-single-option"
            key={
              isGrouped && optionGroupKey
                ? `${getDescendantProp(option, optionGroupKey)}_${value}`
                : value
            }
            onSelectOption={handleSelectOption}
            enableToggleOption={enableToggleOption}
            index={index}
          />
        );
      })}
      {searchOnOptions && loading && (
        <div className="my-1">
          <BBBSpinner width={3} height={16} />
        </div>
      )}
    </>
  );
}

export default React.forwardRef(BBBSelect) as <
  T extends {
    [k: string]: any;
  }
>(
  p: DropdownTypes<T> & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement;
