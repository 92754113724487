import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import telData from 'country-telephone-data';
import { AsYouType } from 'libphonenumber-js';
import { cloneDeep } from 'lodash-es';
import { twMerge as cx } from 'tailwind-merge';

import { BBBSelect, BBBTextInput } from '@/components/ui';
import useResponsive from '@/hooks/common/useResponsive';

const US_ASSOCIATE = [
  'BS',
  'BB',
  'AI',
  'AG',
  'VG',
  'VI',
  'KY',
  'BM',
  'GD',
  'TC',
  'JM',
  'MS',
  'MP',
  'GU',
  'AS',
  'SX',
  'LC',
  'DM',
  'VC',
  'PR',
];

const mappedTel = telData.allCountries.map((data) => {
  const isUSAssociate = US_ASSOCIATE.includes(data.iso2.toUpperCase());
  const _dialCode = isUSAssociate ? '1' : data.dialCode;
  return {
    ...data,
    dialCode: _dialCode,
    dialCodeLabel: (
      <div className="flex gap-3 items-center">
        <div className="flex-none w-12">+{_dialCode}</div>
        <div>{data.name}</div>
      </div>
    ),
    dialCodeLabel2: `+${_dialCode} `,
    icon: (
      <img
        alt="United States"
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.iso2.toUpperCase()}.svg`}
        className="w-4 h-4"
      />
    ),
    defaultNationalNumber: isUSAssociate ? data.dialCode.slice(1) : undefined,
  };
});

const clonedTelData = cloneDeep(mappedTel);

export type ControlledTextInput<T extends FieldValues> = {
  isHookForm: true;
  control: Control<T, any>;
  controlName: Path<T>;
  onChange?: never;
  onBlur?: never;
};

export type BBBTelInputValue = {
  countryCode?: string;
  phoneNumber?: string;
  iso2?: string;
  defaultNationalNumber?: string;
};

export type NormalTelInput = {
  isHookForm?: false;
  control?: never;
  controlName?: never;
  onChange?: (val: BBBTelInputValue | undefined) => void;
  onBlur?: () => void;
};

export type BBBTelInputProps<T extends FieldValues> = (
  | ControlledTextInput<T>
  | NormalTelInput
) &
  Omit<JSX.IntrinsicElements['input'], 'value' | 'onChange' | 'ref'> & {
    error?: string;
    label?: string;
    value?: BBBTelInputValue;
    containerClassname?: string;
  };

function BBBTelInput<T extends FieldValues>(props: BBBTelInputProps<T>) {
  const {
    isHookForm,
    controlName,
    control,
    label,
    containerClassname,
    disabled,
    error,
    name,
    ...inputProps
  } = props;
  const { onChange, onBlur, value, ...inputPropsExcluded } = inputProps;

  const [offset, setOffset] = useState(10);
  const [slicedOptions, setSlicedOptions] = useState<typeof clonedTelData>();
  const [totalSliced, setTotalSliced] = useState<number>(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const filteredTelData = clonedTelData.filter((data) =>
      !search
        ? true
        : data.name.toLowerCase().includes(search.toLowerCase()) ||
          data.dialCodeLabel2.toLowerCase().includes(search.toLowerCase())
    );
    setSlicedOptions(filteredTelData.slice(0, offset));
    setTotalSliced(filteredTelData.length);
  }, [offset, search]);

  return (
    <>
      {isHookForm ? (
        <Controller
          name={controlName}
          control={control}
          render={({ field }) => (
            <_BBBTelInput
              {...props}
              telInputValue={field.value}
              name={field.name}
              onValueChange={field.onChange}
              offset={offset}
              setOffset={setOffset}
              search={search}
              setSearch={setSearch}
              slicedOptions={slicedOptions}
              totalSliced={totalSliced}
            />
          )}
        />
      ) : (
        <_BBBTelInput
          {...props}
          telInputValue={value}
          name={name}
          onValueChange={onChange}
          offset={offset}
          setOffset={setOffset}
          search={search}
          setSearch={setSearch}
          slicedOptions={slicedOptions}
          totalSliced={totalSliced}
        />
      )}
    </>
  );
}

type _BBBTelInputProps = {
  telInputValue: BBBTelInputValue | undefined;
  onValueChange?: (val: BBBTelInputValue | undefined) => void;
};

function _BBBTelInput<T extends FieldValues>({
  telInputValue,
  onValueChange,
  name,
  containerClassname,
  label,
  error,
  disabled,
  isHookForm,
  controlName,
  control,
  offset,
  setOffset,
  search,
  setSearch,
  totalSliced,
  slicedOptions,
  ...inputProps
}: _BBBTelInputProps &
  BBBTelInputProps<T> & {
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    totalSliced: number;
    slicedOptions: typeof clonedTelData | undefined;
  }) {
  const {
    onChange,
    onBlur,
    value: valueFromProps,
    ...inputPropsExcluded
  } = inputProps;

  const isMobile = useResponsive('sm');
  const [chars, setChars] = useState<string>();
  const [cursor, setCursor] = useState<number | null>(null);

  const _value = telInputValue
    ? `+${telInputValue.countryCode}${telInputValue.defaultNationalNumber}${telInputValue.phoneNumber}`
    : '';

  useEffect(() => {
    const asYouType = new AsYouType();

    if (telInputValue) {
      const _chars = asYouType.input(
        `+${telInputValue.countryCode}${
          telInputValue.defaultNationalNumber ?? ''
        }${telInputValue.phoneNumber}`
      );

      setChars(_chars);
    }
  }, [telInputValue]);

  const derivedSlice =
    slicedOptions?.filter(
      (opt) =>
        !(
          opt.dialCode === telInputValue?.countryCode &&
          telInputValue.iso2 === opt.iso2
        )
    ) ?? [];

  const value = mappedTel.find(
    (data) =>
      data.dialCode === telInputValue?.countryCode &&
      telInputValue.iso2 === data.iso2
  );

  const ref = useRef<HTMLInputElement>(null);

  const inputValue = chars?.split(' ').slice(1).join(' ') ?? '';

  useEffect(() => {
    let _cursor = cursor ?? 0;
    const trimmed = inputValue.replace(/\s/g, '');

    if (_cursor >= trimmed.length) {
      _cursor = inputValue.length;
    }

    if (!isMobile) {
      ref.current?.setSelectionRange(_cursor, _cursor);
    }
  }, [inputValue, cursor, isMobile]);

  return (
    <div className={containerClassname}>
      {label}
      <div className={cx('flex gap-1')}>
        <BBBSelect
          options={value ? [value, ...derivedSlice] : [...derivedSlice]}
          optionLabel="dialCodeLabel"
          selectedLabel="dialCodeLabel2"
          optionValue="iso2"
          isSearchable
          optionIcon="icon"
          dropdownClassName="h-full p-0"
          menuContainerClassName="w-96"
          value={value}
          containerClassName="w-32 mb-0"
          placeholder="+1"
          isPaginated
          hasMore={offset < totalSliced}
          fetchNext={() => setOffset((prev) => prev + 5)}
          onValueChange={(val) => {
            onValueChange?.({
              ...telInputValue,
              countryCode: val!.dialCode,
              iso2: val!.iso2,
              defaultNationalNumber: !telInputValue?.defaultNationalNumber
                ? val!.defaultNationalNumber
                : '',
            });
          }}
          search={search}
          onChangeSearch={setSearch}
          isDisabled={disabled}
        />
        <BBBTextInput
          value={inputValue}
          containerClassname="grow mb-0"
          onChange={({
            target: { value: phoneNumberValue, selectionStart },
          }) => {
            const _asYouType = new AsYouType();

            const clearPhoneNumber = phoneNumberValue.split(' ').join('');

            if (telInputValue) {
              _asYouType.input(
                `+${telInputValue.countryCode}${clearPhoneNumber}`
              );
            }

            const _country = _asYouType.getCountry()?.toLowerCase();

            setCursor(selectionStart);

            onValueChange?.({
              ...telInputValue,
              phoneNumber: clearPhoneNumber,
              defaultNationalNumber:
                telInputValue?.defaultNationalNumber &&
                !_value.endsWith(telInputValue.defaultNationalNumber)
                  ? telInputValue.defaultNationalNumber
                  : undefined,
              ...(_country
                ? {
                    iso2: _country,
                  }
                : {}),
            });
          }}
          placeholder="1234567"
          disabled={disabled}
          name={name}
          ref={ref}
          {...inputPropsExcluded}
        />
      </div>
      <div className="text-red-500">{error}</div>
    </div>
  );
}

export default BBBTelInput;
