import ChatInitiationTemplate from './ChatInitationTemplate';
import ProfileInformation from './ProfileInformation';

import { BBBContainer } from '@/components/ui';

export default function CloudApiTemplate() {
  return (
    <BBBContainer pageTitle="WhatsApp Cloup API" className="md:p-0">
      <div className="flex flex-col gap-5">
        <ProfileInformation />
        <ChatInitiationTemplate />
      </div>
    </BBBContainer>
  );
}
