import { useEffect, useState } from 'react';
import { twMerge as cx } from 'tailwind-merge';
import AddCustomerModal from '../../AddCustomerModal';
import Notes from './Notes';

import BriefcaseIcon from '@/assets/icons/BriefcaseIcon';
import CopyIcon from '@/assets/icons/CopyIcon';
import EmailIcon from '@/assets/icons/EmailIcon';
import InstagramIcon from '@/assets/icons/InstagramIcon';
import LocationIcon from '@/assets/icons/LocationIcon';
import PhoneIcon from '@/assets/icons/PhoneIcon';
import UserIcon from '@/assets/icons/UserIcon';
import { BBBCard } from '@/components/ui';
import { CustomerWithAssociation } from '@/types/customers/association';
import { formatDisplayName } from '@/utils/customers';

export type DetailCardProps = Partial<
  Pick<
    CustomerWithAssociation,
    | 'addresses'
    | 'phoneNumber'
    | 'firstName'
    | 'lastName'
    | 'instagramId'
    | 'email'
    | 'sources'
    | 'notes'
    | 'id'
    | 'customerTags'
  >
>;

function DetailCard(props: DetailCardProps) {
  const {
    addresses,
    phoneNumber,
    firstName,
    lastName,
    instagramId,
    email,
    notes,
    sources,
    id,
  } = props;

  const [copied, setCopied] = useState<'phone' | 'insta' | 'mail'>();
  const [showAdd, setShowAdd] = useState(false);

  const address = [addresses?.[0]?.city, addresses?.[0]?.country].filter(
    Boolean
  );

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(undefined);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    if (copied === 'phone') {
      navigator.clipboard.writeText(phoneNumber!);
    } else if (copied === 'mail') {
      navigator.clipboard.writeText(email!);
    } else if (copied === 'insta') {
      navigator.clipboard.writeText(instagramId!);
    }
  }, [copied, email, instagramId, phoneNumber]);

  return (
    <>
      {showAdd && (
        <AddCustomerModal
          show={showAdd}
          onClose={() => {
            setShowAdd(false);
          }}
          data={props}
        />
      )}
      <BBBCard
        title="Customer details"
        rightButton={
          <span
            className="text-[#2B6AAF] underline cursor-pointer text-sm"
            onClick={() => setShowAdd(true)}
          >
            Edit
          </span>
        }
        className="mb-5"
      >
        <div className="flex gap-1.5 items-center mb-4">
          <UserIcon />
          <span className="text-primary-main">
            {formatDisplayName(phoneNumber, firstName, lastName)}
          </span>
        </div>
        <div
          className={cx(
            'flex gap-1.5 relative cursor-pointer hover:underline items-center mb-4',
            !phoneNumber && 'pointer-events-none'
          )}
          onClick={() => setCopied('phone')}
        >
          <PhoneIcon />
          <span className="text-primary-main">{phoneNumber ?? '-'}</span>
          {!!phoneNumber && <CopyIcon />}
          {copied === 'phone' && <CopyTooltip />}
        </div>
        <div
          className={cx(
            'flex gap-1.5 cursor-pointer relative hover:underline items-center mb-4',
            !instagramId && 'pointer-events-none'
          )}
          onClick={() => setCopied('insta')}
        >
          <InstagramIcon />
          <span className="text-primary-main">{instagramId ?? '-'}</span>
          {!!instagramId && <CopyIcon />}
          {copied === 'insta' && <CopyTooltip />}
        </div>
        <div
          className={cx(
            'flex gap-1.5 relative cursor-pointer hover:underline items-center mb-4',
            !email && 'pointer-events-none'
          )}
          onClick={() => setCopied('mail')}
        >
          <EmailIcon />
          <span className="text-primary-main">{email ?? '-'}</span>
          {!!email && <CopyIcon />}
          {copied === 'mail' && <CopyTooltip />}
        </div>
        <div className="flex gap-1.5 items-center mb-4">
          <BriefcaseIcon />
          <span className="text-primary-main">
            {addresses?.length ? addresses[0].company || '-' : '-'}
          </span>
        </div>
        <div className="flex gap-1.5 items-center mb-4">
          <LocationIcon />
          <span className="text-primary-main">
            {addresses?.length && address.length ? address.join(', ') : '-'}
          </span>
        </div>
        <Notes id={id} notes={notes} />
      </BBBCard>
    </>
  );
}

function CopyTooltip() {
  return (
    <div className="absolute -top-6 left-12 rounded-lg p-2 bg-[#4E4E4EB2] text-neutral-10 text-xs">
      Copied
    </div>
  );
}

export default DetailCard;
