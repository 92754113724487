import { twMerge as cx } from 'tailwind-merge';

import { BBBSpinner } from '@/components/ui';

export function Loader({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center gap-1',
        className
      )}
    >
      <BBBSpinner height={18} />
      <p className="text-neutral-60">Loading more data</p>
    </div>
  );
}
