import { useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import services from 'api/services';
import { PlanType, PricingFeatureNames } from 'api/services/pricing';
import useQuerySearchParams from 'hooks/common/url/useQuerySearchParams';
import { useActiveCompany } from 'hooks/rtk/selector';
import { AppType } from 'types/integrations';
import useRedirectUpgradePlan from '../useRedirectUpgradePlan';

import { PricingPlan } from '@/api/services/pricing';
import api from '@/config/api';
import { appNameFromType } from '@/constants/app';
import useConfirmationModal from '@/hooks/common/useConfirmationModal';
import useConnectIntegration from '@/hooks/common/useConnectIntegration';
import { useAppDispatch } from '@/hooks/rtk/store';
import useShopifyIntegrationByApp from '@/hooks/shopify/useShopifyIntegrationByApp';
import { socketPricing } from '@/socket';
import { setChosePaymentMethodModal } from '@/stores/common';
import { toast } from '@/utils/common/toast';

export const usePricings = (
  {
    app,
    type,
  }: {
    app: AppType;
    type: PlanType;
  },
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['pricings-v2', { app, type }],
    () => services.pricing.getPricings({ app, type }),
    options
  );
};

export const usePricingsHash = (
  {
    app,
    type,
  }: {
    app: AppType;
    type: PlanType;
  },
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['pricings-v2', { app, type }],
    () => services.pricing.getPricings({ app, type }),
    {
      ...options,
      select: (data) => {
        return Object.fromEntries(data.map((d) => [d.name, d]));
      },
    }
  );
};

export const usePricing = (
  { app }: { app: AppType },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-v2', { app, activeCompany }],
    () => services.pricing.getCompanyPricing({ id: activeCompany }, { app }),
    options
  );
};

export const useCompanyPricings = (options?: { enabled?: boolean }) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricings-v2-global', activeCompany],
    () => services.pricing.getCompanyPricings(activeCompany),
    options
  );
};

export const usePricingFeatures = (
  { app }: { app: AppType },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-features-v2', { app, activeCompany }],
    () => services.pricing.getPricingFeatures({ id: activeCompany }, { app }),
    options
  );
};

export const usePricingFeature = (
  {
    app,
    featureName,
  }: {
    app: AppType;
    featureName: PricingFeatureNames;
  },
  options?: { enabled?: boolean }
) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    ['pricing-feature-v2', { app, activeCompany, featureName }],
    () =>
      services.pricing.getPricingFeature(
        { id: activeCompany, featureName },
        { app }
      ),
    options
  );
};

export const mapQueryToPlanType: Record<string, PlanType> = {
  annual: 'ANNUAL',
  monthly: 'EVERY_30_DAYS',
};

export const mapPlanTypeToHumanReadable: Record<PlanType, string> = {
  ANNUAL: 'year',
  EVERY_30_DAYS: 'month',
};

export const usePlanTypeQueryParams = () => {
  const queryParam = useQuerySearchParams();

  const plan = queryParam.get('duration') || 'monthly';

  const planType = mapQueryToPlanType[plan];

  return planType;
};

export const useDowngradeToFree = () => {
  const queryClient = useQueryClient();
  const confirm = useConfirmationModal();
  const activeCompany = useActiveCompany();

  const onPricingEnd = useCallback(() => {
    queryClient.invalidateQueries([`pricing-v2`]);
  }, [queryClient]);

  useEffect(() => {
    socketPricing.emit('join', { companyId: activeCompany });
  }, [activeCompany]);

  useEffect(() => {
    socketPricing.on(`pricing-downgrade-success`, onPricingEnd);

    return () => {
      socketPricing.off(`pricing-downgrade-success`, onPricingEnd);
    };
  }, [onPricingEnd]);

  const { mutate: downgrade } = useMutation(
    ({ app, type }: { app: AppType; type: PlanType }) =>
      api.pricing.get(`/pricing/company/${activeCompany}/billing`, {
        params: {
          app,
          type,
          plan: 'free',
        },
      }),
    {
      onSuccess: (_, { app }) => {
        toast.success(
          'We have succesfully receive your request to downgrade the app.'
        );
        // delay by consumer
        setTimeout(() => {
          queryClient.invalidateQueries([`${app.toLowerCase()}-pricing`]);
        }, 2000);
      },
      onError: () => {
        toast.error('Invalid request');
      },
    }
  );

  return ({ app, type }: { app: AppType; type: PlanType }) => {
    confirm({
      title: 'Are you sure you want to downgrade to the free plan?',
      description:
        'Once you downgrade, you will lose access to some of the features of your current plan',
      submitText: 'Confirm',
      cancelText: 'Cancel',
      onAccept: (hide) => {
        downgrade({ app, type });
        hide();
      },
    });
  };
};

export const useUpgradeOrDowngradePlan = ({
  app,
  type,
  onSuccess,
}: {
  app: AppType;
  type: PlanType;
  onSuccess?: () => void;
}) => {
  const dispatch = useAppDispatch();

  const downgradeToFree = useDowngradeToFree();
  const { fn: redirectUpgradePlan } = useRedirectUpgradePlan({
    appType: app,
    type,
    onSuccess,
  });

  const { data: shopifyIntegration } = useShopifyIntegrationByApp(app);
  const hasShopifyIntegrations = !!shopifyIntegration;

  const connect = useConnectIntegration();

  return ({ plan }: { plan: PricingPlan }) => {
    if (plan === 'free') {
      downgradeToFree({ app, type });
    } else if (plan === 'enterprise') {
      window.open(
        `https://api.whatsapp.com/send/?phone=6285693142306&text=Hello+bitbybit+Team%21%0D%0A%0D%0AI+want+to+upgrade+my+${appNameFromType[app]}+plan+to+Enterprise%21+Are+you+open+for+a+discussion%3F+&type=phone_number&app_absent=0`,
        '_blank'
      );
    } else {
      dispatch(
        setChosePaymentMethodModal({
          app,
          onSave: (method) => {
            if (method === 'shopify') {
              if (hasShopifyIntegrations) {
                redirectUpgradePlan(plan, method);
              } else {
                connect({ name: 'shopify', appType: app });
              }
            } else {
              redirectUpgradePlan(plan, method);
            }
          },
        })
      );
    }
  };
};
