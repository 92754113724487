import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MoreHorizontal } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge as cx } from 'tailwind-merge';

import UserProfilePicture from '@/components/Auth/UserProfilePicture';
import Link from '@/components/ui/Link/Link';
import useHistory from '@/hooks/common/useHistory';
import useOutsideAlerterv2 from '@/hooks/common/useOutsideAlerterv2';
import { useUser } from '@/hooks/rtk/selector';
import { useAppDispatch } from '@/hooks/rtk/store';
import { logout } from '@/stores/auth';
import { formatUserDisplayName } from '@/utils/auth';

type Props = {
  expanded?: boolean;
  setActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

function UserZone({ expanded, setActiveSidebar }: Props) {
  const data = useUser();
  const [activeProfileDropdown, setActiveProfileDropdown] = useState(false);
  const profileDropDownRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useOutsideAlerterv2(profileDropDownRef, () => {
    setActiveProfileDropdown(false);
  });

  const sidebarRootRef = document.getElementById('sidebar-root');

  return (
    <div className="mb-4">
      {sidebarRootRef &&
        createPortal(
          <AnimatePresence>
            {activeProfileDropdown && (
              <motion.div
                className={cx(
                  'shadow-sm absolute w-[calc(100%-1.75rem)] mx-3.5 z-[1000] border border-neutral-30 bottom-[4.75rem] rounded-[8px]'
                )}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={profileDropDownRef}
              >
                <div className="rounded-lg bg-white">
                  <Link
                    to="/settings"
                    onClick={() => setActiveSidebar(false)}
                    className="px-4 pt-3 pb-2 flex"
                  >
                    Settings
                  </Link>
                  <div
                    className="px-4 pt-2 pb-3 cursor-pointer text-danger-main"
                    onClick={() => {
                      dispatch(logout());
                      history.push('/login');
                    }}
                  >
                    Log out
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>,
          sidebarRootRef
        )}

      <div
        onClick={() => {
          setActiveProfileDropdown((prev) => !prev);
        }}
        className={cx(
          'active:bg-secondary-surface active:border-secondary-main mx-3.5 px-4 py-2 rounded-xl gap-4 border transition-all border-transparent hover:border-neutral-30 flex mt-3 items-center cursor-pointer',
          activeProfileDropdown && 'border-transparent bg-neutral-20'
        )}
      >
        {data && <UserProfilePicture {...data} />}
        {!expanded && data && (
          <>
            <div className="grow truncate">{formatUserDisplayName(data)}</div>
            <MoreHorizontal className="text-neutral-40" />
          </>
        )}
      </div>
    </div>
  );
}

export default UserZone;
