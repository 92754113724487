import { useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { businessTypeOptions } from 'constants/whatsApp';
import { useWhatsappCloudIntegration } from 'hooks/bitChat/integration/integrations';
import useConfirmationBanner from 'hooks/common/useConfirmationBanner';
import useCustomForm from 'hooks/common/useCustomForm';
import { useProfile, useUpdateProfile } from 'hooks/whatsApp/business-profile';
import { isEqual } from 'lodash-es';
import { BusinessType, WhatsAppCloudAccount } from 'types/whatsApp/chat';
import * as yup from 'yup';
import { toast } from 'utils/common/toast';

import {
  BBBCard,
  BBBImageUpload,
  BBBSelect,
  BBBTextAreaInput,
  BBBTextInput,
} from '@/components/ui';

const schema = yup.object().shape({
  profile_picture: yup.string(),
  displayName: yup.string(),
  vertical: yup.mixed<{
    label: string;
    value: string;
  }>(),
  description: yup.string(),
  address: yup.string().max(256),
  email: yup.string().email().max(128),
  websites: yup.array().of(yup.string().url()).max(256),
});

type ProfileInformation = Omit<WhatsAppCloudAccount, 'key' | 'vertical'> & {
  vertical?: {
    label: string;
    value: BusinessType;
  };
};

export default function ProfileInformation() {
  const { data } = useProfile();
  const waCloudQuery = useWhatsappCloudIntegration();
  const [staleLoading, setStaleLoading] = useState(false);

  const dataFromApi = useMemo<ProfileInformation>(
    () => ({
      profile_picture: data?.profile_picture || '',
      vertical:
        businessTypeOptions?.find(
          (option) => option.value === data?.vertical
        ) || undefined,
      description: data?.description || '',
      address: data?.address || '',
      email: data?.email || '',
      websites: data?.websites || [''],
    }),
    [
      data?.address,
      data?.description,
      data?.email,
      data?.profile_picture,
      data?.vertical,
      data?.websites,
    ]
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useCustomForm<ProfileInformation>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...dataFromApi,
    },
  });

  const websites = useWatch({
    control,
    name: 'websites',
  });

  const { toggle } = useConfirmationBanner();

  const { mutate, isLoading } = useUpdateProfile();
  const queryClient = useQueryClient();

  const whatsappCloudConnected =
    waCloudQuery.data && waCloudQuery.data?.status === 'CONNECTED';

  useEffect(() => {
    function onSubmit(data: ProfileInformation) {
      if (!whatsappCloudConnected) {
        return toast.error('Please connect to Whatsapp API first.');
      }

      mutate(
        { ...data, vertical: data?.vertical?.value },
        {
          onSettled: () => {
            setStaleLoading(true);
          },
          onSuccess: () => {
            setTimeout(() => {
              queryClient.invalidateQueries([
                'bitchat-whatsapp-business-profile',
              ]);
              setStaleLoading(false);
              toast.success('Success update profile!');
            }, 3000);
          },
        }
      );
    }

    const isFormEqual = isEqual(watch(), dataFromApi);

    toggle('bitchat-settings-whatsapp-api-profile', {
      show: !isFormEqual,
      variant: isLoading || staleLoading ? 'loading' : 'actionable',
      text: 'You have unsaved changes',
      cancelLabel: 'Discard changes',
      onCancel: reset,
      isCancelable: true,
      onAccept: () => handleSubmit(onSubmit)(),
    });
  }, [
    dataFromApi,
    handleSubmit,
    isLoading,
    whatsappCloudConnected,
    mutate,
    reset,
    toggle,
    watch(),
    staleLoading,
    queryClient,
  ]);

  useEffect(() => {
    reset(dataFromApi);
  }, [dataFromApi, reset]);

  return (
    <>
      <BBBCard
        title="Profile Information"
        desc="This will be visible on your business profile."
        className="mb-cardBottom"
      >
        <div className="flex flex-col gap-5">
          <Controller
            control={control}
            name="profile_picture"
            render={({ field }) => (
              <BBBImageUpload
                label="Profile photo"
                imageUrl={field.value}
                onChangeImage={field.onChange}
                shape="circle"
                width={80}
                height={80}
              />
            )}
          />
          <Controller
            control={control}
            name="vertical"
            render={({ field }) => (
              <BBBSelect
                label="Category"
                placeholder="What is the category of your company?"
                options={businessTypeOptions}
                optionLabel="label"
                optionValue="value"
                isSearchable
                value={field.value}
                onValueChange={field.onChange}
                error={errors.vertical?.message}
              />
            )}
          />
          <BBBTextAreaInput
            isHookForm
            control={control}
            controlName="description"
            label="Description"
            rows={5}
            placeholder={`Describe your business to give more context to AI Agents. Some examples below: \n\n “ZARA is a Spanish multinational fast-fashion company with over 2,000 stores worldwide. We specialize in selling clothing, accessories, beauty products, and perfumes.”`}
            hasMaxCharLabel
            maxChar={512}
            error={errors.description?.message}
          />
        </div>
      </BBBCard>
      <BBBCard
        title="Contact Information"
        desc="Add some contact details for your business."
        className="mb-cardBottom"
      >
        <div className="flex flex-col gap-5">
          <BBBTextInput
            label="Address"
            isHookForm
            control={control}
            controlName="address"
            placeholder="Enter business address"
            hasMaxCharLabel
            maxChar={256}
            error={errors.address?.message}
          />
          <BBBTextInput
            label="Email"
            isHookForm
            control={control}
            controlName="email"
            placeholder="Enter business email"
            hasMaxCharLabel
            maxChar={128}
            error={errors.email?.message}
          />
          {websites?.map((website, idx) => (
            <BBBTextInput
              key={idx}
              isUrl
              label={`Website`}
              isHookForm
              control={control}
              controlName={`websites.${idx}`}
              placeholder="Enter business website"
              hasMaxCharLabel
              maxChar={256}
              error={errors.websites?.[idx]?.message}
            />
          ))}
          {websites && websites?.length === 1 && (
            <div
              className="flex items-center text-neutral-40 gap-2 cursor-pointer"
              onClick={() => {
                setValue('websites', [websites?.[0] || '', '']);
              }}
            >
              <PlusCircle />
              Add another website
            </div>
          )}
        </div>
      </BBBCard>
    </>
  );
}
